import React, { Component, Fragment } from "react";
import { Alert, Form, Input, DatePicker, Button } from "antd";
import { observer } from "mobx-react";
import { n, filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { SearchForm, Toolbar, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import classnames from "classnames";

const FormItem = Form.Item;

const decorateRows = (item, index) =>
  classnames("base-table--row", {
    "base-table--row__odd": index % 2 !== 0,
    "base-table--row__sucess": item.Resumo.dias_faltantes > 30,
    "base-table--row__invalid": item.Resumo.dias_faltantes >= 15 && item.Resumo.dias_faltantes <= 30,
    "base-table--row__danger": item.Resumo.dias_faltantes < 15,
  });

const rowKey = item =>
  [
    item.Resumo.data_emissao,
    item.Resumo.codigo_cliente,
    item.Resumo.documento,
  ].join(":");

@observer
class ResumoCashbackScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Resumo Cashback",
      uri: "/r/resumo_cashback",
    });

    this.state = {};
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const { store } = this.props;

    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };
    return (
      <Fragment>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "normal" }}>
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
            message={`Cashback expirando, corra!!! `}
            type="error"
            showIcon
          />
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15 }}
            message={`Cashback expira em poucos dias! `}
            type="warning"
            showIcon
          />
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginLeft: 15 }}
            message={`Cashback novinho para resgate! `}
            type="success"
            showIcon
          />
        </div>
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={onUpdateDataSource}
          filterFields={
            store.isVendedorOrOperador
              ? {
                data_emissao$0: "date",
                data_emissao$1: "date",
                nome_cliente: "text",
              }
              : {
                data_emissao$0: "date",
                data_emissao$1: "date",
              }
          }
          filterParser={{
            data_emissao$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
            data_emissao$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
            nome_cliente: filter => ({
              ...filter,
              field: "Resumo.nome_cliente",
              value: `%${filter.value}%`,
              op: "LIKE",
            }),
          }}>
          {({ form }) => (
            <Fragment>
              <FormItem label="Filtrar por Data de Emissão">
                {form.getFieldDecorator("data_emissao$0")(<DatePicker placeholder="Data Inicial" {...inputProps} />)}
              </FormItem>
              <FormItem>
                {form.getFieldDecorator("data_emissao$1")(<DatePicker placeholder="Data Final" {...inputProps} />)}
              </FormItem>
              {store.isVendedorOrOperador && (
                <FormItem label="Cliente">
                  {form.getFieldDecorator("nome_cliente")(
                    <Input placeholder="Filtrar por Nome de Cliente" {...inputProps} style={{ width: 200 }} />
                  )}
                </FormItem>
              )}
              <FormItem>
                <Button type="danger" htmlType="submit" icon="search" />
              </FormItem>
            </Fragment>
          )}
        </SearchForm>
      </Fragment>
    );
  };
  renderFooter = () => {
    if (!this.ds.isLoading) {
      const gerado = this.ds.items.reduce((ac, item) => ac + item.Resumo.cashback_gerado, 0);
      const utilizado = this.ds.items.reduce((ac, item) => ac + item.Resumo.cashback_utilizado, 0);
      const saldo = this.ds.items.reduce((ac, item) => ac + item.Resumo.saldo, 0);

      return (
        <div style={{ textAlign: "right", fontSize: 13 }}>
          <span style={{ margin: "0 2px" }}>
            <strong>Total gerado:</strong> {n(gerado)}
          </span>
          {` `}|{` `}
          <span style={{ margin: "0 2px" }}>
            <strong>Total utilizado:</strong> R$ {n(utilizado)}
          </span>
          {` `}|{` `}
          <span style={{ margin: "0 2px" }}>
            <strong>Total saldo:</strong> R$ {n(saldo)}
          </span>
        </div>
      );
    }

    return (
      <div style={{ textAlign: "right", fontSize: 13 }}>
        <strong>...carregando</strong>
      </div>
    );
  };
  render() {
    let columns = [
      {
        title: "Data",
        dataIndex: "Resumo.data_emissao",
        dataType: "date",
        key: "data_emissao",
        sorter: true,
        width: "9.25%",
        filteredValue: this.ds.filterValue("data_emissao"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Emissão (dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          type: "str_date",
          field: "Resumo.data_emissao",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Código",
        dataIndex: "Resumo.codigo_cliente",
        key: "codigo_cliente",
        sorter: false,
        render: (text, item) => (
          <WrappedValue value={`${item.Resumo.codigo_cliente} / ${item.Resumo.loja_cliente}`} type="text" />
        ),
        width: "8%",
        filteredValue: this.ds.filterValue("codigo_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código Cliente" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Resumo.codigo_cliente",
          value: `${filter.value}`,
          op: ":=",
        }),
      },
      {
        title: "Loja",
        dataIndex: "Resumo.loja_cliente",
        width: "4%",
      },
      {
        title: "Cliente",
        dataIndex: "Resumo.nome_cliente",
        key: "nome_cliente",
        width: "auto",
        sorter: true,
        render: text => <WrappedValue value={text} type="text" />,
        className: "text-left",
        filteredValue: this.ds.filterValue("nome_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Resumo.nome_cliente", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Documento",
        dataIndex: "Resumo.documento",
        key: "documento",
        sorter: true,
        render: text => <WrappedValue value={text} type="text" />,
        className: "text-center",
        width: "13%",
        filteredValue: this.ds.filterValue("documento"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nota fiscal" {...props} />,
        filterParser: filter => ({
          ...filter,
          field: "Resumo.documento",
          value: `%${filter.value}`,
          op: ":LIKE",
        }),
      },
      {
        title: "Limite",
        dataIndex: "Resumo.data_limite",
        dataType: "date",
        key: "data_limite",
        sorter: true,
        width: "9.25%",
        filteredValue: this.ds.filterValue("data_limite"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data limite p/ resgate(dd/mm/aaaa)" {...props} />,
        filterParser: filter => ({
          ...filter,
          type: "str_date",
          field: "Resumo.data_limite",
          value: filterDate(filter.value),
        }),
      },
      {
        title: "Expira em",
        dataIndex: "Resumo.dias_faltantes",
        key: "dias_faltantes",
        sorter: true,
        width: "11%",
        render: text => <WrappedValue value={`${n(+text, 0)} dias`} type="text" />,
        className: "text-center",
      },
      {
        title: "Gerado",
        dataIndex: "Resumo.cashback_gerado",
        key: "cashback_gerado",
        sorter: true,
        width: "10%",
        render: text => <WrappedValue value={n(+text)} type="text" />,
        className: "text-center",
      },
      {
        title: "Utilizado",
        dataIndex: "Resumo.cashback_utilizado",
        key: "utilizado",
        sorter: true,
        width: "10%",
        render: text => <WrappedValue value={n(+text)} type="text" />,
        className: "text-center",
      },
      {
        title: "Saldo",
        dataIndex: "Resumo.saldo",
        key: "saldo",
        sorter: true,
        width: "10%",
        render: text => <WrappedValue value={n(+text)} type="text" />,
        className: "text-center",
      },

    ];

    return (
      <div className="resumo-screen">
        <PageTitle title={this.ds.name} />
        {!this.ds.hasFilter && (
          <Alert
            message={
              <Fragment>
                Listagem inicial de cashback faturados no período de <em>60 dias</em>. Para buscas mais amplas, favor
                utilize os filtros de data abaixo.
              </Fragment>
            }
            type="info"
            showIcon
            style={{ margin: "-5px 0 16px" }}
          />
        )}
        <FullTable
          className="full-table__compact"
          rowClassName={decorateRows}
          beforeComponent={this.renderSearchForm}
          actionBar={<Toolbar exportable={true} printable={true} />}
          footer={this.renderFooter}
          rowKey={rowKey}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter sua listagem de cashback no momento."
        />
      </div>
    );
  }
}

export default withStore(ResumoCashbackScreen);
