import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Line } from "recharts";
import styled from "styled-components";
import { fetchMeta } from "actions/dashboard";
import { n } from "@util";
import moment from "moment";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 26px;
`;

const Chart = ({ isLoading, label, data = [], chartValue, width = 509, height = 195, percent, children, ...props }) => {
  const CustomizedLabel = ({ x = 0, y = 0, value, unit, width }) => {
    if (value === 0) {
      return (
        <text></text>
      )
    }

    return (
      <text
        x={x + width + 5}
        y={y + 10}
        fontSize={13}
        fontWeight={600}
        fill="#666"
        textAnchor="start"
      >
        {unit === "%" ? n(value) + " " + unit : unit + " " + n(value)}
      </text>
    )
  };

  return (
    <Spin spinning={isLoading}>
      {!!data && data.length > 0 ?
        <ComposedChart
          layout="vertical"
          width={window.innerWidth * 0.9}
          height={window.innerHeight * 0.9}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barGap={0}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="nome_operador" type="category" scale="band" width={300} />
          <Legend />
          <Bar
            dataKey="carteira"
            fill="#1059ad"
            label={<CustomizedLabel unit="R$" />}
            minPointSize={5}
          />
          <Bar
            dataKey="faturado"
            fill="#02aed9"
            label={<CustomizedLabel unit="R$" />}
            minPointSize={5}
          />
          <Bar
            dataKey="vendido"
            fill="#34e5d5"
            label={<CustomizedLabel unit="R$" />}
            minPointSize={5}
          />
          <Line type="monotone" name="Meta" dataKey="meta" stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />
        </ComposedChart>
        : <div style={{
          width: 480,
          height: 280,
          top: 40,
          right: 55,
          left: 55,
          bottom: 20,
        }} />
      }
    </Spin>
  );
};
export default class MetaPanelOTIndividual extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      mes: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.fetchData();
      const interval = setInterval(this.fetchData, 300000);
      this.setState({ interval });
    })()
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.setState({ interval: false });
  }

  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      let mes = null;

      if (!!this.state.mes) {
        mes = this.state.mes.month() + 1;
      }

      const response = await fetchMeta(null, mes, true);
      if (response.success) {
        const { data } = response;
        this.setState({ data: data.meta });
      }
    } catch (err) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, data } = this.state;

    const currentMonth = moment().format('MMMM');

    return (
      <div style={{ minHeight: 380 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: "center",
          fontSize: 19,
          height: 25
        }}>
          <h1 style={{ marginBottom: 0 }}>Televendas - Atingimento meta (R$) {currentMonth} - Por operador</h1>
        </div>
        <DashboardBody>
          <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              <Chart isLoading={isLoading} title="Venda Mix" keyLabel="mes" unit="" titleX="Mês" data={data} />
            </div>
          </Fragment>
        </DashboardBody>
      </div>
    );
  }
}
