import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Line, ReferenceLine } from "recharts";
import styled from "styled-components";
import { fetchSemaforoTV } from "actions/dashboard";
import moment from "moment";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 26px;
`;

const Chart = ({ isLoading, label, data = [], chartValue, width = 509, height = 195, percent, children, ...props }) => {
  const CustomizedLabel = ({ x = 0, y = 0, value, unit, width, height }) => {
    if (value === 0) {
      return (
        <text></text>
      )
    }

    return (
      <text
        x={x + width + 5}
        y={y + 5 + height / 2}
        fontSize={14}
        fontWeight={600}
        fill="#666"
      >
        {value}
      </text>
    )
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height, realizado } = props;
    const color = realizado >= 0 && realizado < 50 ? 'rgb(255, 0, 0)' : (realizado >= 50 && realizado < 100 ? 'rgb(250, 249, 0)' : 'rgb(0, 153, 102)');

    return <rect x={x} y={y} width={width} height={height} fill={color} />;
  };

  const CustomSVG = ({ color }) => {
    return <svg
      className="recharts-surface"
      width="14"
      height="14"
      viewBox="0 0 32 32"
      version="1.1"
      style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}
    >
      <path
        stroke="none"
        fill={color}
        d="M0,4h32v24h-32z"
        className="recharts-legend-icon"
      />
    </svg>
  }

  const CustomLegend = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div key={`item`} style={{ margin: '0 10px', textAlign: 'center' }}>
          <CustomSVG color='rgb(250, 0, 0)' />
          <span style={{ color: '#666' }}>0 a 50 Crítico </span>
          <CustomSVG color='rgb(250, 249, 0)' />
          <span style={{ color: '#666' }}>50 a 100 Atenção </span>
          <CustomSVG color='rgb(0, 153, 102)' />
          <span style={{ color: '#666' }}>Acima de 100 Ideal </span>
        </div>
      </div>
    );
  };


  return (
    <Spin spinning={isLoading}>
      {!!data && data.length > 0 ?
        <ComposedChart
          layout="vertical"
          width={window.innerWidth * 0.9}
          height={window.innerHeight * 0.9}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barGap={0}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="nome_operador" type="category" scale="band" width={300} />
          <Legend content={<CustomLegend />} />
          <Bar
            dataKey="realizado"
            fill="#1059ad"
            label={<CustomizedLabel unit="" />}
            shape={<CustomBarShape />}
            minPointSize={5}
          />
          {/* <Bar
            dataKey="faturado"
            fill="#02aed9"
            label={<CustomizedLabel unit="R$" />}
            minPointSize={5}
          />
          <Bar
            dataKey="vendido"
            fill="#34e5d5"
            label={<CustomizedLabel unit="R$" />}
            minPointSize={5}
          /> */}
          <ReferenceLine type="monotone" name="Meta" x={100} stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />
          <ReferenceLine type="monotone" name="Meta" x={50} stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />
          <Line type="monotone" name="Meta" dataKey="meta" stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />
        </ComposedChart>
        : <div style={{
          width: 480,
          height: 280,
          top: 40,
          right: 55,
          left: 55,
          bottom: 20,
        }} />
      }
    </Spin>
  );
};
export default class MixOTIndividual extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      mes: null,
      isLoading: false,
      interval: false,
    };
  }

  componentDidMount() {
    (async () => {
      await this.fetchData();
      const interval = setInterval(this.fetchData, 300000);
      this.setState({ interval });
    })()
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.setState({ interval: false });
  }

  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchSemaforoTV('venda_mix', true);

      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, data } = this.state;

    const currentMonth = moment().format('MMMM');

    return (
      <div style={{ minHeight: 380 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: "center",
          fontSize: 19,
          height: 25
        }}>
          <h1 style={{ marginBottom: 0 }}>Televendas - Venda mix {currentMonth} - Por operador</h1>
        </div>
        <DashboardBody>
          <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              <Chart isLoading={isLoading} title="Venda Mix" keyLabel="mes" unit="" titleX="Mês" data={data} />
            </div>
          </Fragment>
        </DashboardBody>
      </div>
    );
  }
}
