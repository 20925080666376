import React, { Component } from "react";
import { Button, Modal, Icon, Upload } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi, fetchPostApi } from "actions";
import PageHeader, { BackButton } from "components/PageHeader";
import AnexosModal from "../AnexosModal";
import classnames from "classnames";

const decorateRows = (item, show) =>
  classnames("base-table--row", {
    "base-table--row__danger": show && item.vencido !== '0',
    "base-table--row__sucess": show && item.vencido === '0' && item.vencendo === '0' && item.atualizado !== '0',
    "base-table--row__invalid": show && item.vencido === '0' && item.vencendo !== '0',
  });

@observer
class AtrelarTreinamentoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: props.match.params.edit === 'atrelados' ? "Monitorar Treinamentos" : "Monitorar Anexos",
      uri: props.match.params.edit === 'atrelados' ? "/treinamento/" : "/treinamento/anexos_empresa",
      defaultFilter: {
        empresa: props.match.params.empresa || props.store.treinamentoFornecedorData?.codigo,
        requestPendencia: true
      },
    });

    this.state = {
      selected: null,
      download_uri: ''
    };
  }

  componentDidMount() {
    this.fetchMonitor();
  }

  fetchMonitor = async () => {
    const { match } = this.props
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi(`/treinamento/empresa/monitor_treinamento/${match.params.empresa || this.props.store.treinamentoFornecedorData?.codigo}`);
      this.handleMonitorData(response.data);
    } catch (error) {

    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleMonitorData = (data) => {
    const mapped = data.map((item) => {
      return item.id_treino;
    })

    this.setState({ selected: mapped });
  }

  handleSave = async () => {
    this.props.store.isLoading = true;
    try {
      const { match, history } = this.props;
      const { selected } = this.state;

      const { data: response } = await fetchPostApi(`/treinamento/empresa/atrelar`, { selecionados: selected, empresa: match.params.empresa });
      if (!!response.success) {
        Modal.success({
          title: "Salvo com sucesso!",
          content: "Monitoramento atualizado com sucesso!",
          onOk: () => history.goBack()
        });
      }
    } catch (error) {
      Modal.error({
        title: "Erro!",
        content: "Erro ao salvar treinamentos a empresa!"
      })
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleClick = () => {
    if (this.props.match.params.edit === 'atrelados') {
      this.handleSave();
    } else {
      this.ds.fetch();
    }
  }

  renderUploadItem = (anexos) => {
    const fileList = [];

    anexos && anexos.forEach((item, index) => {
      fileList.push({
        uid: index * -1,
        name: item.filename,
        status: 'done',
        url: item.download_uri,
      })
    });

    return (
      <Upload fileList={fileList} />
    )
  }

  render() {
    const { history, match } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        width: "20%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Sigla",
        dataIndex: "sigla",
        key: "sigla",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("sigla"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por sigla" {...props} />,
        filterParser: filter => ({ ...filter, field: "sigla", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (_, item) => {
          return (
            <div>{item.sigla}</div>
          )
        }
      },
      {
        title: "Necessita anexo?",
        dataIndex: "necessita_anexo",
        key: "necessita_anexo",
        width: "12%",
        render: (necessita_anexo) => {
          return (
            <div>{necessita_anexo === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Vigência?",
        key: "vigencia",
        dataIndex: "vigencia",
        width: '10%',
        render: (vigencia) => {
          return (
            <div>{vigencia === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Anexos",
        key: "anexos",
        dataIndex: "fn.anexos",
        className: "text-left",
        sorter: false,
        render: (item) => this.renderUploadItem(item),
        exportOnly: this.props.match.params.edit === 'atrelados'
      },
      {
        title: "Observação",
        key: "observacao",
        dataIndex: "obs",
        render: (obs) => {
          return (
            <WrappedValue value={obs} />
          )
        }
      }
    ];

    if (match.params.edit === 'anexos' || this.props.store.treinamentoFornecedorData?.codigo) {
      columns.push({
        title: "",
        key: "actionbar",
        width: "5%",
        export: false,
        render: (_, item) => {
          return (
            <Button
              disabled={item.interno === 'S' && !(!!match.params.empresa || !!this.props.store.treinamentoFornecedorData?.codigo)}
              onClick={() => this._anexosModal._reactInternals.child.stateNode.open(item.codigo, item.necessita_anexo, item.vigencia)}
              title="Monitorar anexos"
              size="small"
              type="primary">
              <Icon type="edit" />
            </Button>
          )
        }
      })
    }

    const rowSelection = {
      selectedRowKeys: this.state.selected,
      onChange: (selectedRowKeys) => {
        this.setState({ selected: selectedRowKeys });
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };

    if (match.params.edit !== "padrao") {
      return (
        <div className="report-screen">
          <PageHeader title={this.ds.name}
            headerLeft={<BackButton onClick={() => history.goBack()} />}
            headerRight={
              <Button loading={this.props.store.isLoading || this.ds.isLoading} onClick={this.handleClick} type="primary" icon="plus">
                {match.params.edit === 'atrelados' ? 'Salvar' : 'Atualizar'}
              </Button>
            }>
          </PageHeader>
          <FullTable
            actionBar="none"
            rowSelection={match.params.edit === 'atrelados' ? rowSelection : null}
            rowKey={item => (`${item.codigo}`)}
            loading={this.props.store.isLoading}
            columns={columns}
            dataSource={this.ds}
            rowClassName={item => decorateRows(item, this.props.match.params.edit !== 'atrelados')}
            errorMessage="Não foi possível obter os treinamentos no momento"
          />
          <AnexosModal ref={_ref => (this._anexosModal = _ref)} isLoading={this.props.store.isLoading} match={match} store={this.props.store} />
        </div >
      );
    } else {
      return (<></>)
    }
  }
  _anexosModal
}

export default withStore(AtrelarTreinamentoScreen);
