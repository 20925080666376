import React, { Component, Fragment } from "react";
import { Alert, Form, Button, Icon, Row, Col, Tag } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { SearchForm, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import moment from "moment";
import MemorandoStatusTag from "screens/common/Components/MemorandoStatusTag";
import ModalMemorando from "screens/common/Components/ModalMemorando";
import Select from "components/Select";
import classNames from "classnames";

const FormItem = Form.Item;

const decorateRows = (item, index) =>
  classNames("base-table--row", {
    "base-table--row__info": item.Processos.isaprovador === 'N',
  });

@observer
class MemorandoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Memorandos",
      uri: "/r/memorando",
      state: {
        filiais: {},
      },
    });

    this.state = {
      status: null
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
    };

    const selectOptions = [
      "Pendente", "Liberado", "Rejeitado", "Cancelado"
    ];

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          status: "text",
        }}>
        {({ form, submitForm }) => (
          <Fragment>
            <Row>
              <Col span={24}>
                <FormItem label="Filtro de status">
                  {form.getFieldDecorator("status")(
                    <Select
                      {...inputProps}
                      disabled={dataSource.isLoading || this.props.store.isLoading}
                      style={{ width: 150 }}
                      options={selectOptions}
                      allowClear
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Button type="danger" icon="search" onClick={() => setTimeout(submitForm, 30)} />
                </FormItem>
              </Col>
            </Row>
          </Fragment>
        )}
      </SearchForm>
    );
  };
  render() {
    const columns = [
      {
        title: "Processo",
        dataIndex: "Processos.processo",
        key: "processo",
        sorter: true,
        width: "15%",
        filteredValue: this.ds.filterValue("processo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por processo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Processos.processo|Processos.empresa", value: `${filter.value}%`, op: ":LIKE" }),
        render: (value, item) => {
          return (
            <WrappedValue value={<><Tag color="blue">{item.Processos.empresa}</Tag>{value}</>} />
          )
        }
      },
      {
        title: "Tipo",
        dataIndex: "Processos.tipo",
        key: "tipo",
        sorter: true,
        width: "9%",
        filteredValue: this.ds.filterValue("tipo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por tipo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Processos.tipo", value: `%${filter.value}`, op: ":LIKE" }),
        render: (_, item) => {
          return (
            <div>{item.Processos.tipo}</div>
          )
        }
      },
      {
        title: "Título",
        dataIndex: "Processos.titulo",
        key: "titulo",
        sorter: true,
        width: "35%",
        filteredValue: this.ds.filterValue("titulo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por titulo" {...props} />,
        filterParser: filter => ({ ...filter, field: "Processos.titulo", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (_, item) => {
          return (
            <div>{item.Processos.titulo}</div>
          )
        }
      },
      {
        title: "Data Bloqueio",
        dataIndex: "Processos.data_bloqueio",
        key: "data_bloqueio",
        sorter: true,
        width: "12%",
        render: (_, item) => {
          return (
            <div>{moment(item.Processos.data_bloqueio, "YYYYMMDD").format("DD/MM/YYYY")} {item.Processos.hora_bloqueio}</div>
          )
        }
      },
      {
        title: "Solicitante",
        key: "solicitante",
        exportOnly: true,
        dataIndex: "Processos.solicitante",
        sorter: true,
      },
      {
        title: "Solicitante",
        key: "solicitante",
        dataIndex: "Processos.nome_solicitante",
        sorter: true,
        width: "20%",
      },
      {
        title: "Aprovador",
        key: "aprovador",
        dataIndex: "Processos",
        sorter: true,
        exportOnly: true,
        width: "7%",
        render: (_, item) => {
          return (
            <div>{item.Processos.aprovador} / {item.Processos.nome_aprovador}</div>
          )
        }
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "Processos.status",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("status"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por status" {...props} />,
        filterParser: filter => ({ ...filter, field: "Processos.status", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (value) => {
          return (
            <MemorandoStatusTag status={value} />
          )
        }
      },
      {
        title: "",
        key: "actionbar",
        dataIndex: "Processos",
        width: "5%",
        render: (_, item) => {
          return (
            <Button
              type="primary"
              style={{ padding: "0px 9px 0px 7px" }}
              onClick={() => this._memo.open("Memorando #" + (item.Processos.tipo === 'Memorando' ? item.Processos.chave : item.Processos.processo) + " - " + moment(item.Processos.data_bloqueio, "YYYYMMDD").format("DD/MM/YYYY"), item)}
            >
              <Icon type="edit" />
            </Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} />
        <Alert
          message={
            <Fragment>
              <p style={{ fontSize: 13, marginTop: 1.5, marginBottom: 2 }}>
                Linhas em azul são memorandos onde você somente participa!
              </p>
            </Fragment>
          }
          type="info"
          showIcon
          style={{ marginBottom: 10 }}
        />
        <FullTable
          beforeComponent={this.renderSearchForm}
          rowKey={item => (`${item.Processos.processo}:${item.Processos.chave}`)}
          loading={this.props.store.isLoading}
          rowClassName={decorateRows}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter o relatório de processos no momento"
        />
        <ModalMemorando
          ref={_ref => (this._memo = _ref)}
          onClose={() => this.ds.fetch()} />
      </div>
    );
  }
  _memo = {};
}

export default withStore(MemorandoScreen);
