import React, { Component, Fragment } from "react";
import { Form, Button, DatePicker, Modal, Select, Icon } from "antd";
import { observer } from "mobx-react";

import { filterDate } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, SearchForm, Toolbar, WrappedValue } from "components/FullTable";

import moment from "moment";
import InputSearch from "components/InputSearch";
import ChooserGetManyClients from "components/ChooserGetManyClients";
import ChooserGetManySellers from "components/ChooserGetManySellers";

const FormItem = Form.Item;

@observer
class AnaliseVolumeReportScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codigo_cliente: null,
      tipo_filtro: null,
      codigo_representantes: [],
      codigos_clientes: [],
      data_inicio: moment().startOf("month"),
      data_fim: moment().endOf("month"),
    };

    this.ds = new TableDataSource({
      name: "Análise de Volume Máquinas",
      uri: "/r/analise-volume",
      controlled: true,
      defaultFilter: {
        data_inicio: this.state.data_inicio,
        data_fim: this.state.data_fim,
      },
      fetched: true,
    });
  }

  async chooseCliente(event, cod_cli, form, submitForm) {
    const { store } = this.props;

    if (event.target.value.trim() !== "") {
      store.isLoading = true;

      try {
        form.setFieldsValue({ codigo_cliente: cod_cli });
      } catch (error) {
        Modal.error({
          title: "Atenção",
          content: "Ocorreu um erro ao tentar selecionar o cliente",
        });
        form.setFieldsValue({ codigo_cliente: '' });
      } finally {
        store.isLoading = false;
      }
    } else {
      form.setFieldsValue({ codigo_cliente: '' });
      setTimeout(submitForm, 30)
    }
  }

  componentDidMount = () => {
    if (this.props.store.isVendedor || this.props.store.IsOperador) {
      this._options = this._options.filter(item => item.value !== "R");
    }
  }

  imprimirTudo = () => {
    if (!this.state.data_inicio || !this.state.data_fim) {
      return Modal.error({
        title: 'Exportar',
        content: 'É obrigatório informar as datas para realizar a exportação'
      });
    }

    if (moment(this.state.data_inicio).isAfter(moment(this.state.data_fim))) {
      return Modal.error({
        title: 'Exportar',
        content: 'A data início não pode ser maior do que a data fim'
      });
    }

    return this.props.store.requestPrintForm(`/r/analise-volume-imprimir/${moment(this.state.data_inicio).format('YYYY-MM-DD')}/${moment(this.state.data_fim).format('YYYY-MM-DD')}`);
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    const inputProps = {
      readOnly: dataSource.isLoading,
      format: "DD/MM/YYYY",
    };

    const initialValues = {
      data_inicio: this.state.data_inicio,
      data_fim: this.state.data_fim,
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        initialValues={initialValues} // Passa os valores iniciais aqui
        filterFields={{
          data$0: "date",
          data$1: "date",
          group$2: "select",
          codigos_clientes: "text",
          cod_representante: "text"
        }}
        filterParser={{
          data$0: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: ">=" }),
          data$1: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date", op: "<=" }),
          group$2: filter => ({ ...filter, value: filter.value, type: "radio" }),
          codigos_clientes: filter => ({
            ...filter,
            field: "cod_cliente",
            value: `(${filter.value})`,
            op: "IN",
          }),
          cod_representante: filter => ({
            ...filter,
            field: "cod_representante",
            value: `(${filter.value})`,
            op: "IN",
          }),
        }}>
        {({ form, submitForm }) => (
          <Fragment>
            <FormItem label="Filtrar por Data de Faturamento da máquina">
              {form.getFieldDecorator("data_inicio", {
                initialValue: this.state.data_inicio,
              })(<DatePicker placeholder="Data Inicial" onChange={e => this.setState({ data_inicio: e })} {...inputProps} />)}
            </FormItem>
            <FormItem>
              {form.getFieldDecorator("data_fim", {
                initialValue: this.state.data_fim,
              })(<DatePicker placeholder="Data Final" onChange={e => this.setState({ data_fim: e })} {...inputProps} />)}
            </FormItem>
            <FormItem label="Tipo filtro">
              <Select
                style={{ width: 150 }}
                onChange={e => {
                  this.setState({ codigo_cliente: null, tipo_filtro: e });
                  form.setFieldsValue({ codigos_clientes: null, cod_representante: null });
                }}
                value={this.state.tipo_filtro}
              // disabled={!(this.props.store.isSupervisor || this.props.store.isDiretor)}
              >
                {this._options.map(item => (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </FormItem>
            {this.state.tipo_filtro === "C" &&
              <FormItem label="Cliente">
                {form.getFieldDecorator("codigos_clientes")(
                  <InputSearch
                    onBlur={event => this.chooseCliente(event, event.target.value, form, submitForm)}
                    onSearch={() => this._choosers.cliente.open((value) => {
                      form.setFieldsValue({ codigos_clientes: value.map(item => item.codigo) });
                      this.setState({ codigos_clientes: value.map(item => item.codigo) });
                    })}
                    // onPressEnter={event => this.chooseCliente(event, event.target.value, form, submitForm)}
                    maxLength="0"
                  />
                )}
              </FormItem>
            }
            {this.state.tipo_filtro === "R" &&
              <FormItem label="Representante">
                {form.getFieldDecorator("cod_representante")(
                  <InputSearch
                    onBlur={event => this.chooseCliente(event, event.target.value, form, submitForm)}
                    onSearch={() => this._choosers.representantes.open((value) => {
                      form.setFieldsValue({ cod_representante: value.map(item => item.vendedor_atendimento) });
                      this.setState({ codigo_representantes: value.map(item => item.vendedor_atendimento) });
                    })}
                    // onPressEnter={event => this.chooseCliente(event, event.target.value, form, submitForm)}
                    maxLength="0"
                  />
                )}
              </FormItem>
            }
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
            {this.props.store.isSupervisor &&
              <FormItem style={{ float: 'right' }}>
                <Button onClick={this.imprimirTudo} type="primary" title="Imprimir">Imprimir tudo <Icon type="printer" /></Button>
              </FormItem>
            }
          </Fragment>
        )}
      </SearchForm>
    );
  };


  render() {
    var columns = [
      {
        title: 'Cod. cliente',
        dataIndex: "cod_cliente",
        key: "cod_cliente",
        sorter: false,
      },
      {
        title: 'Cliente',
        dataIndex: "nome_cliente",
        key: "nome_cliente",
        sorter: false,
      },
      {
        title: <span className="th-small-label">Cód. pai</span>,
        dataIndex: "cod_pai",
        key: "cod_pai",
        sorter: true,
        filteredValue: this.ds.filterValue("cod_pai"),
      },
      {
        title: 'Nm. pai',
        dataIndex: "nome_pai",
        key: "nome_pai",
        sorter: false,
      },
      {
        title: 'Cód. produto',
        dataIndex: "cod_produto",
        key: "cod_produto",
        sorter: true,
      },
      {
        title: 'Nm. produto',
        dataIndex: "nome_produto",
        key: "nome_produto",
        sorter: false,
      },
      {
        title: 'Nm. série',
        dataIndex: "serie",
        key: "serie",
        sorter: false,
      },
      {
        title: 'Linha',
        dataIndex: "linha",
        key: "linha",
        sorter: false,
      },
      {
        title: 'Volume Aut.',
        dataIndex: "volume_automotivo",
        key: "volume_automotivo",
        sorter: false,
      },
      {
        title: 'Fat. Aut.',
        dataIndex: "faturamento_automotivo",
        key: "faturamento_automotivo",
        sorter: false,
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: 'Volume imob.',
        dataIndex: "volume_imobiliario",
        key: "volume_imobiliario",
        sorter: false,
      },
      {
        title: 'Fat. imob.',
        dataIndex: "faturamento_imobiliario",
        key: "faturamento_imobiliario",
        sorter: false,
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: 'Código representante',
        dataIndex: "cod_representante",
        key: "cod_representante",
        sorter: false
      }
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}></PageTitle>
        <FullTable
          className="full-table__compact"
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.id}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter suas análises no momento"
          actionBar={<Toolbar exportable={false} printable={false} />}
        />
        <ChooserGetManyClients
          ref={_ref => (this._choosers["cliente"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <ChooserGetManySellers
          ref={_ref => (this._choosers["representantes"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
      </div>
    );
  }
  _choosers = {}
  _options = [
    {
      value: "C",
      label: "Cliente"
    },
    {
      value: "R",
      label: "Representante"
    }
  ]
}

export default withStore(AnaliseVolumeReportScreen);
