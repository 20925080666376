import { Button, Form, Row, Col } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import withStore from 'withStore';
import FormLancamentos from './FormLancamentos';
import IntroduceModal from './IntroduceModal';
import AnexosModal from './AnexosModal';

@Observer
class StepLancamentos extends Component {
  constructor(props) {
    super(props);

    const dtfimv = moment(this.props.promotoria.Promotoria.zy7_dtfimv, 'DD-MM-YYYY');
    const currentDate = moment();

    this.state = {
      anexos: props.promotoria.Anexos ?? [],
      isDisableButton: dtfimv.isAfter(currentDate),
      withPalestra: this.props.promotoria.Atividades.filter(item => item.zy8_tpativ === "P" && item.zy8_lancad === "N") ?? [],
      isVisible: false,
      modalAnexos: false
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleModalAnexos = this.handleModalAnexos.bind(this);
  }

  componentDidMount() {
    this.setState({ anexos: this.props.promotoria?.Anexos });
  }

  handleOpenModal() {
    this.setState({ isVisible: true });
  }

  handleCloseModal() {
    this.setState({ isVisible: false });
  }

  handleModalAnexos() {
    if (this.state.modalAnexos) {
      this.setState({ modalAnexos: false });
    } else {
      this.setState({ modalAnexos: true });
    }
  }

  render() {
    return (
      <div>
        {this.state.withPalestra.map(item => (
          <FormLancamentos isDisableButton={this.state.isDisableButton} atividade={item} promotoria={this.props.promotoria} />
        ))}
        {this.props.promotoria.Lancamentos.map(item => (
          <FormLancamentos isDisableButton={this.state.isDisableButton} promotoria={this.props.promotoria} lancamento={item} />
        ))}
        {!(this.state.withPalestra.length || this.props.promotoria.Lancamentos.length) && <h4><strong>Não há lançamentos</strong></h4>}
        {(this.props.promotoria.Atividades.length >= 1 || !this.props.promotoria.isFinishPromotoria) &&
          <Row gutter={8} style={{ marginTop: 20 }}>
            <Col span={4}>
              <Form.Item label="Documentos">
                <Button onClick={this.handleModalAnexos} disabled={this.state.isDisableButton || this.props.promotoria.isDisabledAntesLancamento} type="primary">Visualizar documentos</Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Informações adicionais">
                <Button onClick={this.handleOpenModal} disabled={this.state.isDisableButton || this.props.promotoria.isDisabledAntesLancamento} ghost type="primary">Lançar informações Adc.</Button>
              </Form.Item>
            </Col>
          </Row>
        }
        {!!this.props.promotoria && <IntroduceModal handleClose={this.handleCloseModal} isVisible={this.state.isVisible} promotoria={this.props.promotoria} />}
        {!!this.props.promotoria && !!this.state.anexos && this.state.modalAnexos && <AnexosModal promotoria={this.props.promotoria} anexos={this.state.anexos} isVisible={this.state.modalAnexos} handleClose={this.handleModalAnexos} />}
      </div>
    );
  }
}

export default withStore(Form.create({ name: 'lancamentos-finais' })(StepLancamentos));