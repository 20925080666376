import React, { useEffect, useState } from "react";
import { Carousel, Col, Icon, Popover, Row, Spin } from "antd";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { fetchPostApi } from "actions";
import "./FloatButton.css";

const animation = isImg => `
  @keyframes slide-in {
    0% { -webkit-transform: translateX(${isImg ? '-200%' : '-250%'}); }
    100% { -webkit-transform: translateX(0%); }
  }

  @-webkit-keyframes slide-in {
    0% {transform: translateX(${isImg ? '-200%' : '-250%'}); }
    100% { transform: translateX(0%); }
  }

  @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(${isImg ? '-200%' : '-250%'}); }
  }

  @-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(${isImg ? '-200%' : '-250%'}); }
  }
`

const FloatSvg = styled.img`
  position:fixed;
  height:35px;
  bottom:30px;
  left:23px;
  text-align:center;
  z-index: 99999;
  color:#FFF;
  filter: drop-shadow( 2px 2px 4px rgba(0, 0, 0, .7));
  cursor: pointer;
  &:hover {
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7));
  }

  ${props => props.selected ?
    `
      animation: slide-in 0.5s forwards;
      -webkit-animation: slide-in 0.5s forwards;
    `
    :
    `
      animation: slide-out 0.5s forwards;
      -webkit-animation: slide-out 0.5s forwards;
    `
  }
  
  ${animation(true)}
`;

const FloatIcon = styled(Icon)`
  position:fixed;
  height:35px;
  bottom:20px;
  left:67px;
  text-align:center;
  z-index: 99999;
  cursor: pointer;

  ${props => props.selected ?
    `
      animation: slide-in 0.5s forwards;
      -webkit-animation: slide-in 0.5s forwards;
    `
    :
    `
      animation: slide-out 0.5s forwards;
      -webkit-animation: slide-out 0.5s forwards;
    `
  }

  ${animation(false)}
`;

function FloatButton() {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [locationState, setLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("Ainda não existe ajuda para esta tela!");
  const [imagens, setImagens] = useState();
  const location = useLocation();

  const fetchHelp = async (key) => {
    try {
      setLoading(true);

      const { data: response } = await fetchPostApi('/info', { chave: key });

      if (!!response.data?.descritivo) {
        setData(response.data.descritivo);
        setSelected(true);
      } else {
        setData("Ainda não existe ajuda para esta tela!");
      }

      if (!!response.imagens) {
        if (!response.data?.descritivo) {
          setData("");
        }
        setImagens(response.imagens);
        setSelected(true);
      }

      if (!response.data?.descritivo && (response.imagens && response.imagens.length <= 0)) {
        setSelected(false);
        setData("Ainda não existe ajuda para esta tela!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    setLocation(key);
  }

  useEffect(() => {
    const currentLocation = location.pathname.slice(1);

    if (currentLocation !== locationState) {
      fetchHelp(currentLocation);
    }
  }, [location, locationState]);

  const divStyle = !!imagens && imagens.length > 0 && { width: window.innerWidth * 0.7, height: window.innerHeight * 0.8 }

  const content =
    <Spin spinning={loading}>
      <div style={{ ...divStyle }}>
        <Row>
          <Col span={23}>
            <span dangerouslySetInnerHTML={{ __html: data }} />
          </Col>
          <Col span={1} style={{ textAlign: "end" }}>
            <Icon type="close" style={{ fontSize: 20, fontWeight: "bold", cursor: "pointer" }} onClick={() => setVisible(false)} />
          </Col>
        </Row>
        {!!imagens && imagens.length > 0 &&
          <Carousel>
            {
              imagens.map((img, index) => (
                <div key={index} className="image-container">
                  <img src={img} alt="" />
                </div>
              ))
            }
          </Carousel>
        }
      </div>
    </Spin>

  return (
    <div id="teste">
      <Popover placement="rightBottom"
        overlayClassName="left100"
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={visible => { setVisible(visible) }}>
        <FloatSvg selected={selected} src={require("img/help.svg")} />
      </Popover>
      <FloatIcon type={selected ? "caret-left" : "caret-right"}
        selected={selected}
        onClick={() => setSelected(!selected)} />
    </div>
  );
}

export default FloatButton;