import React, { Component } from "react";
import { autorun } from "mobx";
import { Icon } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";


const rowKey = item =>
  `${item.Produto.codigo}:${item.Produto.classe_produto}:${!!item.ITTAB ? item.ITTAB.codigo_tabela : "0"}`;


const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TagCashBack = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

@observer
export default class ChooserProduto extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/produtos/000000-00-00/C/REVENDA");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { data } = this.props.pedido;
        const codigo_cliente = `${data.codigo_cliente}`.padStart(6, "0");
        const loja_cliente = !!data.loja_cliente ? `${data.loja_cliente}`.padStart(2, "0") : "01";
        const filial_faturamento = !!data.filial_faturamento ? `${data.filial_faturamento}`.padStart(2, "0") : "01";
        const tipo_transp = this.props.pedido.tipoFreteTabela;
        const codigo = [codigo_cliente, loja_cliente, filial_faturamento].join("-");

        if (data.orcamentoTintometrico) {
          this.ds.uri = "/pedidos/produtos/" + codigo + "/" + tipo_transp + "/" + data.unidade_negocio + "/T"
        } else if (data.isValidKit && data.num_kit) {
          this.ds.uri = "/pedidos/produtos/" + codigo + "/" + tipo_transp + "/" + data.unidade_negocio + "/K/" + data.num_kit
        } else {
          this.ds.uri = "/pedidos/produtos/" + codigo + "/" + tipo_transp + "/" + data.unidade_negocio
        }

      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Linha",
        key: "linha_produto",
        dataIndex: "Produto.linha_produto",
        width: "14.5%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo",
        width: "13%",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.Produto.possui_cashback === 'SIM' && <TagCashBack> <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />CASHBACK</TagCashBack>}
          </DescricaoCell>
        ),
      },
      !this.props.isB2B
        ? {
          title: "Tipo",
          key: "tipo_produto",
          dataIndex: "Produto.tipo_produto",
          width: "12%",
        }
        : {
          title: "Preço",
          key: "preco_tabela",
          dataIndex: "Produto.preco_tabela",
          dataType: "money",
          width: "12%",
        },
      {
        title: "Qtd. Emb.",
        key: "quantidade_por_fardo",
        dataIndex: "Produto.quantidade_por_fardo",
        width: "10%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Encontre um Produto"
        errorMessage="Não foi possível obter seus produtos no momento"
        searchPlaceholder="Busque pelo código ou descrição"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        width={860}
        {...this.props}
      />
    );
  }
}
