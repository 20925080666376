import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { n } from "@util";

const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;

  > strong {
    display: block;
  }
`;

const VerbaBonResumoWrapper = styled.div`
  .ant-row {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const Item = ({ label = null, children, ...props }) => (
  <ItemWrapper {...props}>
    {!!label && <strong>{`${label}:`}</strong>}
    {children}
  </ItemWrapper>
);

@observer
export default class VerbaBonResumo extends Component {
  render() {
    const { pedido, options, ...props } = this.props;
    const { data } = pedido;
    const total_bonificado = pedido.total_bonificado;
    //utilizo o tratamento abaixo para diminuir do total utilizado o valor bonificado no pedido atual para nao considerar duplicacaoes em valor bonificado...
    //const verba_aux = data.verba_utilizado > 0 ? data.verba_utilizado - total_bonificado : data.verba_utilizado;
    //-------------------------------------------------------------------------------------------------------------------------------------------------------
    const verba_utilizado = data.verba_utilizado;//verba_aux < 0 ? 0 : verba_aux;
    const saldo_bonificado = data.verba_valor - (verba_utilizado + total_bonificado);

    return (
      <VerbaBonResumoWrapper {...props}>
        <Row gutter={12}>
          <Col span={7}>
            <Item label="Centro de custo">{data.verba_descc}</Item>
          </Col>
          <Col span={3}>
            <Item label="Total Liberado">R$ {n(data.verba_valor)}</Item>
          </Col>
          <Col span={5}>
            <Item label="Valor utilizado(outros pedidos)">R$ {n(verba_utilizado)}</Item>
          </Col>
          <Col span={6}>
            <Item label="Valor Itens Bonificados(Este pedido)">R$ {n(total_bonificado)}</Item>
          </Col>
          <Col span={3}>
            <Item label="Saldo">
              <Button
                disabled={false}
                title="saldo"
                size="large"
                type="default"
                style={saldo_bonificado < 0
                  ? { fontSize: 12, height: 23, letterSpacing: -0.5, background: "#AC1A22", borderColor: "white", color: "white" }
                  : { fontSize: 12, height: 23, letterSpacing: -0.5, background: "green", borderColor: "white", color: "white" }
                }>
                R$ {n(saldo_bonificado)}
              </Button>

            </Item>
          </Col>
        </Row>
      </VerbaBonResumoWrapper>
    );
  }
}
