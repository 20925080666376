import React, { Component } from "react";
import { observer } from "mobx-react";
import { TableDataSource } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.codigo}:${item.loja}`;

@observer
export default class ChooserCliente extends Component {
    constructor(props) {
        super(props);
        this.ds = new TableDataSource("/promotoria/clientes");
    }

    componentWillUnmount() {
        if (!!this._watchDS) this._watchDS();
    }

    onSelectRow = (value) => {
        this.props.onSelectCliente(value);
        this.close();
    };

    close = () => {
        this.props.onClose();
    };

    render() {
        const { visible } = this.props;

        const columns = [
            {
                title: "Código",
                key: "codigo",
                width: "13.5%",
            },
            {
                title: "Cliente",
                key: "razao_social",
                className: "text-left",
                width: "67.5%",
            },
            {
                title: "CNPJ",
                key: "cnpj",
                width: "19%",
            },
        ];

        return (
            <ChooserModal
                visible={visible}
                rowKey={rowKey}
                columns={columns}
                dataSource={this.ds}
                title="Encontre o cliente"
                errorMessage="Não foi possível obter os clientes no momento"
                searchPlaceholder="Busque pelo código ou descrição"
                onSelectRow={this.onSelectRow}
                onCancel={this.close}
                width={860}
                {...this.props}
            />
        );
    }
}
