import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class MenuAprovador extends PureComponent {
  render() {
    // eslint-disable-next-line
    const { tipoDiretor, collapsed = false, selected = [], Diretor_unidade_negocio } = this.props;
    const submenuKeys = [];

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__vendedor", "sidebar-nav__diretor")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subAprov"
            title={
              <span>
                <Icon type="check" />
                <span>Aprovações/Liberações</span>
              </span>
            }>
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-dir-memorando">
                <Link to="/memorando">Memorando</Link>
              </MenuItem>
            )}
          </SubMenu>

          <SubMenu
            key="subLinks"
            title={
              <span>
                <Icon type="link" />
                <span>Links Úteis</span>
              </span>
            }>
            <MenuItem key="nav-links-produtos">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvTHZTaWE5TGFYUEU?resourcekey=0-oL1MxFa_2UqT3YEk7DO9jw">Catálogo de Produtos</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvaWdvRDloc1FMUlk?resourcekey=0-FkGzi6rE9fFRO_iPDcR03A">Catálogo de Cor</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-arsenal">
              <ExternalLink to="https://arsenal.anjo.com.br">Arsenal ANJO</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://www.anjo.com.br/fale-conosco/telefones">Telefones Úteis</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://anjotintas.zendesk.com/hc/pt-br/requests/new">Abertura SAC - ZENDESK</ExternalLink>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuAprovador);
