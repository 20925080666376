import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { TableDataSource } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.login}:${item.senha}`;

@observer
export default class ChooserPromotor extends Component {
  constructor(props) {
    super(props);
    this.ds = new TableDataSource("/promotoria/buscar-promotores");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }

  componentDidMount() {
    this._watchDS = autorun(
      () => { }, { delay: 60 })
  }

  // componentWillUnmount() {
  //     if (!!this._watchDS) this._watchDS();
  // }

  onSelectRow = (promotor, selectedRowKeys) => {
    const { onChoose } = this.state;

    if (!!promotor && !!onChoose && typeof onChoose === "function") {
      onChoose(promotor, selectedRowKeys);
    }
    this.close();
  };

  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };

  render() {
    const columns = [
      {
        title: "Código",
        key: "login",
        width: "10%",
      },
      {
        title: "Cliente",
        key: "nome",
        className: "text-left",
        width: "65%",
      },
      {
        title: "Tipo",
        key: "tipo",
        width: "15%",
      },
      {
        title: "Status",
        key: "status",
        width: "10",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Selecione o promotor"
        errorMessage="Não foi possível obter os promotores no momento"
        searchPlaceholder="Busque pelo código ou descrição"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        width={860}
        {...this.props}
      />
    );
  }
}
