import React, { Component, Fragment } from "react";
import { Alert, Button } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import { n } from "@util";
import withStore from "withStore";
import { default as StyledProdutoTag } from "components/pedidos/ProdutoTag";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import classnames from "classnames";

const PedidoTag = styled(StyledProdutoTag)`
  &.ant-tag {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.3px;
    padding: 1px 3px;
    margin: 3px 3px 1px 0;
    height: auto;
    cursor: default;
  }
`;

const decorateRows = (item, index) =>
  classnames("base-table--row", {
    "base-table--row__odd": index % 2 !== 0,
    "base-table--row__sucess": item.Acomp.resultado.indexOf("SUBINDO") !== -1,
    "base-table--row__danger": item.Acomp.resultado.indexOf("CAINDO") !== -1,
    "base-table--row__invalid": item.Acomp.resultado.indexOf("MANTENDO") !== -1,
    "base-table--row__disabled": item.Acomp.resultado.indexOf("CANAL NAO MOV") !== -1,
  });

const VolumeExtra = observer(({ item }) => {
  const tags = [];

  if (!!item.Acomp.linha_perfil) {
    tags.push({
      key: `linha`,
      color: "#09609c",
      title: `Linha: ${item.Acomp.linha_perfil}`,
      children: `${item.Acomp.linha_perfil}`,
    });
  }
  if (!!item.Acomp.perfil) {
    tags.push({
      key: `perfil`,
      color: "#09609c",
      title: `Linha: ${item.Acomp.perfil}`,
      children: `${item.Acomp.perfil}`,
    });
  }
  if (!!item.Acomp.perfil_forcado) {
    tags.push({
      key: `perfil_forcado`,
      color: "#09609c",
      title: `Linha: ${item.Acomp.perfil_forcado}`,
      children: `Forçado: ${item.Acomp.perfil_forcado}`,
    });
  }

  if (tags.length > 0) {
    return (
      <div className="produto-extra-tags pedido-extra-tags">
        {tags.map(tag => !!tag.children &&
          <PedidoTag {...tag} >
            {tag.children}
          </ PedidoTag>)}
      </div>
    );
  }

  return null;
});

@observer
class VolumePerfilScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Volume Perfil",
      // uri: "/r/volume_perfil",
      uri: !isMop ? "/r/volume_perfil" : "/r/volume_perfil/mop",
      controlled: false,
    });

    this.state = {
      isMop,
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    return (
      <Fragment>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "normal" }}>
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
            message={`Cor perfis "Caindo"! `}
            type="error"
            showIcon
          />
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15 }}
            message={`Cor Perfis "Mantendo"! `}
            type="warning"
            showIcon
          />
          <Alert
            style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginLeft: 15 }}
            message={`Cor perfis "Subindo"! `}
            type="success"
            showIcon
          />
        </div>

      </Fragment>

    );
  };
  render() {
    const { store } = this.props;

    const isDiretor = store.tipoDiretor === "DIRETOR";
    const isGerente = store.tipoDiretor === "GERENTE";

    const columns = [
      {
        title: "ID.",
        dataIndex: "Acomp.recnozpq",
        key: "Acomp.recnozpq",
        width: "5%",
      },
      {
        title: "Pai",
        dataIndex: "Acomp.cod_pai",
        className: "text-left",
        key: "Acomp.cod_pai",
        width: "9%",
        sorter: false,
        filteredValue: this.ds.filterValue("cod_pai"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Pai" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.cod_pai", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Código",
        dataIndex: "Acomp.cod_cliente",
        key: "Acomp.cod_cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cod_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código de Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "13%",
      },
      {
        title: "Loja",
        dataIndex: "Acomp.loja_cliente",
        width: "6.5%",
      },
      {
        title: "Cliente",
        dataIndex: "Acomp.nome_cliente",
        className: "text-left",
        key: "Acomp.nome_cliente",
        width: "19%",
        sorter: false,
        filteredValue: this.ds.filterValue("nome_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.nome_cliente", value: `%${filter.value}%`, op: "LIKE" }),
        render: (text, item) => (
          <Fragment>
            {text}
            <VolumeExtra item={item} />
          </Fragment>
        ),
      },
      {
        title: "Vendedor",
        dataIndex: "Acomp.nome_vendedor",
        className: "text-left",
        key: "Acomp.nome_vendedor",
        width: "15%",
        exportOnly: !isGerente && !isDiretor,
        sorter: true,
        filteredValue: this.ds.filterValue("nome_vendedor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Vendedor" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.nome_vendedor", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Gerente",
        dataIndex: "Acomp.nome_gerente",
        className: "text-left",
        key: "Acomp.nome_gerente",
        width: "15%",
        exportOnly: true,
        sorter: true,
        filteredValue: this.ds.filterValue("nome_gerente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Gerente" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.nome_gerente", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Regiao",
        dataIndex: "Acomp.nmregiao_tabela",
        className: "text-left",
        key: "Acomp.nmregiao_tabela",
        width: "15%",
        exportOnly: !isDiretor,
        sorter: true,
        filteredValue: this.ds.filterValue("nmregiao_tabela"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Regiao" {...props} />,
        filterParser: filter => ({ ...filter, field: "Acomp.nmregiao_tabela", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Linha",
        dataIndex: "Acomp.linha_perfil",
        key: "Acomp.linha_perfil",
        width: "9%",
        exportOnly: true,
      },
      {
        title: "Perfil",
        dataIndex: "Acomp.perfil",
        key: "Acomp.perfil",
        width: "10%",
        exportOnly: true,
      },
      {
        title: "Forçado ?",
        dataIndex: "Acomp.perfil_forcado",
        key: "Acomp.perfil_forcado",
        width: "12%",
        sorter: false,
        exportOnly: true,
      },
      {
        title: "Meta Perf.Atu",
        dataIndex: "Acomp.meta_perfil_atual",
        key: "Acomp.meta_perfil_atual",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "14%",
      },
      {
        title: "Meta Mensal",
        dataIndex: "Acomp.meta_mensal",
        key: "Acomp.meta_mensal",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "13%",
      },
      {
        title: "Volume total",
        dataIndex: "Acomp.quantidade_total",
        key: "Acomp.quantidade_total",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "13%",
      },
      {
        title: "Real.Mensal",
        dataIndex: "Acomp.realizado_mensal",
        key: "Acomp.realizado_mensal",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "12.3%",
      },
      {
        title: "F. p/manter",
        dataIndex: "Acomp.quantidade_faltante",
        key: "Acomp.quantidade_faltante",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "12%",
      },
      {
        title: "F. p/subir",
        dataIndex: "Acomp.quantidade_superior",
        key: "Acomp.quantidade_superior",
        className: "text-right",
        sorter: false,
        render: text => <WrappedValue value={n(+text, 2)} type="text" />,
        width: "11%",
      },
      {
        title: "Resultado",
        dataIndex: "Acomp.resultado",
        key: "Acomp.resultado",
        sorter: true,
        width: "19%",
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
          <div>
            {store.isOperador && (
              <Button
                title="Modo de listagem dos clientes do operador"
                type="primary"
                icon="filter"
                ghost={!!this.state.isMop}
                onClick={() =>
                  this.props.history.push(!this.state.isMop ? `/r/volume_perfil-mop` : `/r/volume_perfil`)
                }>
                {!this.state.isMop ? `Apenas meus clientes` : `Todos os clientes`}
              </Button>
            )}
          </div>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          rowClassName={decorateRows}
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          //rowKey={item => `${item.Acomp.cod_pai}:${item.Acomp.cod_cliente}:${item.Acomp.loja_cliente}:${item.Acomp.linha_perfil}` }
          rowKey={item => `${item.Acomp.recnozpq}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter seus clientes no momento"
        />
      </div>
    );
  }
}

export default withStore(VolumePerfilScreen);
