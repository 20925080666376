import React, { Fragment } from "react";
import { Alert, Col, DatePicker, Divider, Form, Modal, Row, Spin, message } from "antd";
import { observer } from "mobx-react";
import { PureComponent } from "react";
import withStore from "withStore";
import { fetchApi, fetchPostApi } from "actions";
import moment from "moment";
import styled from "styled-components";

const ContainerAtividades = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 320px;
`;

@observer
class AtualizarPromotorModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      datasOcupadasPromotor: [],
      dtinicio: null,
      dtfim: null,
    }

    if (!this.props.store.isSupervisor) {
      this.buscarDatasSelecionadas();
    }
  }

  verificarDatasPromotor = async (inicio, fim) => {
    try {
      this.props.promotoria.isLoading = true;

      const formData = {
        promotor: this.props.promotoria.Promotoria.zy7_codpro,
        data_inicio: moment(inicio).format('YYYYMMDD'),
        data_fim: moment(fim).format('YYYYMMDD')
      }

      const { data } = await fetchPostApi(`/promotoria/verificar-datas-promotor`, formData);

      if (data.isContais) {
        Modal.confirm({
          title: 'AVISO',
          content: 'A data escolhida já está reservada no calendário do promotor selecionado.',
          onOk: () => { },
          onCancel: () => {
            this.setState({ dtfim: null, dtinicio: null });
          },
          okText: 'De acordo'
        })
      }

      return
    } catch (error) {
    } finally {
      this.props.promotoria.isLoading = false;
    }
  }

  novaData = async () => {
    try {
      this.props.promotoria.isLoading = true;

      const payload = {
        dtinicio: this.state.dtinicio,
        dtfim: this.state.dtfim,
        atividades: this.props.promotoria.Atividades,
        cod_promotor: this.props.promotoria.Promotoria.zy7_codpro,
        nome_promotor: this.props.promotoria.Promotoria.zy7_promot,
        cod_sol: this.props.promotoria.Promotoria.zy7_codsol
      }

      const { data } = await fetchPostApi(`/promotoria/atualizar-data/${this.props.promotoria.Promotoria.r_e_c_n_o_}`, payload);

      this.props.promotoria.Promotoria.zy7_dtiniv = this.state.dtinicio;
      this.props.promotoria.Promotoria.zy7_dtfimv = this.state.dtfim;

      this.setState({ visible: false, dtinicio: null, dtfim: null });

      Modal.success({
        title: 'Data',
        content: data
      });

      window.location.href = '/promotoria/index';
    } catch (error) {
      if (!!error.response) {
        return message.error(error.response.data.message);
      }
      console.log(error);
      return message.error('Erro ao alterar o promotor');

    } finally {
      this.props.promotoria.isLoading = false;
    }
  }

  handleDateAtividade = (data, atividade) => {

    if (atividade) {
      if (!data) {
        data = moment();
      }
      this.props.promotoria.handleDateAtividade(data, atividade)
    }
    this.forceUpdate();
  };

  disabledDateAtividades = (current) => {
    const startDate = moment(this.state.dtinicio, 'DD-MM-YYYY').subtract(1, 'day');
    let endDate = moment(this.state.dtfim, 'DD-MM-YYYY');

    return current < startDate || current > endDate;
  };

  disabledDate = (currentDate) => {
    const dataInicio = this.state.dtinicio;
    const { datasOcupadasPromotor } = this.state;
    const dataFormat = 'YYYYMMDD';
    const datasAposSelecionado = datasOcupadasPromotor
      .filter(registro => moment(registro.zyd_dtiniv, dataFormat).isAfter(moment(dataInicio).format(dataFormat)))
      .map(registro => moment(registro.zyd_dtiniv, dataFormat));

    const isDisabledBeforeStart = dataInicio && currentDate && currentDate.isBefore(dataInicio, 'day');

    const isDisabledFromDatabase = datasOcupadasPromotor.some(registro => {
      const dataInicioRegistro = moment(registro.zyd_dtiniv, dataFormat);
      const dataFimRegistro = moment(registro.zyd_dtfimv, dataFormat);

      // Verifica se currentDate não é undefined antes de chamar isBetween
      return currentDate && currentDate.isBetween(dataInicioRegistro, dataFimRegistro, 'day', '[]');
    });

    const disableDataAfterInicio = datasAposSelecionado.some(item => currentDate && currentDate.isAfter(item));

    return isDisabledBeforeStart || isDisabledFromDatabase || disableDataAfterInicio;
  };

  buscarDatasSelecionadas = async () => {
    const codPromotor = this.props.promotoria.Promotoria.zy7_codpro;

    if (!this.props.store.isSupervisor) return;

    try {
      this.setState({ isLoading: true });
      const { data } = await fetchApi(`/promotoria/datas-selecionadas/${codPromotor}`);
      this.setState({ datasOcupadasPromotor: data });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Modal
        maskClosable={false}
        title="Atualizar promotor"
        visible={this.props.isVisible}
        onOk={this.novaData}
        onCancel={this.props.onClose}
      >
        <Spin spinning={this.props.promotoria.isLoading}>
          <Fragment>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Data início">
                  <DatePicker onChange={(value) => {
                    this.setState({ dtinicio: value });

                    if (value && value.isAfter(this.state.dtfim)) {
                      this.setState({ dtfim: value });
                      this.verificarDatasPromotor(value, value);
                      return;
                    }

                    if (value && this.state.dtfim) {
                      this.verificarDatasPromotor(value, this.state.dtfim);
                    }
                  }} format='DD/MM/YYYY' value={this.state.dtinicio} disabledDate={this.disabledDate} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Data fim">
                  <DatePicker onChange={(value) => {
                    this.setState({ dtfim: value });
                    if (value && this.state.dtinicio) {
                      this.verificarDatasPromotor(this.state.dtinicio, value);
                    }
                  }} format='DD/MM/YYYY' value={this.state.dtfim} disabledDate={this.disabledDate} />
                </Form.Item>
              </Col>
            </Row>
            <Alert
              message="Ao alterar a data será obrigatório atualizar as datas das atividades."
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
            <h3 style={{ marginTop: 10, marginBottom: 10 }}>Atividades</h3>
            <ContainerAtividades>
              {this.props.promotoria.Atividades.map((atividade, index) => (
                <Row gutter={8} key={index}>
                  <p>Atividade: {atividade.zy8_tpativ === "V" && "Visitas"} {atividade.zy8_tpativ === "P" && "Palestras/Treinamento"} {atividade.zy8_tpativ === "I" && "Inst. Tintométrica"} {atividade.zy8_tpativ === "S" && "Atend. SAC"} {atividade.zy8_tpativ === "F" && "Trein. Fábrica"}</p>
                  <p>Local: {atividade.zy8_local}</p>
                  <p>Descrição: {atividade.zy8_descri}</p>
                  <DatePicker onChange={e => this.handleDateAtividade(e, atividade)} disabled={!this.state.dtinicio || !this.state.dtfim} disabledDate={this.disabledDateAtividades} format="DD/MM/YYYY" value={moment(atividade.zy8_dtvisi, 'YYYYMMDD')} />
                  <Divider />
                </Row>
              ))}
            </ContainerAtividades>
          </Fragment>
        </Spin>
      </Modal>
    );
  }
}

export default withStore(AtualizarPromotorModal);
