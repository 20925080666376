import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import moment from "moment";

@observer
class HistoricoMaquinasReportScreen extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Histórico de Máquinas",
      uri: "/r/historico-maquinas",
      controlled: false,
    });

    this.state = {
      isMop,
      maquinas: [],
      isLoading: false,
      isVisible: false,
      maquinaSelected: {}
    };
  }

  isVisibleModal = (record) => {
    this.setState({ isVisible: true });
    this.setState({ maquinaSelected: record })
  }

  closeModal = () => {
    this.setState({ isVisible: false });
  }

  render() {
    const columns = [
      {
        title: "Cod. cliente",
        dataIndex: 'cod_cliente',
        key: 'HistMaq.cod_cliente',
        sorter: true,
        filteredValue: this.ds.filterValue("cod_cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo código do cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_cliente", value: `%${filter.value}%`, op: "LIKE" }),
        width: "6%"
      },
      {
        title: "Cliente",
        dataIndex: 'cliente',
        key: 'HistMaq.cliente',
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome do cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}%`, op: "LIKE" }),
        width: "20%"
      },
      {
        title: "Data",
        dataIndex: 'data',
        key: 'HistMaq.data',
        render: (text, item) => (<WrappedValue value={`${moment(item.data, 'YYYYMMDD').format('DD-MM-YYYY')}`} />),
        width: "6%"
      },
      {
        title: "Data devol.",
        dataIndex: 'data_devolucao',
        key: 'HistMaq.data_devolucao',
        render: (text, item) => (<WrappedValue value={`${item.data_devolucao === 'NAO DEVOLVIDA' ? 'NAO DEVOLVIDA' : moment(item.data_devolucao, 'YYYYMMDD').format('DD-MM-YYYY')}`} />),
        width: "10%"
      },
      {
        title: "Nf. venda",
        dataIndex: 'nf_venda',
        key: 'HistMaq.nf_venda',
        width: "10%"
      },
      {
        title: "Nf. devol.",
        dataIndex: 'nf_devolucao',
        key: 'HistMaq.nf_devolucao',
        width: "10%"
      },
      {
        title: "Cod. prod.",
        dataIndex: 'cod_produto',
        key: 'HistMaq.cod_produto',
        filteredValue: this.ds.filterValue("cod_produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por código do produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "cod_produto", value: `%${filter.value}%`, op: "LIKE" }),
        width: "6%"
      },
      {
        title: "Produto",
        dataIndex: 'produto',
        key: 'HistMaq.produto',
        filteredValue: this.ds.filterValue("produto"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por produto" {...props} />,
        filterParser: filter => ({ ...filter, field: "produto", value: `%${filter.value}%`, op: "LIKE" }),
        width: "23%"
      },
      {
        title: "Nm. Série",
        dataIndex: 'serie',
        key: 'HistMaq.serie',
        filteredValue: this.ds.filterValue("serie"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por número de série" {...props} />,
        filterParser: filter => ({ ...filter, field: "serie", value: `%${filter.value}%`, op: "LIKE" }),
        width: "9%"
      },
      {
        title: "Nm. pedido",
        dataIndex: 'pedido',
        key: 'HistMaq.pedido',
        sorter: true,
        filteredValue: this.ds.filterValue("pedido"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo pedido" {...props} />,
        filterParser: filter => ({ ...filter, field: "pedido", value: `%${filter.value}%`, op: "LIKE" }),
        width: "9%"
      }
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name} />
        <FullTable
          className="full-table__compact"
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível buscar as informações"
        />
      </div>


    );
  }
}

export default withStore(HistoricoMaquinasReportScreen);
