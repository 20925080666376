import React, { useEffect } from 'react';
import video from '../../video/Seguranca.mp4';
import withStore from 'withStore';
import { fetchPostApi } from 'actions';

const VideoModal = (props) => {
  useEffect(() => {
    (async () => {
      if (props.store?.treinamentoFornecedorData?.viu_video === "N") {
        try {
          const { data: response } = await fetchPostApi(`/treinamento/empresa/viu_video/${props.store?.treinamentoFornecedorData?.token}`);
          if (response.success) {
            props.store.treinamentoFornecedorData.viu_video = 'S';

            props.store.update_fornecedor(props.store.treinamentoFornecedorData);
          }
        } catch (error) {
          console.log('Error:', error)
        }
      }
    })()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <video style={{
          width: '100%',
          maxHeight: '80vh'
        }}
          controls>
          <source src={video} type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
      </div>
    </>
  );
};

export default withStore(VideoModal);
