import React, { Component } from "react";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter } from "components/FullTable";
import { Button } from "antd";

@observer
class ListaPintores extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Lista pintores cadastrados",
      uri: `/r/relatorio-pintores-promotoria/${props.match.params.qrcode}`,
    });

    this.handleNavigatePromotoria = this.handleNavigatePromotoria.bind(this);
  }

  handleNavigatePromotoria() {
    this.props.history.goBack();
  }

  render() {
    const columns = [
      {
        title: <span className="th-small-label">Código</span>,
        dataIndex: "r_e_c_n_o_",
        key: "r_e_c_n_o_",
        sorter: true,
        width: "9%",
      },
      {
        title: <span className="th-small-label">Código QRCODE</span>,
        dataIndex: "zyg_cod",
        key: "zyg_cod",
        sorter: true,
        width: "9%",
      },
      {
        title: <span className="th-small-label">Filial</span>,
        dataIndex: "zyg_filial",
        key: "zyg_filial",
        width: "9%",
      },
      {
        title: <span className="th-small-label">Nome</span>,
        dataIndex: "zyg_nome",
        key: "zyg_nome",
        width: "9%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Email</span>,
        dataIndex: "zyg_email",
        key: "zyg_email",
        width: "9%",
        filteredValue: this.ds.filterValue("email"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: <span className="th-small-label">Contato</span>,
        dataIndex: "zyg_tel1",
        key: "zyg_tel1",
        width: "9%",
      },
      {
        title: <span className="th-small-label">Contato alt</span>,
        dataIndex: "zyg_tel2",
        key: "zyg_tel2",
        width: "9%",
      },
      {
        title: <span className="th-small-label">Trabalhando</span>,
        dataIndex: "zyg_empreg",
        key: "zyg_empreg",
        width: "9%",
        filteredValue: this.ds.filterValue("empregado"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pelo nome" {...props} />,
        filterParser: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} />
        <FullTable
          className="full-table__compact"
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter a lista dos pintores"
        />
        <Button type="primary" onClick={this.handleNavigatePromotoria}>Retornar para promotoria</Button>
      </div>
    );
  }
}

export default withStore(ListaPintores);
