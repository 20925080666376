import React, { Component } from "react";
import { Avatar, Modal, Spin, Tag } from "antd";
import { observer } from "mobx-react";

import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";
import PageTitle from "components/PageTitle";

@observer
class VisitantesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImage: false,
      previewVisible: false,
    }

    this.ds = new TableDataSource({
      uri: '/treinamento/funcionario/naempresa',
      state: { download_uri: '' }
    })
  }

  renderFoto(download_uri, funcionario, sexo, temFoto) {
    const colors = sexo === 'M' ? { background: 'lightblue', color: 'steelblue' } : { background: 'pink', color: 'crimson' }
    return (
      <Avatar
        shape="square"
        size={64}
        src={temFoto ? (download_uri + "/" + funcionario + ".jpg") : ''}
        icon="user"
        style={{ cursor: "pointer", ...colors }}
        onClick={() => !!temFoto && (this.setState({ previewImage: download_uri + "/" + funcionario + ".jpg" }, () => this.setState({ previewVisible: true })))}
      />
    )
  }

  render() {
    const renderContent = (value, row, type = "text") => {
      const obj = {
        children: <WrappedValue type={type} value={value} />,
        props: {},
      };
      if (!!row.separador) {
        obj.props.colSpan = 0;
      }
      return obj;
    }

    const columns = [
      {
        title: '',
        width: '8%',
        render: (_, row) => {
          if (!row.separador) {
            return this.renderFoto(this.ds.state?.download_uri, row.codigo_func, row.sexo, row.tem_foto === 'S');
          }
          return {
            children: <div className="text-center" style={{ fontWeight: "bold" }}>{row.local_entrada} - {row.contador}</div>,
            props: {
              colSpan: 5,
            },
          };
        },
      },
      {
        title: "Visitante",
        dataIndex: "visitante",
        key: "visitante",
        sorter: true,
        className: "text-left",
        width: "30%",
        filteredValue: this.ds.filterValue("visitante"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "visitante", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (visitante, item) => renderContent(<><div>{visitante}</div><div>{item.cargo && <Tag color="orange">{item.cargo}</Tag>}</div></>, item),
      },
      {
        title: "Empresa",
        dataIndex: "empresa",
        key: "empresa",
        sorter: true,
        className: "text-left",
        width: "30%",
        filteredValue: this.ds.filterValue("empresa"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Empresa" {...props} />,
        filterParser: filter => ({ ...filter, field: "empresa", value: `%${filter.value}%`, op: ":LIKE" }),
        render: renderContent,
      },
      {
        title: "Resp. Interno",
        dataIndex: "responsavel_interno",
        key: "responsavel_interno",
        sorter: true,
        className: "text-left",
        width: "24%",
        filteredValue: this.ds.filterValue("responsavel_interno"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Responsável Interno" {...props} />,
        filterParser: filter => ({ ...filter, field: "responsavel_interno", value: `%${filter.value}%`, op: ":LIKE" }),
        render: renderContent,
      },
      {
        title: "Entrou às:",
        dataIndex: "hora_entrada",
        key: "hora_entrada",
        width: "8%",
        export: false,
        render: renderContent,
      }
    ]

    return (
      <Spin spinning={this.ds.isLoading}>
        <PageTitle title="Visitantes do dia" meta={false} />
        <FullTable
          actionBar="none"
          columns={columns}
          dataSource={this.ds}
          rowClassName=""
        />
        <Modal visible={!!this.state.previewVisible}
          onCancel={() => this.setState({ previewVisible: false })}
          footer={null}>
          <img alt="" src={this.state.previewImage} />
        </Modal>
      </Spin>
    );
  }
}

export default VisitantesScreen;
