/* eslint-disable */
import React from "react";
import { Form, Input, Modal } from "antd";
import { observer } from "mobx-react";
import { PureComponent } from "react";
import withStore from "withStore";

const FormItem = Form.Item;

@observer
class IntroduceModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false
    }

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.handleClose();
  }

  handleSubmit = async () => {
    this.handleClose();
  }

  render() {
    return (
      <Modal
        maskClosable={false}
        title="Lançar informações adicionais"
        visible={this.props.isVisible}
        onOk={this.handleSubmit}
        okButtonProps={{
          disabled: Number(this.props.promotoria.Promotoria.zy7_etapas) > 5 && !(this.props.store.isPromotor || this.props.store.isSupervisor) || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledPromotorNotResponsible
        }}
        onCancel={this.handleClose}
      >
        {!(this.props.store.isGerente || this.props.store.isVendedor || this.props.store.isOperador) &&
          <FormItem label="Parecer Geral - Técnico">
            <Input.TextArea
              value={this.props.promotoria.Promotoria.zy7_melhor}
              disabled={Number(this.props.promotoria.Promotoria.zy7_etapas) > 5 && !(this.props.store.isPromotor || this.props.store.isSupervisor) || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
              onChange={e => this.props.promotoria.Promotoria.zy7_melhor = e.target.value}
              maxLength={254}
            />
          </FormItem>
        }
        <FormItem label="Parecer - Comercial">
          <Input.TextArea
            value={this.props.promotoria.Promotoria.zy7_ppromg}
            disabled={Number(this.props.promotoria.Promotoria.zy7_etapas) > 5 && !(this.props.store.isPromotor || this.props.store.isSupervisor) || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
            onChange={e => this.props.promotoria.Promotoria.zy7_ppromg = e.target.value}
            maxLength={254}
          />
        </FormItem>
        {!(this.props.store.isGerente || this.props.store.isVendedor || this.props.store.isOperador) &&
          <FormItem label="Pontos a melhorar promotoria">
            <Input.TextArea
              value={this.props.promotoria.Promotoria.zy7_ppromo}
              disabled={Number(this.props.promotoria.Promotoria.zy7_etapas) > 5 && !(this.props.store.isPromotor || this.props.store.isSupervisor) || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
              onChange={e => this.props.promotoria.Promotoria.zy7_ppromo = e.target.value}
              maxLength={254}
            />
          </FormItem>
        }
      </Modal>
    );
  }
}

export default withStore(IntroduceModal);
