/* eslint-disable */
import React, { Component, Fragment, createRef } from "react";
import { Alert, Button, Form, Icon, Input, Modal, Spin, Steps, message } from "antd";
import { observer } from "mobx-react";
import PageHeader from "components/PageHeader";
import { fetchApi, fetchPostApi } from "actions";
import withStore from "withStore";
import StepValidacao from "./components/StepValidacao";
import { PromotoriaStore } from "./store/PromotoriaStore";
import StepAtividades from "./components/StepAtividades";
import StepValidacaoSupervisor from "./components/StepValidacaoSupervisor";
import StepProdutos from "./components/StepProdutos";
import StepSolicitacaoPromotoria from "./components/StepSolicitacaoPromotoria";
import moment from "moment";
import StepLancamentos from "./components/StepLancamentos";
import HistoricoReprovacaoModal from "components/HistoricoReprovacaoModal";

const { Step } = Steps;

@observer
class PromotoriaScreenSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stepAtual: null,
      typeStep: 'process',
      hyperion: null,
      aquarela: null,
      isValidLancamento: false,
      isVisibleModalReprovacao: false,
      isVisibleModalReprovacaoHistorico: false,
      motivoReprovacao: "",
      isVisibleModalCancelamento: false,
      motivo_cancel: "",
      // isVisibleModalValidacaoGernete: false
    };
    this.promotoria = new PromotoriaStore(this.props.store);
    this.motivoReprovacaoInput = createRef();
    this.motivoCancelamentoInput = createRef();
  }

  componentDidMount = async () => {
    await this.buscarPromotoria();
  }

  buscarPromotoria = async () => {
    const { id } = this.props.match.params;

    try {
      this.promotoria.isLoading = true;
      const { data } = await fetchApi(`/promotoria/find-one/${id}`);
      this.promotoria.parse(data);
      this.promotoria.Promotoria.zy7_dtiniv = moment(data.data.Promotoria?.zy7_dtiniv).format('DD-MM-YYYY');
      this.promotoria.Promotoria.zy7_dtfimv = moment(data.data.Promotoria?.zy7_dtfimv).format('DD-MM-YYYY');
      this.promotoria.Atividades.zy8_dtvisi = moment(data.data_atividade?.data_atividade).format('DD-MM-YYYY');

      if (data.anexos.length) {
        this.promotoria.Anexos = data.anexos;
      }

      if (data.atividades.length) {
        this.promotoria.Atividades = data.atividades;
      }

      this.promotoria.stepAtual = 0;

      const maquinas = JSON.parse(this.promotoria.Promotoria?.zy7_maquin);

      this.setState({
        hyperion: maquinas.find(item => item.type === "hyperion"),
        aquarela: maquinas.find(item => item.type === "aquarela")
      });

      if (this.promotoria.Promotoria.zy7_perimo) {
        const { data } = await fetchApi(`/promotoria/buscar-perfil/${this.promotoria.Promotoria.zy7_perimo}`);
        this.promotoria.Promotoria.zy7_perimo = data.zaw_descri
      }

      if (this.promotoria.Promotoria.zy7_peraut) {
        const { data } = await fetchApi(`/promotoria/buscar-perfil/${this.promotoria.Promotoria.zy7_peraut}`);
        this.promotoria.Promotoria.zy7_peraut = data.zaw_descri;
      }
      await this.fecthQuadrante();

      // if (this.promotoria.Promotoria.zy7_status === "0") this.setState({ isVisibleModalValidacaoGernete: true });

    } catch (error) {
      message.error(error.response?.data?.message);
    } finally {
      this.promotoria.isLoading = false;
    }
  }

  fecthQuadrante = async () => {
    try {
      this.promotoria.isLoading = true;
      const { data } = await fetchApi(`/promotoria/buscar-quadrante-cliente/${this.promotoria.Promotoria.zy7_codcli}`);
      this.promotoria.quadranteCliente = data.a1_zqdrant;
    } catch (error) {
      Modal.error({
        title: 'Quadrante Cliente',
        content: 'Ocorreu um erro tentar buscar as informações do quadrante do cliente'
      });
    } finally {
      this.promotoria.isLoading = false;
    }
  }

  handleOpenModalTimeLine = () => {
    this.setState({ isVisibleModalTimeLine: true });
  }

  handleCloseModalTimeLine = () => {
    this.setState({ isVisibleModalTimeLine: false });
  }

  handleSave = async () => {
    const { id } = this.props.match.params;
    try {
      this.promotoria.isLoading = true;
      await fetchPostApi(`/promotoria/salvar-alteracoes/${id}`, this.promotoria);
      this.props.history.goBack();
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      this.promotoria.isLoading = false;
    }
  }

  handleReprove = async () => {
    if (!String(this.state.motivoReprovacao).trim()) {
      this.motivoReprovacaoInput.current.focus();
      return message.warning('Informe o motivo da reprovação');
    }

    Modal.confirm({
      title: "Você confirma esta operação?",
      content: "Clicando no botão abaixo você irá reprovar a solicitação.",
      cancelText: "Cancelar",
      okText: "Confirmar",
      onOk: () => this.reprovar(),
    });

    this.setState({ isVisibleModalReprovacao: false });
  }

  reprovar = async () => {
    try {
      this.setState({ isLoading: true });

      const formData = new FormData();

      formData.append('recno', this.promotoria.Promotoria.r_e_c_n_o_);
      formData.append('motivo', this.state.motivoReprovacao);
      formData.append('cod_sol', this.promotoria.Promotoria.zy7_codsol);

      await fetchPostApi(`/promotoria/reprovar`, formData);

      this.props.history.goBack();
      this.setState({ motivoReprovacao: "" });
    } catch (error) {
      message.error("Erro ao reprovar a solicitação");
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleModalReprovacao = () => {
    if (this.state.isVisibleModalReprovacao) {
      this.setState({ isVisibleModalReprovacao: false });
    } else {
      this.setState({ isVisibleModalReprovacao: true, motivoReprovacao: "" });
    }
  }

  ModalReprovacao = () => {
    return (
      <Modal
        visible={this.state.isVisibleModalReprovacao}
        title="Reprovação"
        okType="danger"
        okText="Reprovar"
        onOk={this.handleReprove}
        onCancel={this.handleModalReprovacao}
      >
        <Form.Item label="Informe o motivo da reprovação">
          <Input.TextArea ref={this.motivoReprovacaoInput} value={this.state.motivoReprovacao} onChange={e => this.setState({ motivoReprovacao: e.target.value })} />
        </Form.Item>
      </Modal>
    )
  }

  handleModalReprovacaoHistorico = () => {
    if (!this.state.isVisibleModalReprovacaoHistorico) {
      this.setState({ isVisibleModalReprovacaoHistorico: true });
    } else {
      this.setState({ isVisibleModalReprovacaoHistorico: false });
    }
  }

  showModalCancelamento = () => {
    if (!this.state.isVisibleModalCancelamento) {
      this.setState({ isVisibleModalCancelamento: true });
    } else {
      this.setState({ isVisibleModalCancelamento: false });
    }
  }

  cancelarSolicitacao = async () => {
    try {
      this.setState({ isLoading: true });

      if (!this.state.motivo_cancel.trim()) {
        this.motivoCancelamentoInput.current.focus();
        this.setState({ isLoading: false });
        return Modal.warning({
          title: 'Cancelamento Solicitação',
          content: 'Informe o motivo do cancelamento'
        });
      }

      const formData = new FormData();

      formData.append('motivo', this.state.motivo_cancel);
      formData.append('cod_sol', this.promotoria.Promotoria.zy7_codsol);

      await fetchPostApi(`/promotoria/cancelar/${this.promotoria.Promotoria.r_e_c_n_o_}`, formData);
      this.setState({ isVisibleModalCancelamento: false, motivo_cancel: "" });
      this.props.history.goBack();
    } catch (error) {
      message.error("Erro ao reprovar a solicitação");
    } finally {
      this.setState({ isLoading: false });
    }
  }

  ModalConfirmacaoCancelamento = () => (
    <Modal
      title="Cancelar Solicitação"
      visible={this.state.isVisibleModalCancelamento}
      onOk={this.cancelarSolicitacao}
      onCancel={this.showModalCancelamento}
      okText="Iniciar o cancelamento"
      okButtonProps={{ type: 'danger', style: { backgroundColor: '#ff4d4f', color: 'white' } }}
    >
      <p>Informe o motivo</p>
      <Input.TextArea ref={this.motivoCancelamentoInput} onChange={e => this.setState({ motivo_cancel: e.target.value })} value={this.state.motivo_cancel} />
    </Modal>
  )

  render() {
    const { typeStep, isVisibleModalReprovacaoHistorico } = this.state;

    const ComponentSteps = () => (
      <Steps size="small" current={this.promotoria.stepAtual} status={typeStep}>
        <Step title="Solicitação" />
        <Step title="Produtos" />
        <Step title="Atividades" />
        <Step title="Validação" />
        <Step title="Supervisor" />
        <Step title="Lançamentos/Validação" />
      </Steps>
    );

    const ComponentStepsVendedor = () => (
      <Steps size="small" current={this.promotoria.stepAtual} status={typeStep}>
        <Step title="Solicitação" />
        <Step title="Produtos" />
        <Step title="Atividades" />
      </Steps>
    )

    const ComponentStepsGerenteAteValidacao = () => (
      <Steps size="small" current={this.promotoria.stepAtual} status={typeStep}>
        <Step title="Solicitação" />
        <Step title="Produtos" />
        <Step title="Atividades" />
        <Step title="Validação" />
      </Steps>
    )

    const ComponentStepsPromotorLancamento = () => (
      <Steps size="small" current={this.promotoria.stepAtual} status={typeStep}>
        <Step title="Solicitação" />
        <Step title="Produtos" />
        <Step title="Atividades" />
        <Step title="Supervisor" />
        <Step title="Lançamentos/Validação" />
      </Steps>
    )

    return (
      <div>
        <PageHeader
          title="Cadastro de promotoria"
          headerLeft={
            <Fragment>
              <Button style={{ marginRight: 13 }} type="primary" disabled={this.promotoria.stepAtual === 0} onClick={() => { this.promotoria.previousStep() }}><Icon type="left-circle" /> Voltar</Button>
              <Button icon="rollback" ghost={true} type="primary" onClick={() => { this.props.history.goBack() }}>Voltar para lista</Button>
            </Fragment>
          }
          headerRight={
            <Fragment>
              {this.promotoria.HistorioReprova.length >= 1 && <Button onClick={this.handleModalReprovacaoHistorico} icon="eye" style={{ marginRight: 13 }} ghost type="danger">Histórico Repr|Cancel</Button>}
              <Button disabled={this.promotoria.isDisabledButtomExcluir || this.promotoria.isDisabledButtonNext || this.promotoria.isFinishPromotoria || this.promotoria.isLoading} style={{ marginRight: 13 }} onClick={this.showModalCancelamento} icon="delete" type="primary" ghost>Excluir</Button>
              <Button style={{ marginRight: 13 }} icon="close-circle-o" ghost={true} onClick={this.handleModalReprovacao} disabled={this.promotoria.isLoading || this.promotoria.isDisabled || this.promotoria.isDisabledButtonReproved || this.promotoria.isDisabledButtonsForVendendor || this.promotoria.isDisabledButtoNextStatus || this.promotoria.isFinishPromotoria || this.props.store.isPromotor} type="danger">Reprovar</Button>
              <Button icon="save" style={{ marginRight: 13 }} disabled={this.promotoria.isFinishPromotoria || this.promotoria.isLoading || this.promotoria.isDisabled || this.promotoria.isDisabledButtonsForVendendor || this.promotoria.isDisabledButtoNextStatus || this.promotoria.isDisabledPromotorNotResponsible} type="primary" onClick={() => this.handleSave()}>Salvar</Button>
              <Button type="primary" disabled={this.promotoria.isLoading || this.promotoria.isDisabledButtonNext} onClick={() => { this.promotoria.nextStep() }}>Próximo <Icon type="right-circle" /></Button>
            </Fragment>
          }
        />
        <Spin spinning={this.promotoria.isLoading} size="large">
          {(this.props.store.isVendedor || this.props.store.isOperador) && !this.promotoria.isShowStepsValid ?
            <ComponentStepsVendedor /> :
            this.promotoria.isShowStepsValidGerenteValidacao ?
              <ComponentStepsGerenteAteValidacao /> : this.promotoria.isShowStepsPromotorStepLancamentos ? <ComponentStepsPromotorLancamento />
                : <ComponentSteps />
          }
          <br />
          {this.promotoria.Promotoria.zy7_criado === "S" &&
            <Alert
              message="Solicitação gerada pelo gerente, disponível apenas para visualização."
              type="info"
              showIcon
              style={{ marginBottom: 10 }}
            />}
          {(this.promotoria.stepAtual === 0 || this.promotoria.stepAtual === 7) && !!this.state.hyperion && !!this.state.aquarela && <StepSolicitacaoPromotoria aquarela={this.state.aquarela} hyperion={this.state.hyperion} match={this.props.match} history={this.props.history} promotoria={this.promotoria} />}
          {this.promotoria.stepAtual === 1 && !!this.promotoria && <StepProdutos promotoria={this.promotoria} />}
          {this.promotoria.stepAtual === 2 && !!this.promotoria && <StepAtividades promotoria={this.promotoria} />}
          {(this.promotoria.stepAtual === 3 && !this.promotoria.isShowStepsPromotorStepLancamentos && !!this.promotoria) && <StepValidacao promotoria={this.promotoria} />}
          {(this.promotoria.stepAtual === 4 && !!this.promotoria && !this.promotoria.isShowStepsPromotorStepLancamentos || this.promotoria.stepAtual === 3 && !!this.promotoria && this.promotoria.isShowStepsPromotorStepLancamentos) && <StepValidacaoSupervisor promotoria={this.promotoria} />}
          {((this.promotoria.stepAtual === 5 || this.promotoria.stepAtual === 6) && !this.promotoria.isShowStepsPromotorStepLancamentos && !!this.promotoria || this.promotoria.stepAtual === 4 && !!this.promotoria && this.promotoria.isShowStepsPromotorStepLancamentos) && <StepLancamentos match={this.props.match} history={this.props.history} promotoria={this.promotoria} />}
        </Spin>
        {isVisibleModalReprovacaoHistorico && <HistoricoReprovacaoModal visible={isVisibleModalReprovacaoHistorico} handleClose={this.handleModalReprovacaoHistorico} reprovacoes={this.promotoria.HistorioReprova} title="Histórico Reprovação | Cancelamento Promotoria" />}
        {this.ModalReprovacao()}
        {this.state.isVisibleModalCancelamento && this.ModalConfirmacaoCancelamento()}
      </div>
    );
  }
}

export default withStore(PromotoriaScreenSelected);