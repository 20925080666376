import React, { Component, Fragment } from "react";
import { Button, Icon, Dropdown, Menu, Modal, Upload, Input, message, Form } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { SearchForm, TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchPostApi } from "actions";
import classNames from "classnames";
import Select, { Option } from "components/Select";

const FormItem = Form.Item;

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.etapa === 'R',
    "base-table--row__sucess": item.etapa === 'A',
    "base-table--row__invalid": item.etapa === 'P',
  });

const renderContent = (value, row, type = "text") => {
  const obj = {
    children: <WrappedValue type={type} value={value} />,
    props: {},
  };
  if (!!row.separador) {
    obj.props.colSpan = 0;
  }
  return obj;
}

@observer
class PendenteAprovacaoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Pendente Aprovação",
      uri: "/treinamento/pendencia/aprovacao",
    });
  }

  tryApproveOrNot = async (id, isAprovar) => {
    try {
      this.props.store.isLoading = true;

      const formData = {
        id,
        isAprovar,
        obs: this.state?.obs || ""
      }

      const { data: response } = await fetchPostApi("/treinamento/pendencia/aproveOrReprove", formData);

      if (!!response.success) {
        message.success("Sucesso!");
        this.ds.fetch();
      }
    } catch (error) {
      message.error('error');
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleAprovarReprovar = (treinamentoId, isAprovar) => {
    Modal.confirm({
      title: isAprovar ? "Aprovar" : "Reprovar",
      content:
        <>
          Motivo da {isAprovar ? "aprovação" : "reprovação"}
          <Input.TextArea onChange={({ target: { value } }) => this.setState({ obs: value })} />
        </>,
      okType: isAprovar ? 'default' : 'danger',
      okText: isAprovar ? 'Aprovar' : 'Reprovar',
      cancelText: 'Cancelar',
      onOk: () => {
        this.tryApproveOrNot(treinamentoId, isAprovar)
      }
    })
  }

  renderUploadItem = (anexos, row) => {
    const fileList = [];

    anexos && anexos.forEach((item, index) => {
      fileList.push({
        uid: index * -1,
        name: item.filename,
        status: 'done',
        url: item.download_uri,
      })
    });


    const obj = {
      children: <Upload fileList={fileList} />,
      props: {},
    };
    if (!!row.separador) {
      obj.props.colSpan = 0;
    }
    return obj;
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          etapa: "select",
        }}
        filterParser={{
          etapa: filter => ({ ...filter, value: filter.value, type: "radio" }),
        }}>
        {({ form }) => (
          <Fragment>
            <FormItem>
              {form.getFieldDecorator("etapa")(
                <Select style={{ width: '150px' }} placeholder="Visualização por">
                  <Option value="R">Reprovado</Option>
                  <Option value="P">Pendente</Option>
                  <Option value="A">Aprovado</Option>
                </Select>
              )}
            </FormItem>
            <FormItem>
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  };

  render() {
    const columns = [
      {
        title: "Empresa",
        key: "empresa",
        width: '15%',
        sorter: true,
        filteredValue: this.ds.filterValue("e.razao_social"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome da empresa" {...props} />,
        filterParser: filter => ({ ...filter, field: "e.razao_social", value: `${filter.value}%`, op: ":LIKE" }),
        render: (value, row) => {
          if (!row.separador) {
            return <WrappedValue value={<>{row.e?.razao_social}</>} />;
          }
          return {
            children: <div className="text-center" style={{ fontWeight: "bold" }}>ANEXOS - {row.is_func}</div>,
            props: {
              colSpan: 7,
            },
          };
        },
      },
      {
        title: "Treinamento",
        dataIndex: "nome",
        key: "treinamento",
        sorter: true,
        width: "15%",
        filteredValue: this.ds.filterValue("t.nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "t.nome", value: `%${filter.value}%`, op: ":LIKE" }),
        render: renderContent,
      },
      {
        title: "Func.",
        dataIndex: "nome_funcionario",
        key: "funcionario",
        sorter: true,
        width: "20%",
        filteredValue: this.ds.filterValue("f.nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "f.nome", value: `%${filter.value}%`, op: ":LIKE" }),
        render: renderContent,
      },
      {
        title: "Obs. Anexo",
        key: "obs_anexo",
        dataIndex: "obs_anexo",
        render: renderContent,
      },
      {
        title: "Vigência",
        key: "vigencia",
        dataIndex: "fn.vigencia",
        render: (v, r) => renderContent(v, r, "date"),
      },
      {
        title: "Anexos",
        key: "anexos",
        dataIndex: "fn.anexos",
        className: "text-left",
        sorter: false,
        render: (item, row) => this.renderUploadItem(item, row)
      },
      {
        title: "Situação",
        dataIndex: "etapa",
        key: "etapa",
        sorter: true,
        width: "10%",
        render: (_, item) => renderContent(<div>{item.etapa === 'A' ? 'Atualizado' : (item.etapa === 'R' ? 'Reprovado' : 'Pendente')}</div>, item),
      },
      {
        title: "",
        key: "actionbar",
        width: "5%",
        export: false,
        render: (_, item) => {
          const menu =
            <Menu>
              <Menu.Item key="menu-aprovar"
                disabled={item.etapa !== 'P'}
                onClick={() => this.handleAprovarReprovar(item.fn.codigo, true)}>
                <Icon type="check" />
                Aprovar
              </Menu.Item>
              <Menu.Item key="menu-reprovar"
                disabled={item.etapa !== 'P'}
                onClick={() => this.handleAprovarReprovar(item.fn.codigo, false)}>
                <Icon type="delete" />
                Reprovar
              </Menu.Item>
            </Menu>
          return renderContent(
            <Dropdown
              overlay={menu}>
              <Button size="small"
                type="primary">
                <Icon type="menu-unfold" />
              </Button>
            </Dropdown>
            , item)
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          rowKey={(item, i) => i}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter os treinamentos no momento"
          rowClassName={decorateRows}
          beforeComponent={this.renderSearchForm}
        />
      </div>
    );
  }
}

export default withStore(PendenteAprovacaoScreen);
