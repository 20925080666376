import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";

import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";
import styled from "styled-components";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

const TagNexxusPro = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  float: right;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 2px;
  margin-top: 12px;
  cursor: default;
`;

const VENDEDOR_RELATORIOS_LINK = [
  { key: "nf", href: "/notas-fiscais", label: "Notas Fiscais - DANFE", title: "NF-e / DANFE / XML" },
  { key: "volume_perfil", href: "/r/volume_perfil", label: "Volume perfil ", title: "Volume perfil ", plataforma: "NEXXUS PRO" },
  { key: "ranking", href: "/r/ranking", label: "Ranking Volume", title: "Ranking Volume", plataforma: "NEXXUS PRO" },
  { key: "resumo_cashback", href: "/r/resumo_cashback", label: "Cashback ", title: "Cashback ", plataforma: "NEXXUS PRO" },
  { key: "retencao-cliente", href: "/r/retencao-cliente", label: "Retenção Cliente", title: "Retenção Cliente", plataforma: "NEXXUS PRO" },
  { key: "relatorio-eventos", href: "/r/eventos", label: "Log de Eventos", plataforma: "NEXXUS PRO" },
  { key: "titulos_abertos", href: "/r/titulos_abertos", label: "Títulos em Aberto" },
  { key: "boletos", href: "/boletos", label: "Boletos em Aberto" },
];

const RELATORIOS_NOVOS = [
  { key: "relatorio-tickets", href: "/r/relatorio-tickets", label: "Tickets Máquinas" },
  { key: "maquinas", href: "/r/maquinas", label: "Máquinas", title: "Máquinas" },
  { key: "analise-volume", href: "/r/analise-volume", label: "Análise de Volume de Maquinas", title: "Análise de Volume de Maquinas" },
  { key: "ociosidade", href: "/r/ociosidade", label: "Ociosidade", title: "Ociosidade" },
  { key: "cursos-treinamentos-promotoria", href: "/r/cursos-treinamentos-promotoria", label: "Cursos e Treinamentos Promotoria", title: "Cursos e Treinamentos Promotoria" },
  { key: "produtos-trabalhados-promotoria", href: "/r/produtos-trabalhados-promotoria", label: "Produtos Trabalhado Promotoria", title: "Produtos Trabalhado Promotoria" },
]

class MenuDiretor extends PureComponent {
  render() {
    // eslint-disable-next-line
    const { tipoDiretor, isB2B, collapsed = false, selected = [], resetTabs, Diretor_unidade_negocio, isNexxusPro } = this.props;
    const submenuKeys = [];

    const isDiretorOrPresidente = tipoDiretor === "DIRETOR" || tipoDiretor === "PRESIDENTE" || tipoDiretor === "GERENTE";
    const isDirOrPre = tipoDiretor === "DIRETOR" || tipoDiretor === "PRESIDENTE";
    const isDiretor = tipoDiretor === "DIRETOR";
    const isGerente = tipoDiretor === "GERENTE";
    const isPresidente = tipoDiretor === "PRESIDENTE";
    const isPricing = tipoDiretor === "PRICING";
    const isDirPreRevB2b = (Diretor_unidade_negocio === "B2B" && isDiretorOrPresidente) || (Diretor_unidade_negocio !== "B2B");

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__vendedor", "sidebar-nav__diretor")}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subAprov"
            title={
              <span>
                <Icon type="check" />
                <span>Aprovações/Liberações</span>
              </span>
            }>
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-dir-simulacoes">
                <Link to="/simulacoes">Análise de Simulações</Link>
              </MenuItem>
            )}
            {((Diretor_unidade_negocio !== "B2B") || (Diretor_unidade_negocio === "B2B" && isDiretor)) && !isPricing && (
              <MenuItem key="nav-dir-margem">
                {<Link to="/margens">Liberação Margem</Link>}
              </MenuItem>
            )}
            {(!isPricing &&
              <MenuItem key="nav-dir-solprazo">
                <Link to="/solicitacao-prazo" title="Exceções de Prazo">
                  Exceções de Prazo
                </Link>
              </MenuItem>
            )}
            {!isPresidente && Diretor_unidade_negocio !== "B2B" && !isPricing && (
              <MenuItem key="nav-dir-soldesc">
                <Link to="/solicitacao-desconto">Desconto Marketing</Link>
              </MenuItem>
            )}
            {isDirPreRevB2b && !isPricing && (
              <MenuItem key="nav-dir-bonificacoes">
                <Link to="/bonificacoes" title="Bonificações / Amostras / Brindes / Vendor">
                  Bonif./Amostras/Brindes/Vendor
                </Link>
              </MenuItem>
            )}
            <MenuItem key="nav-dir-memorando">
              <Link to="/memorando">Memorando</Link>
            </MenuItem>
            {isDirPreRevB2b && (
              <MenuItem key="nav-dir-aprovacao-maquinas-tintometricas">
                <Link to="/maquinas-tintometricas" title="Máquinas tintométricas">
                  Solicitações Máquinas Tintométricas
                </Link>
              </MenuItem>
            )}
            {isDirPreRevB2b && (
              <MenuItem key="nav-dir-devolucoes">
                <Link to="/maquinas-tintometricas/devolucoes" onClick={event => resetTabs()}>
                  Devoluções Máquinas Tintométricas
                </Link>
              </MenuItem>
            )}
            {Diretor_unidade_negocio === "B2B" && (
              <MenuItem key="nav-dir-bonificacoes">
                <Link to="/sda" title="Analise de SDA's">
                  Análise de SDA's
                </Link>
              </MenuItem>
            )}
          </SubMenu>

          <SubMenu
            key="subVendasDistr"
            title={
              <span>
                <Icon type="bars" />
                <span>Vendas e Distribuição</span>
              </span>
            }>
            <MenuItem key="nav-servicos-pedidos">
              <Link to="/r/pedidos">Pedidos por Período</Link>
            </MenuItem>
            {/* {isGerente && ( */}
            <MenuItem key="nav-vendas-distr-cadastro">
              <Link to="/vendedor/clientes">Manutenção Cliente</Link>
            </MenuItem>
            {/* )} */}
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-vendas-distr-arq">
                <Link to="/r/ranking">Ranking Volume</Link>
              </MenuItem>
            )}
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-vendas-distr-arq">
                <Link to="/arquivos-comercial">Arquivos Comerciais</Link>
              </MenuItem>
            )}
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-cadastro-promotoria">
                <Link to="/promotoria/index">
                  <span>Promotoria</span>
                </Link>
              </MenuItem>
            )}
            {Diretor_unidade_negocio !== "B2B" && (
              <MenuItem key="nav-vendas-distr-agenda">
                <Link to="/promotoria/agenda">Agenda Promotoria</Link>
              </MenuItem>
            )}
            <MenuItem key="nav-clubea">
              <Link to="/clubea">
                <span>Clube A</span>
              </Link>
            </MenuItem>
            <MenuItem key="nav-vend-ano">
              <Link to="/vendedorano">
                <span>Vendedor do Ano</span>
              </Link>
            </MenuItem>
          </SubMenu>
          {!isDirOrPre && Diretor_unidade_negocio !== "B2B" && (
            <SubMenu
              key="subTabelaPreco"
              title={
                <span>
                  <Icon type="tags" />
                  <span>Tabela de Preço Unificada</span>
                </span>
              }>
              {!isNexxusPro && (
                <MenuItem key="nav-tabela-preco">
                  <Link to="/tabela-preco">Tabela de Preço</Link>
                </MenuItem>
              )}
              {isNexxusPro && (
                <MenuItem key="nav-tabela-preco" className="ant-menu-item__featured">
                  <Link to="/tabela-preco-sku">
                    <Icon type="unlock" />
                    <span>Tabela de Preço </span>
                    <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                  </Link>
                </MenuItem>
              )}
            </SubMenu>
          )}
          {!isPresidente && Diretor_unidade_negocio !== "B2B" && (
            <SubMenu
              key="subRelatorios"
              title={
                <span>
                  <Icon type="appstore-o" />
                  <span>Relatórios</span>
                </span>
              }>
              {VENDEDOR_RELATORIOS_LINK.filter(item => !item.disabled && ((item.plataforma === "NEXXUS PRO" && isNexxusPro) || !item.plataforma) && ((isDiretor && item.plataforma === "NEXXUS PRO") || (!isDiretor))).map(item => (
                <MenuItem key={`nav-relatorios-${item.key}`} className={item.plataforma === "NEXXUS PRO" ? "ant-menu-item__featured" : ""}>
                  <Link to={item.href} title={item.title || item.label}>
                    {item.plataforma === "NEXXUS PRO" && (<Icon type="unlock" />)}
                    {item.label}
                    {item.plataforma === "NEXXUS PRO" && (<TagNexxusPro>NEXXUSPRO</TagNexxusPro>)}
                  </Link>
                </MenuItem>
              ))}
              {(isDiretor || isGerente) && RELATORIOS_NOVOS.map(item => (
                <MenuItem key={`nav-relatorios-${item.key}`}>
                  <Link to={item.href}>{item.label}</Link>
                </MenuItem>
              ))}
              {isGerente &&
                <MenuItem key={`nav-relatorios-saldo`}>
                  <Link to="/r/saldo">Saldo em Estoque</Link>
                </MenuItem>
              }
              {isGerente &&
                <MenuItem key={`nav-relatorios-verba`}>
                  <Link to="/r/verba">Verbas Mensais</Link>
                </MenuItem>
              }
            </SubMenu>
          )}
          <SubMenu
            key="subLinks"
            title={
              <span>
                <Icon type="link" />
                <span>Links Úteis</span>
              </span>
            }>
            <MenuItem key="nav-links-produtos">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvTHZTaWE5TGFYUEU?resourcekey=0-oL1MxFa_2UqT3YEk7DO9jw">Catálogo de Produtos</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvaWdvRDloc1FMUlk?resourcekey=0-FkGzi6rE9fFRO_iPDcR03A">Catálogo de Cor</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-arsenal">
              <ExternalLink to="https://arsenal.anjo.com.br">Arsenal ANJO</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://www.anjo.com.br/fale-conosco/telefones">Telefones Úteis</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://anjotintas.zendesk.com/hc/pt-br/requests/new">Abertura SAC - ZENDESK</ExternalLink>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuDiretor);
