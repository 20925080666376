import React, { Component } from 'react';
import { Row, Col, Select, Form, DatePicker, Input, Button, Spin, List, Icon, Modal } from 'antd';
import withStore from 'withStore';
import moment from 'moment';
import styled from 'styled-components';


const LabelGridText = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #363636;
`;

class StepAtividades extends Component {
  constructor(props) {
    super(props);
    this.handleAtividade = this.handleAtividade.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      isLoading: false,
      options: [
        {
          value: 'V',
          label: 'VISITAS',
          disable: false
        },
        {
          value: 'P',
          label: 'PALESTRAS / TREINAMENTO',
          disable: false
        },
        // {
        //     value: 'T',
        //     label: 'TREINAMENTO',
        //     disable: false
        // },
        {
          value: 'I',
          label: 'INSTALAÇÃO (SUPERVISOR)',
          disable: !this.props.store.isSupervisor
        },
        {
          value: 'S',
          label: 'SAC (SUPERVISOR)',
          disable: !this.props.store.isSupervisor
        },
        {
          value: 'F',
          label: 'TREINAMENTO FÁBRICA (SUPERVISOR)',
          disable: !this.props.store.isSupervisor
        }
      ],
      usaMaquina: [
        {
          value: 'S',
          label: 'Sim'
        },
        {
          value: 'N',
          label: 'Não'
        }
      ]
    }
  }

  disabledDate = (current) => {
    const { promotoria } = this.props;
    const startDate = moment(promotoria.Promotoria.zy7_dtiniv, 'DD-MM-YYYY');
    let endDate = moment(promotoria.Promotoria.zy7_dtfimv, 'DD-MM-YYYY');

    // Adicione um dia à data endDate
    endDate = endDate.add(1, 'day');

    return current < startDate || current > endDate;
  };

  handleKeyPressNumbers = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  formularioOficinasEObras = () => (
    <Row gutter={8} style={{ marginTop: 20 }}>
      <Col span={5}>
        <Form.Item label="Responsável pela oficina /obra" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_RESPON', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'V', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="50" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Local" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_LOCAL', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'V', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Atividade" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DESCRI', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'V', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Data da visita" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DTVISI', {
            rules: [
              {
                required: this.props.form.getFieldValue('ZY8_TPATIV') === 'V',
                message: 'Informe o campo obrigatório',
              },
            ],
          })(
            <DatePicker
              disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
              format="DD-MM-YYYY"
              disabledDate={this.disabledDate} // Adicione a função de data desabilitada aqui
            />
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          size="small"
          type="primary"
          icon="plus-circle"
          htmlType="submit"
          style={{
            width: "calc(5% - 4px)",
            minWidth: 32,
            height: 32,
            padding: "3px 8px",
            marginTop: 18
          }}
          disabled={this.props.store.isPromotor || !this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
        />
      </Col>
    </Row>
  );

  formularioPalestrasPintores = () => (
    <Row gutter={8} style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form.Item label="Responsável pela loja" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_RESPON', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'P', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="50" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Qtd participantes" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_QTDPIN', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'P', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="5" onKeyPress={this.handleKeyPressNumbers} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Local" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_LOCAL', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'P', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Atividade" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DESCRI', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'P', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Data da palestra/curso" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DTVISI', {

            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'P', message: 'Informe o campo obrigatório' }],
          })(<DatePicker disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          size="small"
          type="primary"
          icon="plus-circle"
          htmlType="submit"
          style={{
            width: "calc(5% - 4px)",
            minWidth: 32,
            height: 32,
            padding: "3px 8px",
            marginTop: 18
          }}
          disabled={this.props.store.isPromotor || !this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
        />
      </Col>
    </Row>
  );

  // formularioTreinamentoVendedoresLoja = () => (
  //     <Row gutter={8} style={{ marginTop: 20 }}>
  //         <Col span={4}>
  //             <Form.Item label="Responsável pela loja" hasFeedback>
  //                 {this.props.form.getFieldDecorator('ZY8_RESPON', {
  //                     rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'T', message: 'Informe o campo obrigatório' }],
  //                 })(<Input disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="50" />)}
  //             </Form.Item>
  //         </Col>
  //         <Col span={4}>
  //             <Form.Item label="Quantidade de pessoas" hasFeedback>
  //                 {this.props.form.getFieldDecorator('ZY8_QTDPES', {
  //                     rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'T', message: 'Informe o campo obrigatório' }],
  //                 })(<Input disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="5" onKeyPress={this.handleKeyPressNumbers} />)}
  //             </Form.Item>
  //         </Col>
  //         <Col span={4}>
  //             <Form.Item label="Local" hasFeedback>
  //                 {this.props.form.getFieldDecorator('ZY8_LOCAL', {
  //                     rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'T', message: 'Informe o campo obrigatório' }],
  //                 })(<Input disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
  //             </Form.Item>
  //         </Col>
  //         <Col span={4}>
  //             <Form.Item label="Atividade" hasFeedback>
  //                 {this.props.form.getFieldDecorator('ZY8_DESCRI', {
  //                     rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'T', message: 'Informe o campo obrigatório' }],
  //                 })(<Input disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
  //             </Form.Item>
  //         </Col>
  //         <Col span={4}>
  //             <Form.Item label="Data da palestra/curso" hasFeedback>
  //                 {this.props.form.getFieldDecorator('ZY8_DTVISI', {

  //                     rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'T', message: 'Informe o campo obrigatório' }],
  //                 })(<DatePicker disabled={this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
  //             </Form.Item>
  //         </Col>
  //         <Col span={4}>
  //             <Button
  //                 size="small"
  //                 type="primary"
  //                 icon="plus-circle"
  //                 htmlType="submit"
  //                 style={{
  //                     width: "calc(5% - 4px)",
  //                     minWidth: 32,
  //                     height: 32,
  //                     padding: "3px 8px",
  //                     marginTop: 18
  //                 }}
  //                 disabled={!this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
  //             />
  //         </Col>
  //     </Row>
  // )

  formularioInstalacaoTintometrica = () => (
    <Row gutter={8} style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form.Item label="Responsável pela loja" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_RESPON', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'I', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="50" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Data" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DTVISI', {

            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'I', message: 'Informe o campo obrigatório' }],
          })(<DatePicker disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Local" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_LOCAL', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'I', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Atividade" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DESCRI', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'I', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          size="small"
          type="primary"
          icon="plus-circle"
          htmlType="submit"
          style={{
            width: "calc(5% - 4px)",
            minWidth: 32,
            height: 32,
            padding: "3px 8px",
            marginTop: 18
          }}
          disabled={this.props.store.isPromotor || !this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
        />
      </Col>
    </Row>
  )

  formularioAtendimentoDeSac = () => (
    <Row gutter={8} style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form.Item label="Número do protocolo" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_PROTOC', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'S', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="20" onKeyPress={this.handleKeyPressNumbers} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Responsável pelo SAC" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_SACRES', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'S', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="50" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Local" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_LOCAL', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'S', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Atividade" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DESCRI', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'S', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Data" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DTVISI', {

            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'S', message: 'Informe o campo obrigatório' }],
          })(<DatePicker disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          size="small"
          type="primary"
          icon="plus-circle"
          htmlType="submit"
          style={{
            width: "calc(5% - 4px)",
            minWidth: 32,
            height: 32,
            padding: "3px 8px",
            marginTop: 18
          }}
          disabled={this.props.store.isPromotor || !this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}
        />
      </Col>
    </Row>
  )

  formularioTreinamentoFabrica = () => (
    <Row gutter={8} style={{ marginTop: 20 }}>
      <Col span={4}>
        <Form.Item label="Treinamento na Fábrica?" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_TRFABR', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TRFABR') === 'F', message: 'Informe o campo obrigatório' }],
          })(<Select disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible}>
            {this.state.usaMaquina?.map(item => (
              <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
            ))}
          </Select>)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Local" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_LOCAL', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'F', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Atividade" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DESCRI', {
            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'F', message: 'Informe o campo obrigatório' }],
          })(<Input disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} maxLength="200" />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Data" hasFeedback>
          {this.props.form.getFieldDecorator('ZY8_DTVISI', {

            rules: [{ required: this.props.form.getFieldValue('ZY8_TPATIV') === 'F', message: 'Informe o campo obrigatório' }],
          })(<DatePicker disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          size="small"
          type="primary"
          icon="plus-circle"
          htmlType="submit"
          style={{
            width: "calc(5% - 4px)",
            minWidth: 32,
            height: 32,
            padding: "3px 8px",
            marginTop: 18
          }}
          disabled={this.props.store.isPromotor || !this.props.form.getFieldValue('ZY8_TPATIV') || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible || this.props.promotoria.isDisabledPromotorNotResponsible}
        />
      </Col>
    </Row>
  )

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await this.enviarDados(values);
      }
    });
  };

  enviarDados = async (value) => {
    try {
      this.props.promotoria.salvarStepAtividades(value);
      this.props.form.setFieldsValue({
        ZY8_TRFABR: null,
        ZY8_PROTOC: null,
        ZY8_SACRES: null,
        ZY8_DTVISI: null,
        ZY8_LOCAL: null,
        ZY8_RESPON: null,
        ZY8_DESCRI: null,
        ZY8_QTDPIN: null,
        ZY8_QTDPES: null
      });
    } catch (error) {
      return Modal.error({
        title: 'Atividades',
        content: error.message
      });
    }
  }

  handleAtividade() {
    // const { resetFields } = this.props.form;

    // this.props.promotoria.clearStepAtividades()
    // resetFields();
  }

  handleRemove = (value) => {
    this.props.promotoria.handleRemoveAtividade(value);
    this.forceUpdate();
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { options, isLoading } = this.state;
    const { Atividades } = this.props.promotoria;
    return (
      <div>
        <Form
          autoComplete="off"
          layout="vertical"
          onSubmit={this.handleSubmit}
        >
          <Spin spinning={isLoading}>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item label="Informe a atividade que será efetuada" hasFeedback>
                  {getFieldDecorator('ZY8_TPATIV', {
                    initialValue: this.props.promotoria?.Atividades?.length >= 1 ? this.props.promotoria?.Atividades[0]?.zy8_tpativ : null,
                    rules: [{ required: true, message: 'Informe o campo obrigatório' }],
                  })(<Select disabled={this.props.store.isPromotor || this.props.promotoria.isFinishPromotoria || this.props.promotoria.createdByGerente || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible} onChange={this.handleAtividade}>
                    {options?.map(item => (
                      <Select.Option disabled={item.disable} key={item.value} value={item.value}>{item.label}</Select.Option>
                    ))}
                  </Select>)}
                </Form.Item>
              </Col>

            </Row>
            {getFieldValue('ZY8_TPATIV') === 'V' && this.formularioOficinasEObras()}
            {getFieldValue('ZY8_TPATIV') === 'P' && this.formularioPalestrasPintores()}
            {/* {getFieldValue('ZY8_TPATIV') === 'T' && this.formularioTreinamentoVendedoresLoja()} */}
            {getFieldValue('ZY8_TPATIV') === 'I' && this.formularioInstalacaoTintometrica()}
            {getFieldValue('ZY8_TPATIV') === 'S' && this.formularioAtendimentoDeSac()}
            {getFieldValue('ZY8_TPATIV') === 'F' && this.formularioTreinamentoFabrica()}

            {Atividades.length >= 1 && <List
              size="small"
              bordered
              column
              itemLayout="horizontal"
              dataSource={Atividades}
              renderItem={item => <Row gutter={6} style={{ marginTop: 10, marginBottom: 10, marginLeft: 5 }}>
                {!!item && (
                  <Col span={1}>
                    <Button
                      type="danger"
                      ghost
                      disabled={this.props.store.isPromotor || this.props.promotoria.isDisabledButtonAddPosLancamento || this.props.promotoria.createdByGerente || this.props.promotoria.isFinishPromotoria || this.props.promotoria.isDisabledButtonsForVendendor || this.props.promotoria.isDisabledButtoNextStatus || this.props.promotoria.isDisabledPromotorNotResponsible || this.props.promotoria.isDisabledPromotorNotResponsible}
                      onClick={() => this.handleRemove(item)}
                      style={{
                        width: "calc(5% - 4px)",
                        minWidth: 20,
                        height: 20,
                        padding: "3px 8px",
                        display: 'flex', // Tornar o container um flex container
                        justifyContent: 'center', // Centralizar horizontalmente
                        alignItems: 'center' // Centralizar verticalmente
                      }}
                    >
                      <Icon type="close" />
                    </Button>
                  </Col>
                )}
                {item?.zy8_local && <Col span={3}>
                  <LabelGridText>Local: {item.zy8_local}</LabelGridText>
                </Col>}
                {item?.zy8_sacres && <Col span={4}>
                  <LabelGridText>Resp SAC: {item.zy8_sacres}</LabelGridText>
                </Col>}
                {item?.zy8_respon && <Col span={4}>
                  <LabelGridText>Responsável: {item.zy8_respon}</LabelGridText>
                </Col>}
                {item?.zy8_dtvisi && <Col span={3}>
                  <LabelGridText>Data: {moment(item?.zy8_dtvisi).format('DD-MM-YYYY')}</LabelGridText>
                </Col>}
                {item?.zy8_descri && <Col span={4}>
                  <LabelGridText>Atividade: {item.zy8_descri}</LabelGridText>
                </Col>}
                {item?.zy8_protoc && <Col span={4}>
                  <LabelGridText>Protocolo: {item.zy8_protoc}</LabelGridText>
                </Col>}
                {/* {(item?.zy8_qtdpes && item.zy8_tpativ === "T") && <Col span={2}>
                                    <LabelGridText>Qtd pess: {item.zy8_qtdpes}</LabelGridText>
                                </Col>} */}
                {(item?.zy8_qtdpin && item.zy8_tpativ === "P") && <Col span={2}>
                  <LabelGridText>Qtd partici: {item.zy8_qtdpin}</LabelGridText>
                </Col>}
                {item?.zy8_tpativ && <Col span={5}>
                  <LabelGridText>Tp ativi.: {item.zy8_tpativ === "V" && "Visitas"} {item.zy8_tpativ === "P" && "Palestras"} {item.zy8_tpativ === "T" && "Treinamento"} {item.zy8_tpativ === "I" && "Inst. Tintométrica"} {item.zy8_tpativ === "S" && "Atend. SAC"} {item.zy8_tpativ === "F" && "Trein. Fábrica"}</LabelGridText>
                </Col>}
              </Row>}
            />}
          </Spin>
        </Form>
      </div>
    );
  }
}

export default withStore(Form.create({ name: 'atividades' })(StepAtividades));
