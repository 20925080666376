import React, { Component, Fragment } from "react";
import { Modal, Spin, Icon, Steps, Button } from "antd";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import withStore from "withStore";
import { debug } from "@env";
import moment from "moment";
import { getData } from "@util";
import { fetchBase, fetchEdit, fetchProduto, fetchKits, savePedido } from "actions/pedido";
import PedidoStore, { Item, PedidoError } from "stores/PedidoStore";

import { BackButton } from "components/PageHeader";
import ChooserCliente from "components/pedidos/ChooserCliente";
import ChooserAlvara from "components/pedidos/ChooserAlvara";
import ChooserTransp from "components/pedidos/ChooserTransp";
import ChooserProduto from "components/pedidos/ChooserProduto";
import ChooserGrade from "components/pedidos/ChooserGrade";
import ChooserResiduo from "components/pedidos/ChooserResiduo";
import ChooserGradeOcamentoTintometrico from "components/pedidos/ChooserGradeOcamentoTintometrico";

import ChooserCondicao from "components/pedidos/ChooserCondicao";
import ChooserKit from "components/pedidos/ChooserKit";
import FormProdutoHeader from "components/pedidos/FormProdutoHeader";
import UpdateProdutoModal from "components/pedidos/UpdateProdutoModal";
import UpdateBonifModal from "components/pedidos/UpdateBonifModal";

import ProporcaoInfo from "components/pedidos/ProporcaoInfo";
import KitEditor from "components/pedidos/kit";

import PolicyBar from "components/pedidos/PolicyBar";
import TotalBar from "components/pedidos/TotalBar";

import StepCliente from "./StepCliente";
import StepProduto from "./StepProduto";
import StepPagto from "./StepPagto";

import "./PedidoEditorScreen.less";
import CadastroEditModalScreen from "../clientes/CadastroEditModalScreen";
import { isDev } from "env";
import StepEntrega from "./StepEntrega";
import { fetchApi } from "actions";

const Step = Steps.Step;
const NOW = Date.now();

function onPedidoError(errorMessage, onOk) {
  Modal.error({
    title: "Atenção",
    content:
      <span dangerouslySetInnerHTML={{ __html: errorMessage.split("\n").join("<br />") }} /> ||
      "Não foi possível continuar seu pedido",
    onOk,
  });
  return false;
}

function resetItems(pedido) {
  Item.today = pedido.today;
  Item.status_pedido = pedido.isSimulacao && !pedido.descsimu_acima_politica ? "2" : toJS(pedido.data.status_pedido);
  Item.unidade_negocio = toJS(pedido.data.unidade_negocio);

  pedido.pendingItems = [new Item()];
  pedido.checkFrete();
}

function enableForward(pedido, current = 0) {
  if (!!pedido.isLoading) {
    return false;
  }

  const programadoExpirado = moment(pedido.data.programado).diff(NOW, "days") < 0;

  let valid =
    !!pedido.data.programado && !programadoExpirado && pedido.hasCliente && pedido.hasVendedor && (!!pedido.isB2B || !!pedido.hasPolicy) && !!pedido.data.op_logistica;

  if (valid) {
    const hasObsCarregto = !!pedido.data.obs_carregto && pedido.data.obs_carregto.trim().length >= 5;
    if (!!pedido.bonif_amostra_brinde && !hasObsCarregto) {
      return false;
    }
    const hasPrazos = +pedido.data.prazo_medio_solvente > 0 && +pedido.data.prazo_medio_tintacomp > 0;
    if (!hasPrazos) {
      return false;
    }
    if (pedido.isSafra && !pedido.data.tipo_transp) {
      return false;
    }
    if (pedido.data.tipo_frete === "F" && (!pedido.data.codigo_transp || !pedido.data.nome_transp)) {
      return false;
    }
    if (
      pedido.data.tipo_frete !== "F" &&
      pedido.data.redesp_tipo === "F" &&
      (!pedido.data.redesp_transp || !pedido.data.redesp_nome_transp)
    ) {
      return false;
    }
    if (!!pedido.bonif_amostra_brinde && !pedido.data.bonif_amostra_brinde) {
      return false;
    }
    if (!!pedido.isB2B && pedido.data.necessita_avaliacao === "S" && !pedido.data.motivo_avaliacao) {
      return false;
    }
    if (pedido.data.status_pedido === "S" && (!pedido.data.motivo_simulacao || !pedido.data.coligacao_simulacao)) {
      return false;
    }
    if (pedido.data.opcaoKit === "S" && !pedido.data.num_kit) return false;

    if (pedido.data.opcaoKit === "S" && !pedido.data.isValidKit) return false;

    /* STEP PRODUTOS */
    if (current === 1) {
      return pedido.hasItems && pedido.items.filter(item => !item.isValid).length === 0;
    }
    /* STEP ENTREGA */
    if (current === 2) {
      if (pedido.selectedEntrega?.endereco) {
        return true;
      }
      return false;
    }
    /* STEP PAGTO */
    if (current === 3) {
      const hasPagto = !!pedido.data.forma_pagto && !!pedido.data.codigo_condicao;
      const isRevenda = pedido.data.filial_faturamento === "01" || pedido.data.filial_faturamento === "03" || pedido.data.filial_faturamento === "04" || pedido.data.filial_faturamento === "05"
      if (hasPagto) {
        const pagtoPedidoStatus =
          pedido.data.status_pedido === "1" || pedido.data.status_pedido === "9" || pedido.data.status_pedido === "A";

        // pedidos com brinde apenas na Matriz
        if (pagtoPedidoStatus && pedido.hasBrinde && pedido.data.filial_faturamento !== "01") {
          return false;
        }

        // pedidos status BRINDES/AMOSTRA, obrigatório informar uma cupom válido
        const brindeStatus = pedido.data.status_pedido === "5" || pedido.data.status_pedido === "6";
        if (isRevenda && brindeStatus && pedido.data.nexxus_diretor === 'SIM' && ((!!pedido.data.cupom && pedido.data.cupom.trim().length < 6) || !pedido.data.cupom_valido)) {
          return false;
        }
        // pedidos status BRINDES, obrigatório informar uma cupom válido
        const complementarStatus = pedido.data.status_pedido === "C";
        if (complementarStatus && (pedido.peso_bruto_total > 200 || !pedido.data.pedido_originalb2b_valido)) {
          return false;
        }

        if (pagtoPedidoStatus && !!pedido.valida_minimo && !pedido.data.isValidKit) {
          // valor mínimo
          if (Math.max(+pedido.valor_total_nf, 0) < +pedido.precoMinimo) {
            return false;
          }

          // parcela mínima
          if (+pedido.data.qtd_parcelas > 1 && +pedido.valor_parcela < +pedido.parcela_minima) {
            return false;
          }
        }
        //CASO TIVER CUPOM INFORMADO PARA O CLIENTE , FAZ A CONTA
        if (!!pedido.data.verba_codigo && pedido.isPedidoNormal) {
          return pedido.validou_bonificado;
        }

        return true;
      }
      return false;
    }
  }
  return valid;
}

@observer
class PedidoEditorScreen extends Component {
  constructor(props) {
    super(props);

    const {
      isVendedor = true,
      isTipoVendaInterna = false,
      isB2B = false,
      vendedorData = {},
      operadorData = {},
    } = props.store;

    this.pedido = new PedidoStore(
      !!isTipoVendaInterna
        ? {
          venda_interna: vendedorData.codigo,
          unidade_negocio: !isB2B ? "REVENDA" : "B2B",
        }
        : !!isVendedor
          ? {
            codigo_vendedor: vendedorData.codigo,
            permite_fob: vendedorData.permite_fob || "NAO",
            unidade_negocio: !isB2B ? "REVENDA" : "B2B",
          }
          : {
            codigo_operador: operadorData?.codigo_operador || operadorData?.codigo || null,
            unidade_negocio: "REVENDA",
          }
    );

    this.steps = [
      {
        title: "Cliente",
        key: "step-cliente",
        nextLabel: () => (this.pedido.isEdit ? "Revisar Produtos" : "Adicionar Produtos"),
        component: StepCliente,
      },
      {
        title: "Produtos",
        key: "step-produtos",
        nextLabel: () => (this.pedido.isEdit ? "Entrega" : "Entrega"),
        component: StepProduto,
      },
      {
        title: "Entrega",
        key: "step-entrega",
        nextLabel: () => (this.pedido.isEdit ? "Pagamento" : "Definir Pagamento"),
        component: StepEntrega,
      },
      {
        title: "Pagamento",
        key: "step-pagto",
        nextLabel: () => (this.pedido.isEdit ? "Alterar Pedido" : "Incluir Pedido"),
        component: StepPagto,
      },
    ];

    this.state = {
      current: 0,
      started: false,
      programadoBase: [],
      options: {
        linhas: {},
      },
    };
  }
  async componentDidMount() {
    const { store, match } = this.props;
    const isCopy = match.url.indexOf("/add") !== -1;
    const isResiduo = match.url.indexOf("/residuo/edit") !== -1;
    store.trackTab("/pedidos", !isResiduo ? "tab-orcamento" : "tab-residuo");

    await this.fetchBase(!!match.params && !!match.params.slug ? match.params.slug : false);

    if (!!match.params && !!match.params.slug) {
      await this.fetchEdit(match.params.slug, isCopy, isResiduo);
    }
  }
  componentWillUnmount() {
    this.pedido.unregisterListeners();
  }

  async fetchBase(isEdit) {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchBase(!!store.isB2B ? "B2B" : "REVENDA");
      if (!!response.success) {
        const { pedido = null, today = null, options = {} } = response.data;
        if (!!pedido.Pedido) {
          if (!this.pedido.data.programado && "programado" in pedido.Pedido) {
            this.pedido.data.programado = pedido.Pedido.programado;
            this.setState({ programadoBase: [pedido.Pedido.programado] });
          }
          if (!this.pedido.data.prazomedio_referencia && "prazomedio_referencia" in pedido.Pedido) {
            this.pedido.data.prazomedio_referencia = Math.max(+pedido.Pedido.prazomedio_referencia, 0);
          }
          if (!this.pedido.data.taxa_diaria_prazo && "taxa_diaria_prazo" in pedido.Pedido) {
            this.pedido.data.taxa_diaria_prazo = Math.max(+pedido.Pedido.taxa_diaria_prazo, 0);
          }
          if ("peso_total_pedido" in pedido.Pedido) {
            this.pedido.data.peso_total_pedido = Math.max(+pedido.Pedido.peso_total_pedido, 0);
          }
          if ("peso_minimo_revenda_01" in pedido.Pedido) {
            this.pedido.data.peso_minimo_revenda_01 = Math.max(+pedido.Pedido.peso_minimo_revenda_01, 0);
          }
          if ("max_qtd_itens_revenda" in pedido.Pedido) {
            this.pedido.data.max_qtd_itens_revenda = Math.max(+pedido.Pedido.max_qtd_itens_revenda, 0);
          }

          if ("programacao_forames_revenda" in pedido.Pedido) {
            this.pedido.data.programacao_forames_revenda = pedido.Pedido.programacao_forames_revenda;
          }

          if ("nexxus_diretor" in pedido.Pedido) {
            this.pedido.data.nexxus_diretor = pedido.Pedido.nexxus_diretor;
          }

          if ("cashback_revenda" in pedido.Pedido) {
            this.pedido.data.cashback_revenda = pedido.Pedido.cashback_revenda;
          }

        }
        if (!!today) {
          Item.today = this.pedido.today = today;
        }

        this.setState({ options, started: true });
        if (!isEdit) {
          this.pedido.registerPersist(false);
        }
      } else {
        throw new PedidoError("Não foi possível iniciar um novo pedido no momento");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível iniciar um novo pedido no momento",
        onOk() {
          history.replace("/pedidos");
        },
      });
    } finally {
      store.isLoading = false;
    }
  }
  async fetchEdit(codigo, copy = false, residuo = false) {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      const response = await fetchEdit(codigo, residuo, !!store.isB2B ? "B2B" : "REVENDA");
      if (!!response.success) {
        this.pedido.parse(response.data, copy, residuo); // atualiza o formulário com dados vindos do edit, copy ou residuo...
        // this.pedido.registerPersist(codigo);
      } else {
        throw new PedidoError("Não foi possível editar este pedido no momento");
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 5) : 5;
      const errorMessages = {
        1: "Não foi possível editar este pedido no momento",
        5: "Este cliente não está mais disponível para o seu atendimento",
        10: "Pedido não encontrado no sistema ou indisponível no momento",
        15: "Cliente/pedido sem política de desconto definida. Entre em contato com o setor comercial.",
        20: "Os campos de condição de pagamento para este cliente não estão configurados, favor entrar em contato com o setor comercial!",
      };

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || errorMessages[1],
        onOk() {
          history.replace("/pedidos");
        },
      });
    } finally {
      store.isLoading = false;
    }
  }
  savePedido = async () => {
    const { history, store } = this.props;
    store.isLoading = true;

    try {
      this.pedido.clearPersist();
      const response = await savePedido(this.pedido.exportData());
      if (!!response.success) {
        Modal.success({
          title: "Pedido enviado com sucesso!",
          content: "Por favor aguarde alguns minutos até a conclusão da nossa fila de processamento de pedidos.",
          onOk() {
            history.replace("/pedidos");
          },
          autoFocusButton: "ok",
          keyboard: false,
        });
      } else {
        throw new PedidoError("Não foi possível iniciar um novo pedido no momento");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: !!err && !!err.response && !!err.response.data && !!err.response.data.message ? err.response.data.message : "Não foi possível salvar este pedido no momento. Por favor tente novamente mais tarde.",
      });
    } finally {
      store.isLoading = false;
    }
  };
  fetchItem = async (item, forceReset = true) => {
    if (!!item.codigo_produto && !this.pedido.isLoading) {
      const codigo_cliente = `${this.pedido.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.pedido.data.loja_cliente ? `${this.pedido.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.pedido.data.filial_faturamento
        ? `${this.pedido.data.filial_faturamento}`.padStart(2, "0")
        : "01";
      const tipo_frete = this.pedido.tipoFreteTabela;

      this.pedido.isLoading = true;

      try {
        const response = await fetchProduto({
          codigo_cliente,
          loja_cliente,
          filial_faturamento,
          tipo_frete,
          codigo_produto: item.codigo_produto,
          status: this.pedido.data.status_pedido,
          unidade_negocio: this.pedido.data.unidade_negocio,
          tipo_transp: this.pedido.data.tipo_transp,
          op_logistica: this.pedido.data.op_logistica,
          cliente_retira: this.pedido.data.tipo_frete === "C" ? "NAO" : "SIM",
        });
        if (!!response.success) {
          if (this.pedido.data.orcamentoTintometrico || this.pedido.data.isValidKit) {
            response.data.Produto.tintometrico = "SIM"
          }

          item.parse(response.data, !!this.pedido.policy && this.pedido.policy.values);
          if (item.venda_permitida === "NAO") {
            throw new PedidoError(`Vendedor não pode vender esta linha de produto`);
          } else if (item.status === "INATIVO") {
            throw new PedidoError(`Produto bloqueado, não pode ser vendido!`);
          } else if (item.tintometrico === "NAO" && this.pedido.data.status_pedido !== "T") {
            throw new PedidoError(`Cliente não liberado para a compra de produtos da classe Tintométrico!`);
          } else if (item.isPlataformaBloqueada) {
            throw new PedidoError(`Produto bloqueado para venda nesta plataforma!`);
          }
        } else {
          throw new PedidoError(`Produto inválido ou não pertencente a este cliente!`);
        }
      } catch (err) {
        if (!!forceReset) item.reset();

        onPedidoError(
          err.name === "PedidoError" && !!err.message
            ? err.message
            : `Produto inválido, não pertencente a este cliente ou sem preço definido. Por favor entre em contato com o depto. Comercial.`
        );
        return false;
      } finally {
        this.pedido.isLoading = false;
      }
    }
    return true;
  };

  addItems = () => {
    const { pendingItems } = this.pedido;
    const totalPending = pendingItems.length;
    for (let j = 0; j < totalPending; j++) {
      try {
        this.pedido.addItem(pendingItems[j]);
      } catch (err) {
        if (debug) console.warn(err);

        onPedidoError(
          err.name === "PedidoError" && !!err.message
            ? err.message
            : `Não é possível adicionar este item a este pedido.`,
          () => resetItems(this.pedido)
        );
        return false;
      }
    }

    resetItems(this.pedido);
    return true;
  };

  editKit = async codigo_kit => {
    try {
      this.pedido.isLoading = true;

      const response = await fetchKits({
        codigo_kit,
        codigo_cliente: this.pedido.data.codigo_cliente,
        loja_cliente: this.pedido.data.loja_cliente,
        filial_faturamento: this.pedido.data.filial_faturamento,
        tipo_transp: this.pedido.tipoFreteTabela,
        op_logistica: this.pedido.data.op_logistica,
        cliente_retira: this.pedido.data.tipo_frete === "C" ? "NAO" : "SIM",
        perfil_auto: this.pedido.policy.data.cod_perfil_auto,
        perfil_imob: this.pedido.policy.data.cod_perfil_imob,
      });
      if (response.success) {
        const { items = [] } = response.data;
        if (items.length > 0 && !!this._kitEditor) {
          this._kitEditor.open(
            items[0],
            this.pedido.items
              .filter(item => item.hasCampKit && item.campKit.codigo_kit === codigo_kit)
              .map(item => item.asJSON)
          );
          return;
        }
      }

      onPedidoError(`Não é possível alterar itens que fazem parte de KIT. Para isso será necessário excluir!`);
      this.pedido.isLoading = false;
    } catch (err) {
      onPedidoError(`Não é possível alterar itens que fazem parte de KIT. Para isso será necessário excluir!`);
      this.pedido.isLoading = false;
    }
  };
  editItem = item => {
    if (item.neg_anterior === "S") {
      onPedidoError(`Não é possível editar itens de resíduo deste pedido.`);
      return;
    }

    if (!!this._updateProduto) {
      if (this.pedido.isCampKit && !!item.hasCampKit) {
        this.editKit(item.campKit.codigo_kit);
        return false;
      }

      this._updateProduto.open(Item.fromJSON(item.asJSON, !!this.pedido.policy && this.pedido.policy.values), item => {
        try {
          this.pedido.updateItem(item);
        } catch (err) {
          onPedidoError(
            err.name === "PedidoError" && !!err.message ? err.message : `Não é possível alterar este item neste pedido.`
          );
        }
      });
    }
  };
  editBonifItem = item => {
    if (item.neg_anterior === "S") {
      onPedidoError(`Não é possível editar itens de resíduo deste pedido.`);
      return;
    }
    item.quantidadeMaxBonif = item.__quantidade;
    if (!!this._updateBonif) {

      this._updateBonif.open(Item.fromJSON(item.asJSON, !!this.pedido.policy && this.pedido.policy.values), item => {
        try {
          let pendingItems = item; //item;
          pendingItems.bonificado = "S"; //setando como item bonificado...
          pendingItems.neg_anterior = "N";
          this.pedido.addItem(pendingItems);

        } catch (err) {
          onPedidoError(
            err.name === "PedidoError" && !!err.message ? err.message : `Não é possível gerar item de bonificação para este Produto.`
          );
        }
      });
    }
  };
  /*
  preparaGrade = async (grade) => {
    let pendingItems = [];
    let aux = [];
    let totalPending = 0;
    for (let i = 0; i < grade.length; i++) {
      aux.push(grade[i].item);
      //this.pedido.pendingItems = [new Item()];
      this.pedido.pendingItems = [aux[0]].map(itemData => new Item(itemData, this.pedido.policy.values));
      totalPending = this.pedido.pendingItems.length;
      for (let j = 0; j < totalPending; j++) { 
        await this.fetchItemGrade(this.pedido.pendingItems[j] , grade[i].quantidade);  
      }
      aux = [];
    }
  };
  */

  preparaGrade = async (grade, isDescCamp) => {
    let aux = [];
    let totalPending = 0;
    let promises = [];
    let errorMessage = "";
    this.pedido.isLoading = true;

    const itemgrade = (iitem, qtde) => new Promise(resolve => {
      console.log(`started inserting ${qtde}`);
      setTimeout(() => {
        resolve(this.fetchItemAllGrade(iitem, qtde));
      }, 100);
    });

    for (let i = 0; i < grade.length; i++) {
      aux.push(grade[i].item);
      this.pedido.pendingItems = [aux[i]].map(itemData => new Item(itemData, this.pedido.policy.values));
      totalPending = this.pedido.pendingItems.length;
      for (let j = 0; j < totalPending; j++) {
        this.pedido.pendingItems[j].quantidade = grade[i].quantidade;
        promises.push(itemgrade(this.pedido.pendingItems[j], grade[i].quantidade));
      }
    }
    Promise.all(promises).then(async (response) => {
      this.pedido.pendingItems = [];
      this.pedido.isLoading = false;
      for (let j = 0; j < response.length; j++) {
        aux = [response[j].data].map(itemData => new Item(itemData, this.pedido.policy.values));
        const item = new Item();
        item.parse(response[j].data, !!this.pedido.policy && this.pedido.policy.values);
        item.quantidade = response[j].data.Produto.quantidade;

        if (this.pedido.data.isValidKit && this.pedido.data.num_kit) {
          this.pedido.isLoading = true;
          await new Promise((resolve, reject) => {
            fetchApi(`/pedidos/get-product-ticket/${item.codigo_produto}`)
              .then(response => {
                const { zy1_qntd } = response.data.data;
                item.quantidade_max_liberado_kit = zy1_qntd;
                item.isValidTicket = +zy1_qntd > 0 ? true : false;
                resolve();
              })
              .catch(error => {
                reject(error);
              });
          });
          this.pedido.isLoading = false;
        }

        if (isDescCamp && item.canChangeDescCamp) {
          item.desc_camp = Math.max(+item.campanha.base_campanha, 0);
          item.campanha.desconto = Math.max(+item.campanha.base_campanha, 0);
        }
        try {
          this.pedido.addItem(item);
        } catch (err) {
          if (errorMessage.indexOf(err.message) === -1) {
            errorMessage = errorMessage + `${err.message}  \n`;
          }
        }
      }
      if (!!this.pedido) {
        resetItems(this.pedido);
        if (errorMessage.length > 0) {
          onPedidoError(errorMessage);
        }
      }
    });
  };

  fetchItemAllGrade = async (item, qtdGrade, isDescCamp) => {
    if (!!item.codigo_produto) {
      const codigo_cliente = `${this.pedido.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.pedido.data.loja_cliente ? `${this.pedido.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.pedido.data.filial_faturamento
        ? `${this.pedido.data.filial_faturamento}`.padStart(2, "0")
        : "01";
      const tipo_frete = this.pedido.tipoFreteTabela;

      try {
        const response = await fetchProduto({
          codigo_cliente,
          loja_cliente,
          filial_faturamento,
          tipo_frete,
          codigo_produto: item.codigo_produto,
          status: this.pedido.data.status_pedido,
          unidade_negocio: this.pedido.data.unidade_negocio,
          tipo_transp: this.pedido.data.tipo_transp,
          op_logistica: this.pedido.data.op_logistica,
          cliente_retira: this.pedido.data.tipo_frete === "C" ? "NAO" : "SIM",
        });
        if (!!response.success) {
          response.data.Produto.quantidade = qtdGrade;
          return response;
        } else {
          return null;
        }
      } catch (err) {
        return null;
      } finally {

      }
    }
    return null;
  };

  fetchItemGrade = async (item, qtdGrade) => {
    if (!!item.codigo_produto && !this.pedido.isLoading) {
      const codigo_cliente = `${this.pedido.data.codigo_cliente}`.padStart(6, "0");
      const loja_cliente = !!this.pedido.data.loja_cliente ? `${this.pedido.data.loja_cliente}`.padStart(2, "0") : "01";
      const filial_faturamento = !!this.pedido.data.filial_faturamento
        ? `${this.pedido.data.filial_faturamento}`.padStart(2, "0")
        : "01";
      const tipo_frete = this.pedido.tipoFreteTabela;

      this.pedido.isLoading = true;

      try {
        const response = await fetchProduto({
          codigo_cliente,
          loja_cliente,
          filial_faturamento,
          tipo_frete,
          codigo_produto: item.codigo_produto,
          status: this.pedido.data.status_pedido,
          unidade_negocio: this.pedido.data.unidade_negocio,
          tipo_transp: this.pedido.data.tipo_transp,
          op_logistica: this.pedido.data.op_logistica,
          cliente_retira: this.pedido.data.tipo_frete === "C" ? "NAO" : "SIM",
        });

        if (!!response.success) {
          item.parse(response.data, !!this.pedido.policy && this.pedido.policy.values);
          try {
            item.quantidade = qtdGrade;
            if (qtdGrade > 0) {
              //this.pedido.pendingItems.push(item);
              this.pedido.addItem(item);
            }
            //this.pedido.addItem(item);
          } catch (err) {
            if (debug) console.warn(err);

            onPedidoError(
              err.name === "PedidoError" && !!err.message
                ? err.message
                : `Não é possível adicionar este item a este pedido.`,
              () => resetItems(this.pedido)
            );
            return false;
          }
        } else {
          throw new PedidoError(`Produto inválido ou não pertencente a este cliente!`);
        }
      } catch (err) {
        onPedidoError(
          err.name === "PedidoError" && !!err.message
            ? err.message
            : `Produto inválido, não pertencente a este cliente ou sem preço definido. Por favor entre em contato com o depto. Comercial.`
        );
        return false;
      } finally {
        this.pedido.isLoading = false;
        resetItems(this.pedido);
      }
    }
    return true;
  };

  removeItem = item => {
    /* if (this.pedido.isResiduo && item.neg_anterior === "S") {
      onPedidoError(`Não é possível remover itens de resíduo deste pedido.`);
      return;
    } */

    const onOk = () => {
      try {
        if (!!item.hasCampKit && item.neg_anterior !== "S" && item.bonificado !== "S") {
          this.pedido.removeCampKit(item.campKit.codigo_kit);
        } else {
          this.pedido.removeItem(item);
        }
      } catch (err) {
        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Não é possível remover este item neste pedido.`
        );
      }
    };
    Modal.confirm({
      title: !!item.hasCampKit && item.neg_anterior !== "S" && item.bonificado !== "S"
        ? "Este produto faz parte de um KIT, todos os itens deste KIT serão removidos. Deseja remover estes itens assim mesmo?"
        : "Você deseja remover este item?",
      onOk,
    });
  };
  openChooser = (modal = "cliente", onChoose = null) => {
    const _onChoose = (...args) => {
      if (!!onChoose && typeof onChoose === "function") {
        const { pedido } = this;
        onChoose(...args);
        if (!pedido.isEdit && modal === 'cliente') {
          if (!isDev && ((pedido.statusCad === 'P') && pedido.isAtualizaOn === 'SIM' && pedido.data.codigo_cliente)) {
            this._atualizaModal.open(pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente);
          }
        }
      }
    };

    switch (modal) {
      case "kit":
        this._choosers.kit.open(kit => {
          if (!!kit) {
            if (this.pedido.hasItems && kit.CampKit.produto_fora_kit.toUpperCase() === "NAO") {
              onPedidoError(
                `Seu pedido já possui produtos previamente adicionados e este KIT não permite a adição de itens fora da Campanha.`
              );
              return;
            }

            if (!!this._kitEditor) {
              this._kitEditor.open(kit);
            }
            _onChoose(kit);
          }
        });
        break;
      case "residuo":
        this._choosers.residuo.open(residuo => {
          if (!!residuo) {
            let pendingItems = [];
            let aux = [];
            let totalPending = 0;
            for (let i = 0; i < residuo.length; i++) {
              aux.push(residuo[i]);
              pendingItems = [aux[0]].map(itemData => new Item(itemData, this.pedido.policy.values));
              totalPending = pendingItems.length;
              for (let j = 0; j < totalPending; j++) {
                try {
                  this.pedido.addItem(pendingItems[j]);
                } catch (err) {
                  onPedidoError(
                    err.name === "PedidoError" && !!err.message
                      ? err.message
                      : `Não foi possível adicionar residuo a este pedido.`
                  );
                }
              }
              aux = [];
            }
          }
        });

        break;
      case "cliente":
        this._choosers.cliente.open(async value => {
          if (!!value) {
            this.pedido.data.codigo_cliente = value.codigo;
            try {
              await this.pedido.loadCliente();
              _onChoose(value);
            } catch (err) {
              const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
              const errorMessages = {
                1: `Acesso não autorizado`,
                10: `Cliente inválido ou não pertencente a sua região!`,
                15: `Cliente sem política de desconto!`,
                20: `Os campos de condição de pagamento para este cliente não estão configurados, favor entrar em contato com o setor comercial!`,
                25: `Favor preencher os dados de potencial de compra mensal de solvente e tinta/complemento no cadastro do cliente!`,
                30: `Cliente com dedução de ST Ativa porém com a data de vigência vencida!`,
              };
              onPedidoError(errorMessages[+errorCode] || err.message);
            }
          }
        });
        break;
      case "cliente_entrega":
        this._choosers.cliente.open(async value => {
          if (!!value) {
            this.pedido.data.cliente_entrega = value.codigo;
            this.pedido.data.razao_social_entrega = value.razao_social;
            this.pedido.data.loja_entrega = value.loja;
          }
        });
        break;
      case "alvara":
        this._choosers.alvara.open(value => {
          if (!!value) {
            this.pedido.data.codigo_alvara = value.codigo_alvara;
            _onChoose(value);
          }
        });
        break;
      case "transp":
        this._choosers.transp.open(value => {
          if (!!value) {
            this.pedido.data.codigo_transp = value.codigo_transportadora;
            this.pedido.data.nome_transp = value.nome_transportadora;
            _onChoose(value);
          }
        });
        break;
      case "redesp":
        this._choosers.redesp.open(value => {
          if (!!value) {
            this.pedido.data.redesp_transp = value.codigo_transportadora;
            this.pedido.data.redesp_nome_transp = value.nome_transportadora;
            _onChoose(value);
          }
        });
        break;
      case "produto":
        this._choosers.produto.open(_onChoose);
        break;
      case "grade":
        this._choosers.grade.open((grade, isDescCamp) => {
          if (!!grade) {
            this.preparaGrade(grade, isDescCamp);
          }
        });
        break;
      case "grade_orcamento_tintometrico":
        this._choosers.grade_orcamento_tintometrico.open((grade, isDescCamp) => {
          if (!!grade) {
            this.preparaGrade(grade, isDescCamp);
          }
        });
        break;
      case "condicao":
        this._choosers.condicao.open(value => {
          if (!!value) {
            this.pedido.data.codigo_condicao = value.codigo_condicao;
            this.pedido.data.codigo_solicitacao = value.codigo_solicitacao;
            this.pedido.data.nome_condicao = value.descricao_condicao;
            this.pedido.data.qtd_parcelas = Math.max(+value.qtd_parcelas, 1) || 1;
            this.pedido.data.prazo_medio = Math.max(+value.prazo_medio, 1) || 1;
            _onChoose(value);
          }
        }, this.pedido.data.forma_pagto);
        break;
      default:
        console.warn("Chooser modal not found");
        break;
    }
  };
  stepBack = () => {
    const { current } = this.state;
    if (current > 0) {
      this.setState({
        current: Math.max(current - 1, 0),
      });
    } else {
      if (this.pedido.hasCliente) {
        Modal.confirm({
          title: "Você deseja sair do pedido?",
          content: "Ao sair, ficará salvo suas últimas alterações.",
          onOk: () => this.props.history.replace("/pedidos"),
        });
      } else {
        this.props.history.goBack();
      }
    }
  };
  stepForward = () => {
    const { current } = this.state;
    try {
      if (enableForward(this.pedido, +current) && this.pedido.validateStep(+current)) {
        if (current < 3) {
          this.setState({
            current: Math.min(current + 1, this.steps.length - 1),
          });
        } else {
          const { status_pedido } = this.pedido.data;
          const pagtoStatus = status_pedido === "1" || status_pedido === "9" || status_pedido === "A";
          const valorTotalNf = Math.max(+this.pedido.valor_total_nf, 0);
          if (pagtoStatus && !this.pedido.permite_fob && valorTotalNf >= 2500 && this.pedido.data.tipo_frete === "F") {
            Modal.confirm({
              title: "Atenção",
              content:
                "Seu pedido está com o valor total acima de R$ 2.500,00 e está como FOB, você deseja confirmar este pedido?",
              okText: "Confirmar",
              cancelText: "Revisar Opções de Frete",
              onOk: () => this.savePedido(),
              onCancel: () => {
                this.pedido.tipo_frete_enabled = true;
                this.pedido.redesp_tipo_enabled = true;

                this.setState({ current: 0 });
              },
            });
          } else {
            this.savePedido();
          }
        }
      }
    } catch (err) {
      if (err.name === "PedidoError" && !!err.message) {
        if (!!err.code && +err.code === 101) {
          Modal.confirm({
            title: "Atenção",
            content: <span dangerouslySetInnerHTML={{ __html: err.message.split("\n").join("<br />") }} />,
            cancelText: "Revisar Itens do Pedido",
            okText: "Prosseguir",
            onOk: () =>
              this.setState({
                current: Math.min(current + 1, this.steps.length - 1),
              }),
          });
        } else if (!!err.code && +err.code === 102 && !!this._proporcaoInfo) {
          this._proporcaoInfo.open();
        } else {
          onPedidoError(err.message);
        }
      } else {
        onPedidoError(`Não é possível prosseguir com este pedido.`);
      }
    }
  };
  render() {
    const { history, store } = this.props;
    const { current, options, started, programadoBase } = this.state;
    const currentStep = this.steps[current];

    const isLoading = !started || this.pedido.isLoading || store.isLoading;
    const pedidoJSON = this.pedido.asJSON;

    return (
      <div className="pedido-editor-screen">
        {!isLoading && (
          <Helmet>
            <title>
              {this.pedido.isEdit
                ? `Editando Pedido Nº ${this.pedido.numero_pedido} - ANJO NEXXUS`
                : `Novo Pedido - ANJO NEXXUS`}
            </title>
          </Helmet>
        )}
        <Spin spinning={isLoading}>
          {started ? (
            <Fragment>
              <div className="pedido-editor--header">
                <BackButton onClick={this.stepBack} />
                <Steps current={current}>
                  {this.steps.map(item => (
                    <Step key={item.key} title={item.title} />
                  ))}
                </Steps>
                {currentStep.key === "step-produtos" && this.pedido.hasProporcao && (
                  <Button
                    type={!this.pedido.isProporcaoValid ? "danger" : "primary"}
                    ghost={!!this.pedido.isProporcaoValid}
                    style={{ marginRight: 9 }}
                    onClick={() => !!this._proporcaoInfo && this._proporcaoInfo.open()}>
                    <Icon type="info-circle-o" />
                    Proporção
                  </Button>
                )}
                {currentStep.key === "step-produtos" && this.pedido.isCampKit && (
                  <Button
                    disabled={this.pedido.hasCampKit}
                    type="danger"
                    style={{ marginRight: 9 }}
                    onClick={() => this.openChooser("kit")}>
                    Kits Promocionais
                  </Button>
                )}
                {currentStep.key === "step-produtos" && !this.pedido.isResiduo /*&& !this.pedido.bonif_amostra_brinde */ && !this.pedido.isSimulacao && !this.pedido.isB2B && (
                  <Button
                    disabled={!this.pedido.hasFilial && !this.pedido.hasCliente}
                    type="danger"
                    style={{ marginRight: 9 }}
                    onClick={() => this.openChooser("residuo")}>
                    Residuo
                  </Button>
                )}
                <Button disabled={!enableForward(this.pedido, +current)} type="primary" onClick={this.stepForward}>
                  {!!currentStep.nextLabel
                    ? typeof currentStep.nextLabel === "function"
                      ? currentStep.nextLabel()
                      : currentStep.nextLabel
                    : "Avançar"}
                  {current < 2 ? <Icon type="right" /> : <Icon type="file-add" />}
                </Button>
              </div>
              {currentStep.key === "step-produtos" && (
                <FormProdutoHeader
                  isB2B={this.pedido.isB2B}
                  chooser={this.openChooser}
                  pedido={this.pedido}
                  pendingItems={this.pedido.pendingItems.map(item => item.asJSON)}
                  onFetch={this.fetchItem}
                  onAdd={this.addItems}
                  onPedidoError={onPedidoError}
                  isLoading={isLoading}
                />
              )}
              <div className="pedido-editor--content">
                {!!currentStep.component &&
                  React.createElement(currentStep.component, {
                    pedidoJSON,
                    pedido: this.pedido,
                    step: currentStep,
                    stepBack: this.stepBack,
                    stepForward: this.stepForward,
                    chooser: this.openChooser,
                    resetItems: resetItems.bind(this, this.pedido),
                    onFetchItem: this.fetchItem,
                    onAddItems: this.addItems,
                    onEditItem: this.editItem,
                    onEditBonifItem: this.editBonifItem,
                    onRemoveItem: this.removeItem,
                    onPedidoError,
                    programadoBase,
                    options,
                    history,
                    store,
                  })}
              </div>
              {(this.pedido.hasPolicy || this.pedido.hasItems) && (
                <div className="pedido-editor--footer">
                  {this.pedido.hasPolicy && current < 2 && (
                    <PolicyBar policy={this.pedido.policy} condicaoTaxa={this.pedido.condicaoTaxa} />
                  )}
                  {this.pedido.hasItems && (
                    <TotalBar
                      totais={pedidoJSON.data.totais}
                      proporcao={toJS(this.pedido.proporcaoClasses)}
                      style={{ margin: "0 0 9px" }}
                    />
                  )}
                </div>
              )}
            </Fragment>
          ) : (
            <div style={{ minHeight: 480 }} />
          )}
        </Spin>
        <ChooserCliente
          ref={_ref => (this._choosers["cliente"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <ChooserCliente
          ref={_ref => (this._choosers["cliente_entrega"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <ChooserAlvara
          ref={_ref => (this._choosers["alvara"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <ChooserTransp
          ref={_ref => (this._choosers["transp"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
          tipo="desp"
        />
        <ChooserTransp
          ref={_ref => (this._choosers["redesp"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
          tipo="redesp"
        />
        <ChooserProduto
          ref={_ref => (this._choosers["produto"] = _ref)}
          loading={this.props.store.isLoading}
          isB2B={this.pedido.isB2B}
          pedido={this.pedido}
        />
        <ChooserGrade
          ref={_ref => (this._choosers["grade"] = _ref)}
          filial={this.pedido.data.filial_faturamento}
          cliente={this.pedido.data.codigo_cliente}
          loja={this.pedido.data.loja_cliente}
          status_pedido={this.pedido.data.status_pedido}
          cod_canal={this.pedido.data.cod_canal}
          linhas={options.linhas}
          onPedidoError={onPedidoError}
          onFetch={this.fetchItem}
          onAdd={this.addItems}
          isLoading={this.props.store.isLoading}
        />
        <ChooserGradeOcamentoTintometrico
          ref={_ref => (this._choosers["grade_orcamento_tintometrico"] = _ref)}
          filial={this.pedido.data.filial_faturamento}
          cliente={this.pedido.data.codigo_cliente}
          loja={this.pedido.data.loja_cliente}
          status_pedido={this.pedido.data.status_pedido}
          cod_canal={this.pedido.data.cod_canal}
          linhas={options.linhas}
          onPedidoError={onPedidoError}
          onFetch={this.fetchItem}
          onAdd={this.addItems}
          isLoading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <ChooserResiduo
          ref={_ref => (this._choosers["residuo"] = _ref)}
          loading={this.props.store.isLoading}
          isB2B={this.pedido.isB2B}
          pedido={this.pedido}
        />
        <ChooserCondicao
          ref={_ref => (this._choosers["condicao"] = _ref)}
          loading={this.props.store.isLoading}
          isB2B={this.pedido.isB2B}
          pedido={this.pedido}
        />
        <ChooserKit
          ref={_ref => (this._choosers["kit"] = _ref)}
          loading={this.props.store.isLoading}
          pedido={this.pedido}
        />
        <UpdateProdutoModal
          ref={_ref => (this._updateProduto = _ref)}
          isB2B={this.pedido.isB2B}
          pedido={this.pedido}
          isLoading={isLoading}
          onPedidoError={onPedidoError}
        />
        <UpdateBonifModal
          ref={_ref => (this._updateBonif = _ref)}
          isB2B={this.pedido.isB2B}
          isLoading={isLoading}
          onPedidoError={onPedidoError}
        />
        <ProporcaoInfo
          ref={_ref => (this._proporcaoInfo = _ref)}
          atingida={this.pedido.hasItems ? this.pedido.proporcaoAtingida : 0}
          proporcao={this.pedido.proporcao}
          isLoading={isLoading}
        />
        <KitEditor
          ref={_ref => (this._kitEditor = _ref)}
          isLoading={isLoading}
          onPedidoError={onPedidoError}
          pedido={this.pedido}
        />
        <CadastroEditModalScreen
          ref={_ref => (this._atualizaModal = _ref)}
          loading={isLoading}
          pedido={this.pedido}
          renewFocus={() => this.pedido.resetCliente()}
        />
      </div>
    );
  }
  _choosers = {};
  _updateProduto;
  _proporcaoInfo;
  _kitEditor;
  _atualizaModal = {};
}

export default withStore(PedidoEditorScreen);