import React, { Component, Fragment } from "react";
import { Modal, Row, Col, Form, Input, Button, Spin } from "antd";
import { observer } from "mobx-react";

import { fetchApi, fetchPostApi } from "actions";
import { getData, validateCNPJ } from "@util";
import withStore from "withStore";

import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
import InputSearch from "components/InputSearch";
import Select, { Option } from "components/Select";
import { REGION_STATES } from "env";
import { MaskedInput } from "antd-mask-input";
import Anexos from "components/treinamento/Anexos";
import ChooserEmpresa from "./ChooserEmpresa";

const FormItem = Form.Item;

@observer
class EmpresaEditorScreen extends Component {
  state = {
    isValidCNPJ: false,
    cnpj: false
  };
  async componentDidMount() {
    if (this.props.match.params.edit !== 'add') {
      await this.fetchBase();
    }
  }
  async fetchBase() {
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/treinamento/empresa/edit/" + (this.props.match.params.edit || this.props.store?.treinamentoFornecedorData?.codigo));
      if (!!response.success) {
        const { cnpj, razao_social, nome_fantasia, uf, cidade, telefone, email, email_contato } = response.data;
        this.setState({ cnpj, nome_fantasia }, !this.props.store?.treinamentoFornecedorData?.codigo ? this.handleCNPJ : () => { })
        this.props.form.setFieldsValue({
          cnpj,
          razao_social,
          nome_fantasia,
          uf,
          cidade,
          telefone,
          email,
          email_contato
        });
      } else {
        throw new Error("Não foi possível obter dados atualizados para este formulário");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados para este formulário",
        onOk: () => this.props.history.replace("/empresadash/empresa"),
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }
  async saveEmpresa(data) {
    const { store, history, match } = this.props;

    const errorMessages = {
      10: "Não foi possível registrar sua empresa neste momento. Tente novamente mais tarde",
    };

    store.isLoading = true;

    try {
      if (match.params.edit !== 'add') {
        data = {
          ...data,
          codigo: (match.params.edit || this.props.store.treinamentoFornecedorData.codigo)
        }
      }

      data = {
        ...data,
        manual: this.state.isValidCNPJ ? 'S' : 'N'
      }

      const { data: response } = await fetchPostApi("/treinamento/empresa/" + (match.params.edit === 'add' ? "save" : "update"), data);
      if (!!response.success) {
        Modal.success({
          title: "Empresa registrada com sucesso",
          onOk() {
            history.replace("/empresadash/empresa");
          },
          keyboard: false,
        });

        store.isLoading = false;
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });

      store.isLoading = false;
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveEmpresa(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  openChooser = async () => {
    this._choosers.empresa.open(async value => {
      this.setState({ cnpj: value.cnpj }, this.handleCNPJ);
    });
  }
  handleCNPJ = async event => {
    let cnpj = this.state.cnpj || event.target.value.toString().trim();
    if (cnpj) {
      this.setState({ isValidCNPJ: false });

      if (cnpj.length >= 14 && validateCNPJ(cnpj)) {
        this.props.store.isLoading = true;
        try {
          const { data: response } = await fetchApi("/treinamento/empresa/busca_cnpj/" + cnpj.replace(/[^\d]+/g, ""));
          if (!!response.success && !!response.valid) {
            if (!!response.data) {
              const { cnpj, razao_social, nome_fantasia, uf, cidade, telefone, email } = response.data;
              this.props.form.setFieldsValue({
                cnpj,
                razao_social,
                nome_fantasia,
                uf,
                cidade,
                telefone,
                email
              })
            }
          } else {
            throw new Error(response.data.message || "CNPJ não cadastrado, por favor preencha os campos manualmente");
          }
        } catch (err) {
          Modal.warning({
            title: "Atenção",
            content: err.message || "CNPJ não cadastrado, por favor preencha os campos manualmente",
          });
          this.setState({ isValidCNPJ: true });
        } finally {
          this.props.store.isLoading = false;
        }
      }
    }

    this.setState({ cnpj });
  };
  render() {
    const { isValidCNPJ } = this.state;
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    const inputProps = {
      readOnly: isLoading,
      autoComplete: "off",
      disabled: !isValidCNPJ,
    };

    const isAdd = match.params.edit === 'add';

    const optionsUF = Object.keys(REGION_STATES).map(uf => (
      <Option key={uf} value={uf}>
        {REGION_STATES[uf]}
      </Option>
    ));

    return (
      <div className="cadastro-cliente-screen">
        <PageHeader title={isAdd ? "Nova Empresa" : `Alterar empresa #${(match.params.edit || this.props.store?.treinamentoFornecedorData?.codigo)} ${!!this.state.nome_fantasia ? ` - ${this.state.nome_fantasia}` : ''}`} headerLeft={<BackButton onClick={() => this.props.history.goBack()} />} />
        <Spin spinning={!!isLoading}>
          {!this.props.store?.treinamentoFornecedorData?.codigo &&
            <Form
              autoComplete="off"
              layout="vertical"
              hideRequiredMark={false}
              onSubmit={this.handleSubmit}
              className="cadastro-cliente--form">
              <div>
                <Row gutter={12}>
                  <Col span={6}>
                    <FormItem label="CNPJ">
                      {getFieldDecorator("cnpj", { rules: [v.required, v.integer, v.cnpj] })(
                        <InputSearch
                          readOnly={isLoading}
                          maxLength={14}
                          onChange={e => this.setState({ cnpj: e.target.value })}
                          onBlur={this.handleCNPJ}
                          onSearch={this.openChooser}
                          onPressEnter={this.handleCNPJ}
                        />
                        // <InputSearch
                        //   enterButton={
                        //     <Button
                        //       title="Obter os dados deste CNPJ na Receita Federal"
                        //       type="primary"
                        //       icon="search"
                        //       onClick={this.handleCNPJ}
                        //       loading={isLoading}>
                        //       Consultar
                        //     </Button>
                        //   }
                        //   readOnly={isLoading}
                        //   maxLength={14}
                        //   onChange={e => this.setState({ cnpj: e.target.value })}
                        // />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={11}>
                    <FormItem label="Razão Social">
                      {getFieldDecorator("razao_social", { rules: [v.required] })(
                        <Input maxLength={100} {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={7}>
                    <FormItem label="Nome fantasia">
                      {getFieldDecorator("nome_fantasia", { rules: [v.required] })(
                        <Input maxLength={100} {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={5}>
                    <FormItem label="Estado">
                      {getFieldDecorator("uf", { rules: [v.required] })(
                        <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Selecione um Estado">
                          {optionsUF}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="Cidade">
                      {getFieldDecorator("cidade", { rules: [v.required] })(
                        <Input maxLength={100} {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="Telefone">
                      {getFieldDecorator("telefone", { rules: [v.required] })(
                        <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={7}>
                    <FormItem label="Email">
                      {getFieldDecorator("email")(<Input {...inputProps} />)}
                    </FormItem>
                  </Col>
                  <Col span={7}>
                    <FormItem label="Email para contato">
                      {getFieldDecorator("email_contato", { rules: [v.email, v.required] })(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: 6, marginBottom: 16 }}>
                <Button
                  style={{ marginTop: 9 }}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="cadastro-cliente--button">
                  {isAdd ? 'Enviar' : 'Alterar'} cadastro
                </Button>
              </div>
            </Form>
          }
          <Fragment>
            {match.params.edit !== 'add' &&
              <Anexos empresa={(match.params.edit || this.props.store?.treinamentoFornecedorData?.codigo)} />
            }
          </Fragment>
          <ChooserEmpresa
            ref={_ref => (this._choosers["empresa"] = _ref)}
            loading={this.props.store.isLoading}
          />
        </Spin>
      </div >
    );
  }
  _choosers = {};
}

export default Form.create()(withStore(EmpresaEditorScreen));
