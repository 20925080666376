import React, { Component } from "react";
import { Icon, Dropdown, Menu, Tag, Button } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import classNames from "classnames";

const decorateRows = (item) =>
  classNames("base-table--row", {
    "base-table--row__danger": item.vencido !== '0',
    "base-table--row__sucess": item.vencido === '0' && item.vencendo === '0' && item.atualizado !== '0',
    "base-table--row__invalid": item.vencido === '0' && item.vencendo !== '0',
  });

@observer
class MonitoraFuncionarioScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: `Funcionários`,
      uri: "/treinamento/pendencia/funcionarios",
      defaultFilter: {
        empresa: this.props.store.treinamentoFornecedorData.codigo,
      },
    });

    this.state = {
      status: null
    };
  }

  render() {
    const { history } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        exportOnly: true,
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        width: "30%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (nome, item) => {
          return (
            <WrappedValue value={<><Tag color="blue">{item.cpf}</Tag>{nome}</>} />
          )
        }
      },
      {
        title: "Regime / Cargo",
        dataIndex: "cargo",
        key: "cargo",
        sorter: true,
        width: "15%",
        filteredValue: this.ds.filterValue("cargo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Cargo" {...props} />,
        filterParser: filter => ({ ...filter, field: "cargo", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (cargo, item) => {
          return (
            <WrappedValue value={<><Tag color="orange">{item.regime}</Tag>{cargo}</>} />
          )
        }
      },
      {
        title: "Localidade",
        dataIndex: "",
        key: "localidade",
        width: "13%",
        export: false,
        render: (_, item) => {
          return (
            <div>{item.cidade} / {item.uf}</div>
          )
        }
      },
      {
        title: "Cidade",
        dataIndex: "cidade",
        exportOnly: true,
      },
      {
        title: "UF",
        dataIndex: "uf",
        exportOnly: true,
      },
      {
        title: "Tel.",
        key: "telefone",
        dataIndex: "telefone",
        width: '10%',
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        render: (email) => {
          return (
            <WrappedValue value={email} />
          )
        }
      },
      {
        title: "",
        key: "actionbar",
        width: "12%",
        export: false,
        render: (_, item) => {
          const menu =
            <Menu>
              <Menu.Item key="menu-anexos"
                onClick={() => history.push(`${history.location.pathname}/anexos/${item.codigo}`)}>
                <Icon type="check" />
                Anexar Documento
              </Menu.Item>
            </Menu>
          return (
            <Dropdown.Button
              onClick={() => history.push(`${history.location.pathname}/${item.codigo}`)}
              title="Alterar este funcionário"
              size="small"
              type="primary"
              overlay={menu}>
              <Icon type="edit" />
              Alterar
            </Dropdown.Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} >
          <Button onClick={() => history.push(`${history.location.pathname}/add`)} type="primary" icon="plus">Novo funcionário</Button>
        </PageTitle>
        {/* <PageHeader title={this.ds.name}
          headerLeft={<BackButton onClick={() => history.push(`/empresa`)} />}
          headerRight={<Button onClick={() => history.push(`${history.location.pathname}/add`)} type="primary" icon="plus">Novo funcionário</Button>}>
        </PageHeader> */}
        <FullTable
          rowKey={item => (`${item.id_funcio}:${item.funcionario}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          rowClassName={decorateRows}
          errorMessage="Não foi possível obter os funcionários no momento"
        />
      </div>
    );
  }
}

export default withStore(MonitoraFuncionarioScreen);
