import React, { Component, Fragment } from "react";
import { Carousel } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import MetaPanelOT from "components/dashboard/MetaPanelOT";
import MetaPanelLinha from "components/dashboard/MetaPanelLinha";
import MixPanelOT from "components/dashboard/MixPanelOT";
import MixPanelOTAut from "components/dashboard/MixPanelOTAut";
import MixPanelOTCar from "components/dashboard/MixPanelOTCar";
import MixPanelOTImob from "components/dashboard/MixPanelOTImob";
import SemaforoOTPanel from "components/dashboard/SemaforoOTPanel";
import Logoimage from "../../img/logoOkus.png";
import bgImage from "../../img/bg_dashboard.png";
import MetaPanelOTIndividual from "components/dashboard/MetaPanelOTIndividual";
import MixOTIndividual from "components/dashboard/MixOTIndividual";

@observer
class TelevendasAcessoScreen extends Component {
  renderDashboard() {
    return (
      <Fragment>
        <MetaPanelOT tipo="Objetivo Faturamento" />
        <MetaPanelLinha tipo="Objetivo Faturamento" />
      </Fragment>
    );
  }

  renderDashboardMop() {
    return (
      <Fragment>
        <div style={{
          position: "absolute",
          display: "flex",
          zIndex: 0,
          pointerEvents: "none",
          opacity: "29%",
        }}>
          <img
            src={bgImage}
            alt="Okus"
            style={{ width: window.innerWidth, height: window.innerHeight, display: "block" }}>
          </img>
        </div>
        <div style={{
          zIndex: 0,
        }}>
          <Carousel
            autoplaySpeed={15000} autoplay
          >
            <SemaforoOTPanel tipo='nps' />
            <SemaforoOTPanel tipo='ticket_medio' />
            <SemaforoOTPanel tipo='venda_mix' />
            <MetaPanelOT tipo="Objetivo Faturamento" />
            <MetaPanelOTIndividual tipo="Objetivo Faturamento Individual" />
            <MixOTIndividual tipo="Venda Mix Individual" />
            <MixPanelOT />
            <MixPanelOTAut />
            <MixPanelOTCar />
            <MixPanelOTImob />
          </Carousel>
        </div>
        <div style={{
          position: "absolute",
          bottom: "12px",
          right: "12px",
          zIndex: 0,
          pointerEvents: "none",
          opacity: 5,
        }}>
          <img
            src={Logoimage}
            alt="Okus"
            style={{ width: "150px", height: "auto" }}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="home-screen home-screen__vendedor" style={{ overflow: "hidden", height: "100vh" }} >
        {this.renderDashboardMop()}
      </div>
    );
  }
}

export default withStore(TelevendasAcessoScreen);
