import React, { Component, Fragment } from "react";
import { Spin, Modal, Form, Input, Button, Icon, Checkbox, Alert } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";
import { autorun, toJS } from "mobx";
import classnames from "classnames";

import { fetchClasses } from "actions/orcamento";

import {
  SearchForm,
  Toolbar,
  ChooserTable,
  TableDataSource,
  WrappedValue,
  mapColumn,
} from "components/FullTable";

import "./ChooserGrade.less";

const MODE_LINHA = "MODE_LINHA";
const MODE_CLASSE = "MODE_CLASSE";
const MODE_PRODUTO = "MODE_PRODUTO";

const DescricaoCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataTag = styled.div`
  display: inline-block;
  background-color: #c00;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const TagCashBack = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const rowProdutoKey = item => `${item.Produto.codigo}:${item.Produto.codigo_linha}:${item.Produto.classe_produto}`;

const FormItem = Form.Item;
const Search = Input.Search;

@observer
class SearchProdutoForm extends Component {
  render() {
    const { placeholder = "Buscar opção", dataSource, onSearch, onChangeCheck, checked } = this.props;

    const inputProps = {
      readOnly: dataSource.isLoading,
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={(_, m) => {
          if ("query" in m) onSearch(m);
        }}
        filterFields={{ query: "text" }}
        className="chooser-produto-search">
        {({ form, submitForm }) => (
          <Fragment>
            <FormItem>
              <Checkbox
                defaultChecked={checked}
                onChange={e => onChangeCheck(e.target.checked)}
                {...inputProps}
              >
                Desc. Camp?
              </Checkbox>
            </FormItem>
            <FormItem style={{ flex: 1 }}>
              {form.getFieldDecorator("query")(
                <Search
                  placeholder={placeholder}
                  enterButton
                  onSearch={event => setTimeout(submitForm, 30)}
                  {...inputProps}
                />
              )}
            </FormItem>
            {!!dataSource.hasFilter && (
              <FormItem>
                <Toolbar.Button size="default" icon="close" ghost={true} onClick={() => onSearch({})} />
              </FormItem>
            )}
          </Fragment>
        )}
      </SearchForm>
    );
  }
}

@observer
export default class ChooserProdutoOrcamentoTintometrico extends Component {
  constructor(props) {
    super(props);
    const { pedido } = props;

    this.ds = new TableDataSource({
      name: "Encontre seu Produto",
      uri: pedido.data.isValidKit ? "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/K/" + pedido.data.num_kit : "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/T"
    });

    this.state = {
      loading: false,
      visible: false,
      onChoose: null,
      mode: MODE_LINHA,
      linha: null,
      classe: null,
      items: [],
      current: {},
      focused: null,
      tipo_produto: null,
      isDescCamp: false
    };
  }

  componentDidMount() {
    const { pedido } = this.props;

    this._watchDS = autorun(
      () => {
        this.ds.uri = pedido.data.isValidKit ? "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/K/" + pedido.data.num_kit : "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/T"
      },
      { delay: 60 }
    );
  }

  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }

  open = (onChoose = null) => {
    this.ds.fetch();

    this.setState({
      loading: false,
      visible: true,
      mode: MODE_LINHA,
      classesNiveis: {},
      linha: null,
      classe: null,
      items: [],
      onChoose,
      current: {},
      focused: null,
      tipo_produto: null,
    });
  };

  close = () => {
    this.setState({ visible: false, isDescCamp: false });
  };

  saveCurrentValue = (item, field) => event => {
    const { current, tipo_produto } = this.state;
    const key = rowProdutoKey(item);
    const value = typeof event === "string" ? event : +event.target.value;
    const result = Object.values(current);
    const carrinho = result.filter(item => item.quantidade > 0).length;
    let idlinha = '';


    if (carrinho === 0 && +event.target.value === 0) {
      idlinha = null;
    }
    else if (carrinho === 0 && tipo_produto === null && +event.target.value > 0) {
      idlinha = item.Produto.tipo_produto;
    }
    else if (carrinho > 0 && (tipo_produto === 'LINHA' || tipo_produto === 'ESPECIAL')) {
      idlinha = tipo_produto;
    }
    else {
      idlinha = null;
    }

    this.setState({ focused: field + ":" + key, tipo_produto: idlinha, current: { ...current, [key]: { ...current[key], [field]: value, item: item } } });
  };

  hasChanged = (item, field) => {
    const { current } = this.state;
    const key = rowProdutoKey(item);

    return (
      !current[key] || (!!current[key] && (!current[key][field] || `${current[key][field]}` !== `${toJS(item.Produto[field])}`))
    );
  };

  checkQuantidade = item => {
    if (this.hasChanged(item, "quantidade")) {
      const { onPedidoError } = this.props;
      try {
        this.validaQuantidade(item);
      } catch (err) {
        onPedidoError(err.name === "PedidoError" && !!err.message ? err.message : `Quantidade inválida!`);
      } finally {
        //sucesso total
      }
    }
  };

  validaQuantidade(item) {
    const { cod_canal, status_pedido, onPedidoError, pedido } = this.props;
    const { current } = this.state;
    const __errors = [];
    const field = "quantidade";
    const key = rowProdutoKey(item);
    const quantidade = current[rowProdutoKey(item)].quantidade; //item.Produto.quantidade;

    // Desabilita arredondamento e qtd. min. em pedidos status = Amostra ou se o perfil do cliente for FUNCIONARIO
    if ((status_pedido === "5" && item.Produto.tipo_sku === "PA") || (cod_canal === "10") || quantidade === 0) {
      return;
    }

    if (+quantidade > 0) {
      const quantidade_por_fardo = Math.max(+item.Produto.quantidade_por_fardo, 1);
      const fardos = Math.max(Math.ceil(+quantidade / quantidade_por_fardo), 1);
      const quantidade_precisa = fardos * quantidade_por_fardo;

      if (pedido.data.isValidKit) {
        if (quantidade > +item.Produto.quantidade_max_ticket) {
          this.setState({ current: { ...current, [key]: { ...current[key], [field]: item.Produto.quantidade_max_ticket, item: item } } });
          __errors.push("A quantidade não pode ser maior do que a quantidade liberada");
        }
      }
      if (quantidade_precisa !== +quantidade && !pedido.data.isValidKit) {
        //item.Produto.quantidade = quantidade_precisa;
        this.setState({ current: { ...current, [key]: { ...current[key], [field]: quantidade_precisa, item: item } } });
        __errors.push("Não pode ser vendido fardo quebrado");
      }

      // if(+quantidade > Math.max(+item.Produto.quantidade_max_ticket, 1) && pedido.data.isValidKit){
      //   const quantidade_liberada = Math.max(+item.Produto.quantidade_max_ticket, 1);
      //   this.setState({ current: { ...current, [key]: { ...current[key], [field]: quantidade_liberada, item: item } } });
      //   __errors.push("O produto informado não pode ser maior do que a quantidade liberada pelo ticket.");
      // }
    }

    if (__errors.length > 0) {
      onPedidoError(__errors.join(" / "));
    }
  }

  onUpdate = (grade) => {
    const { onChoose, isDescCamp } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(grade, isDescCamp);
    }
    this.close();
  };

  updateDataSourceUri = () => {
    const { pedido } = this.props;

    let _freshUri = pedido.data.isValidKit ? "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/K/" + pedido.data.num_kit : "/cliente/orcamentos/produtos-orcamento-tintometrico/" + pedido.data.filial_faturamento + "-" + pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente + "/T"

    this.ds.uri = _freshUri;
  };

  searchProdutos = filters => {
    if (!!filters) {
      const freshState = (() => {
        if (!filters["query"] && !this.state.classe) {
          return { items: [], classe: null, linha: null, mode: MODE_LINHA, isDescCamp: this.state.isDescCamp };
        }

        return { mode: MODE_PRODUTO, isDescCamp: this.state.isDescCamp };
      })();

      this.setState(freshState);

      requestAnimationFrame(() => {
        if (freshState.mode === MODE_LINHA) {
          this.ds.reset();
        }

        this.updateDataSourceUri();

        if (!!this._chooserProduto) {
          this._chooserProduto.updateDataSource(null, filters, null);
          this._chooserProduto.fetchData();
        }
      });
    }
  };

  selectLinha = async ({ value }) => {
    this.setState({ loading: true });

    const onError = () => {
      this.props.onOrcamentoError(`Não foi possível obter a lista de classes neste momento`);
      this.close();
    };

    try {
      const response = await fetchClasses(value);
      if (!!response.success) {
        this.setState({
          linha: value,
          classe: null,
          classesNiveis: response.data,
          loading: false,
          mode: MODE_CLASSE,
        });
      } else {
        onError();
      }
    } catch (err) {
      onError();
    }
  };

  selectItems = (item, keys = []) => {
    const { items } = this.state;
    if (keys.indexOf(item.key) !== -1) {
      this.setState({ items: [...items, item] });
    } else {
      this.setState({ items: items.filter(aitem => rowProdutoKey(aitem) !== item.key) });
    }
  };

  renderTitle = () => {
    return "Escolha seu(s) Produto(s)";
  };

  renderFooter = () => {
    const { onChoose = null, current } = this.state;
    const isLoading = !!this.props.isLoading || this.state.loading;
    const result = Object.values(current);
    const carrinho = result.filter(item => item.quantidade > 0).length;

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Button
          type="primary"
          loading={isLoading}
          disabled={carrinho === 0}
          onClick={() => {
            if (!!onChoose && typeof onChoose === "function") {
              this.onUpdate(result)
            }
            this.close();
          }}>
          {carrinho > 1 ? "Adicionar Itens" : "Adicionar Item"}
          {carrinho > 0 ? ` (${carrinho})` : ""}
        </Button>
      </div>
    );
  };
  render() {
    const { width = 875, isLoading = false, pedido } = this.props;
    const { mode, visible = false, tipo_produto, current } = this.state;
    const result = Object.values(current);
    const carrinho = result.filter(item => item.quantidade > 0).length;

    const COLUMNS_PRODUTOS = [
      {
        title: "Linha",
        key: "linha_produto",
        dataIndex: "Produto.linha_produto",
        width: "12.5%",
      },
      {
        title: "Tipo",
        key: "tipo_produto",
        dataIndex: "Produto.tipo_produto",
        width: "8%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo",
        width: "12%",
        className: "text-left",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.Produto.permite_tintometrico === 'NAO' && <DataTag>Apenas Clientes Tintométrico</DataTag>}
            {item.Produto.venda_liberada === 'NAO' && <DataTag>Venda bloqueada</DataTag>}
            {!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) && <DataTag>Bloq. na plataforma</DataTag>}
            {item.Produto.possui_cashback === 'SIM' && <TagCashBack> <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />CASHBACK</TagCashBack>}
          </DescricaoCell>
        ),
      },
      {
        title: "UN/CX",
        key: "quantidade_por_fardo",
        dataIndex: "Produto.quantidade_por_fardo",
        width: "9%",
      },
      {
        title: "Qtd.Venda",
        key: "quantidade",
        dataIndex: "quantidade",
        width: "10%",
        render: (_, item) => (
          <Input
            size="small"
            type="number"
            min="0"
            step="1"
            lang="en-150"
            disabled={!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) || item.Produto.permite_tintometrico === "NAO" || item.Produto.venda_liberada === "NAO" || (carrinho > 0 && tipo_produto !== null && item.Produto.tipo_produto !== "AMBOS" && item.Produto.tipo_produto !== tipo_produto)}
            //xvalue={this.state.focused === `quantidade:${rowProdutoKey(item)}` && +item.Produto.quantidade <= 0 ? "" : +item.Produto.quantidade}
            value={!!this.state.current[rowProdutoKey(item)] && this.state.current[rowProdutoKey(item)].quantidade > 0 ? this.state.current[rowProdutoKey(item)].quantidade : ""}
            className="text-center td-input"
            //onFocus={this.saveCurrentValue(item, "quantidade")}
            onChange={this.saveCurrentValue(item, "quantidade")}
            onBlur={event => {
              this.setState({ focused: null });

              const value = (typeof event === "string" ? event : event.target.value).trim();
              if (!value || isNaN(+value)) {
                item.quantidade = 0;
              }

              this.checkQuantidade(item);
            }}
          />
        ),
      }
    ];

    const COLUMNS_PRODUTOS_TICKET = [
      {
        title: "Linha",
        key: "linha_produto",
        dataIndex: "Produto.linha_produto",
        width: "12.5%",
      },
      {
        title: "Tipo",
        key: "tipo_produto",
        dataIndex: "Produto.tipo_produto",
        width: "8%",
      },
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "Produto.codigo",
        width: "12%",
        className: "text-left",
      },
      {
        title: "Produto",
        key: "descricao",
        dataIndex: "Produto.descricao",
        className: "text-left",
        render: (text, item) => (
          <DescricaoCell>
            <WrappedValue value={text} type="text" style={{ minWidth: "60%" }} />
            {item.Produto.permite_tintometrico === 'NAO' && <DataTag>Apenas Clientes Tintométrico</DataTag>}
            {item.Produto.venda_liberada === 'NAO' && <DataTag>Venda bloqueada</DataTag>}
            {!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) && <DataTag>Bloq. na plataforma</DataTag>}
            {item.Produto.possui_cashback === 'SIM' && <TagCashBack> <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />CASHBACK</TagCashBack>}
          </DescricaoCell>
        ),
      },
      {
        title: "UN/CX",
        key: "quantidade_por_fardo",
        dataIndex: "Produto.quantidade_por_fardo",
        width: "9%",
      },
      {
        title: "QTD. Liberada",
        key: "quantidade_max_ticket",
        dataIndex: "Produto.quantidade_max_ticket",
        width: "9%",
      },
      {
        title: "Qtd.Venda",
        key: "quantidade",
        dataIndex: "quantidade",
        width: "10%",
        render: (_, item) => (
          <Input
            size="small"
            type="number"
            min="0"
            step="1"
            lang="en-150"
            disabled={!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) || item.Produto.permite_tintometrico === "NAO" || item.Produto.venda_liberada === "NAO" || (carrinho > 0 && tipo_produto !== null && item.Produto.tipo_produto !== "AMBOS" && item.Produto.tipo_produto !== tipo_produto)}
            //xvalue={this.state.focused === `quantidade:${rowProdutoKey(item)}` && +item.Produto.quantidade <= 0 ? "" : +item.Produto.quantidade}
            value={!!this.state.current[rowProdutoKey(item)] && this.state.current[rowProdutoKey(item)].quantidade > 0 ? this.state.current[rowProdutoKey(item)].quantidade : ""}
            className="text-center td-input"
            //onFocus={this.saveCurrentValue(item, "quantidade")}
            onChange={this.saveCurrentValue(item, "quantidade")}
            onBlur={event => {
              this.setState({ focused: null });

              const value = (typeof event === "string" ? event : event.target.value).trim();
              if (!value || isNaN(+value)) {
                item.quantidade = 0;
              }

              this.checkQuantidade(item);
            }}
          />
        ),
      }
    ];

    return (
      <Modal
        centered
        visible={visible}
        title={this.renderTitle()}
        footer={this.renderFooter()}
        onCancel={this.close}
        width={width}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName={classnames("chooser-produto-modal", {
          "chooser-produto-modal__produto": mode === MODE_PRODUTO,
        })}>
        <Spin spinning={!!this.state.loading || isLoading} style={{ minHeight: 200 }}>
          <Fragment>
            <p>Selecione um ou mais produtos abaixo e depois pressione o botão "Adicionar Itens" para finalizar:</p>
            <Alert
              style={{ marginBottom: 12 }}
              showIcon
              message={this.state.isDescCamp ? "Desconto campanha aplicado nos produtos concorrentes, aproveite!" : "Desconto campanha não será aplicado automaticamente!"}
              type={this.state.isDescCamp ? "success" : "error"} />
            <SearchProdutoForm
              placeholder="Filtrar por código ou nome do produto"
              dataSource={this.ds}
              onSearch={this.searchProdutos}
              checked={this.state.isDescCamp}
              onChangeCheck={checked => this.setState({ isDescCamp: checked })}
            />
            <ChooserTable
              ref={_ref => (this._chooserProduto = _ref)}
              className="chooser-table__compact"
              loading={isLoading}
              paginationProps={{
                style: {
                  float: "none",
                  textAlign: "center",
                  margin: "15px 0 0",
                },
              }}
              locale={{
                emptyText: "Nenhum produto disponível no momento para esta busca ou filtro de classe.",
              }}
              disabledRowKeys={(item) => {
                if (!!(item.Produto.bloqueado_plataforma === item.Vendedor.plataforma_vendedor) || item.Produto.permite_tintometrico === "NAO" || item.Produto.venda_liberada === "NAO" || (carrinho > 0 && tipo_produto !== null && item.Produto.tipo_produto !== "AMBOS" && item.Produto.tipo_produto !== tipo_produto)) {
                  return true;
                }
              }}
              rowKey={rowProdutoKey}
              columns={pedido.data.isValidKit ? COLUMNS_PRODUTOS_TICKET.map(mapColumn) : COLUMNS_PRODUTOS.map(mapColumn)}
              fetchOnMount={false}
              dataSource={this.ds}
              errorMessage="Não foi possível listar seus produtos no momento"
            //onSelectRow={this.selectItems}
            />
          </Fragment>
        </Spin>
      </Modal>
    );
  }
  _chooserProduto;
}
