import React, { Component } from "react";
import { Modal, Form } from "antd";
import { observer } from "mobx-react";

import Anexos from "components/treinamento/Anexos";

@observer
class AnexosModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      visible: false,
      cliente: null,
      loja: null,
      treinamento: null,
      necessita_anexo: null,
      vigencia: null,
      funcionario: null
    };

  }
  open = (treinamento, necessita_anexo, vigencia, funcionario) => {
    this.setState({ visible: true, treinamento, necessita_anexo: necessita_anexo === 'S', vigencia: vigencia === 'S', funcionario });
  };
  close = () => {
    if (!this.state.isLoading) {
      this.setState({ visible: false }, () => {
      });
    }
  };
  render() {
    const { necessita_anexo, vigencia, treinamento } = this.state;

    return (
      <Modal
        centered
        width={990}
        footer={null}
        title={`Anexos`}
        visible={this.state.visible}
        onCancel={this.close}
        destroyOnClose={true}
        maskClosable={true}
        wrapClassName="interacoes-modal">
        <Anexos
          empresa={this.props.match.params.empresa || this.props?.store?.treinamentoFornecedorData?.codigo}
          funcionario={this.props.match.params.funcionario || this.state.funcionario}
          mostraTable={!this.state.funcionario}
          treinamento={treinamento}
          necessita_anexo={necessita_anexo}
          vigencia={vigencia}
          onClose={this.props.onClose && this.props.onClose}
        />
      </Modal >
    );
  }
}

export default Form.create()(AnexosModal);