import React, { Component } from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";
import { n } from "@util";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, WrappedValue } from "components/FullTable";

import "./RankVolumeScreen.less";

const rowClassName = (item, loginName) => {
  const className = [""];
  if (item.nome_vendedor === loginName) {
    className.push("ranking-table");
  }
  return className.join(" ");
};

@observer
class RankVolumeScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Ranking volume",
      uri: "/r/ranking",
    });

    this.state = {};
  }

  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    return (
      <Alert
        style={{ width: "100%", fontSize: 14, letterSpacing: -0.5, fontWeight: "500", marginBottom: 15, marginRight: 15 }}
        message={`As informações do ranking são atualizadas a cada 30 minutos                                                                                       !`}
        type="warning"
        showIcon
      />
    );
  };


  render() {
    const { store } = this.props;
    const isVendedor = !!store.isVendedor || false;
    const loginName = store.loginName || null;
    const codigo_gerente = !!store.diretorData ? store.diretorData.codigo_gerente : null;
    const isDiretorOrPresidente = store.tipoDiretor === "DIRETOR" || store.tipoDiretor === "PRESIDENTE";

    const columns = [
      {
        title: "Ano",
        dataIndex: "ano",
        key: "ano",
        width: "5%",
      },
      {
        title: "Mês",
        dataIndex: "mes",
        key: "mes",
        width: "5%",
      },
      {
        title: "Ranking",
        dataIndex: "posicao",
        key: "posicao",
        sorter: false,
        width: "10%",
      },
      {
        title: "Nome",
        dataIndex: "nome_vendedor",
        key: "nome_vendedor",
        sorter: false,
        width: "40%",
        render: (v, item) => <WrappedValue value={(isVendedor && loginName === v) || (item.codigo_gerente === codigo_gerente) || isDiretorOrPresidente ? v : '---'} type="text" />,
      },
      {
        title: "Nome Fantasia",
        dataIndex: "nome_fantasia",
        key: "nome_fantasia",
        sorter: false,
        exportOnly: isVendedor,
        width: "25%",
        render: (v, item) => <WrappedValue value={(isVendedor && loginName === v) || (item.codigo_gerente === codigo_gerente) || isDiretorOrPresidente ? v : '---'} type="text" />,
      },
      {
        title: "Volume Realizado",
        dataIndex: "volume_realizado",
        key: "volume_realizado",
        sorter: false,
        render: v => <WrappedValue value={n(v, 2)} type="text" />,
        className: "text-right",
        width: "15%",
      },
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name} />
        <FullTable
          actionBar="none"
          rowKey={item => `${item.posicao}:${item.nome_vendedor}`}
          loading={this.props.store.isLoading}
          columns={columns}
          rowClassName={item => rowClassName(item, loginName)}
          dataSource={this.ds}
          beforeComponent={this.renderSearchForm}
          errorMessage="Não foi possível obter o ranking de vendedores no momento"
        />
      </div>
    );
  }
}

export default withStore(RankVolumeScreen);
