import React, { PureComponent, Fragment } from "react";
import { Route } from "react-router-dom";

import TreinamentoScreen from "screens/treinamento/TreinamentoScreen";
import TreinamentoEditorScreen from "screens/treinamento/TreinamentoEditorScreen";
import EmpresaScreen from "screens/treinamento/empresa/EmpresaScreen";
import EmpresaEditorScreen from "screens/treinamento/empresa/EmpresaEditorScreen";
import FuncionarioScreen from "screens/treinamento/funcionario/FuncionarioScreen";
import FuncionarioEditorScreen from "screens/treinamento/funcionario/FuncionarioEditorScreen";
import AtrelarTreinamentoScreen from "screens/treinamento/AtrelarTreinamentoScreen";
import AtrelarTreinamentoEmpresaScreen from "screens/treinamento/empresa/AtrelarTreinamentoScreen";
import HomeScreen from "screens/treinamento/dashboard/HomeScreen";
import TreinamentoPadraoScreen from "screens/treinamento/empresa/TreinamentoPadraoScreen";
import PendenteAprovacaoScreen from "screens/treinamento/PendenteAprovacaoScreen";
import VideoModal from "screens/treinamento/VideoModal";

export default class Treinamento extends PureComponent {
  render() {
    const { match } = this.props;

    return (
      <Fragment>
        <Route exact path={match.url} component={HomeScreen} />
        <Route exact path="/treinamento" component={TreinamentoScreen} />
        <Route exact path="/treinamento/:edit" component={TreinamentoEditorScreen} />
        <Route exact path="/empresadash/:situacao" component={EmpresaScreen} />
        <Route exact path="/empresa/:empresa/:edit/:funcionario" component={AtrelarTreinamentoScreen} />
        <Route exact path="/empresa/:edit" component={EmpresaEditorScreen} />
        <Route exact path="/empresa/padrao/:empresa" component={TreinamentoPadraoScreen} />
        <Route exact path="/empresa/:edit/:empresa" component={AtrelarTreinamentoEmpresaScreen} />
        <Route exact path="/funcionario/:empresa" component={FuncionarioScreen} />
        <Route exact path="/funcionario/:empresa/:edit" component={FuncionarioEditorScreen} />
        <Route exact path="/funcionario/:empresa/:edit/:funcionario" component={AtrelarTreinamentoScreen} />
        <Route exact path="/pendente-aprovacao" component={PendenteAprovacaoScreen} />
        <Route exact path="/introducao" component={VideoModal} />
      </Fragment>
    );
  }
}