import React, { PureComponent } from "react";
import { Layout, Icon, Spin } from "antd";
import styled from "styled-components";
import { darken } from "polished";

import { isDev, isStaging } from "@env";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { fetchApi } from "actions";
import withStore from "withStore";

const ToggleMenuWrapper = styled.div`
  line-height: 44px;
  color: #fff;
  cursor: pointer;

  & > .anticon {
    font-size: 21px;
    line-height: 44px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
  }

  &:hover {
    color: ${darken(0.1, "#fff")};
  }
`;

const TriggerLabel = styled.span`
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  line-height: 44px;
  margin-left: 9px;
  transition: color 0.3s;
`;

const HeaderWrapper = styled(Layout.Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.primary};
    padding: 0 28px;
    min-height: ${props => props.theme.headerHeight};
    line-height: 1;

    .ant-dropdown-link {
      font-size: 15px;
      color: #fff;

      &:focus {
        text-decoration: none;
      }
    }
  }
`;

const LogoWrapper = styled.h1`
  margin: 0;
  cursor: default;
`;

const RankingWrapper = styled.div`
  color: #FFF;
  font-size: 25px;
  display: flex;
`;

const VideoWrapper = styled.div`
  color: #FFF;
  font-size: 25px;
  display: flex;
`;

const ToggleMenuButton = ({ collapsed = false, onClick }) => (
  <ToggleMenuWrapper onClick={onClick}>
    <Icon type={collapsed ? "menu-unfold" : "menu-fold"} />
    <TriggerLabel>Menu</TriggerLabel>
  </ToggleMenuWrapper>
);

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ranking: null,
    };
  }

  componentDidMount() {
    this.fetchRanking();
  }

  async fetchRanking() {
    const { store } = this.props;
    const isVendedor = !!store.isVendedor || false;
    const loginName = store.loginName || null;

    fetchApi(`/r/ranking`)
      .then(({ data: res }) => {
        const filtered = res.data.items.filter((item) => isVendedor && loginName === item.nome_vendedor)

        if (filtered.length > 0) {
          this.setState({ ranking: filtered[0] });
        } else {
          this.setState({ ranking: {} });
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  render() {
    const { collapsed = false, hideMenuButton = false, onCollapse, children, store } = this.props;
    const { ranking } = this.state;
    const isRevenda = store?.vendedorData?.unidade_negocio === "REVENDA";
    const isTreinamento = !!store?.treinamentoFornecedorData || !! !!store?.treinamentoData;

    return (
      <HeaderWrapper>
        {!hideMenuButton && <ToggleMenuButton collapsed={collapsed} onClick={onCollapse} />}
        <LogoWrapper>
          <img
            src={
              isStaging
                ? require("img/logoNexxus_staging.png")
                : isDev
                  ? require("img/logoNexxus_dev.png")
                  : require("img/logoNexxus.png")
            }
            alt="ANJO NEXXUS"
          />
        </LogoWrapper>
        {isRevenda ?
          <Spin spinning={!ranking} className="spinColorWhite">
            {ranking?.posicao > 0 &&
              <RankingWrapper>
                <span style={{ margin: 'auto' }}>{ranking?.posicao}°</span>
                <Link to="/r/ranking">
                  <Icon type="trophy" theme="twoTone" style={{ fontSize: 35, color: '#e8d41e' }} />
                </Link>
              </RankingWrapper>
            }
          </Spin>
          : null}
        {isTreinamento &&
          <VideoWrapper>
            <Link to="/introducao">
              <span>
                <Icon type="youtube" theme="twoTone" style={{ fontSize: 35, color: '#f00', background: 'radial-gradient(circle, rgba(255,255,255,1) 30%, rgba(15,115,185,1) 30%)' }} />
              </span>
            </Link>
          </VideoWrapper>
        }
        {children}
      </HeaderWrapper>
    );
  }
}

export default withStore(Header);
