import React, { Component } from "react";
import { Button, Icon, Dropdown, Menu, Modal } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, WrappedValue } from "components/FullTable";

import { fetchApi } from "actions";

@observer
class TreinamentoScreen extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      name: "Treinamentos",
      uri: "/treinamento/",
      state: {
        filiais: {},
      },
    });

    this.state = {
      status: null
    };
  }

  tryDelete = async (id) => {
    try {
      this.props.store.isLoading = true;
      const { data: response } = await fetchApi("/treinamento/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Treinamento #${id} excluido com sucesso`
        });
        this.ds.fetch();
      }
    } catch (error) {
      Modal.error({
        title: "Atenção",
        content: error
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  handleDelete = (treinamentoId) => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir o treinamento #${treinamentoId}`,
      okType: 'danger',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.tryDelete(treinamentoId)
      }
    })
  }

  render() {
    const { history } = this.props;

    const columns = [
      {
        title: "Cód.",
        dataIndex: "codigo",
        key: "codigo",
        width: '8%',
        sorter: true,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        width: "20%",
        filteredValue: this.ds.filterValue("nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por nome" {...props} />,
        filterParser: filter => ({ ...filter, field: "nome", value: `%${filter.value}%`, op: ":LIKE" }),
      },
      {
        title: "Sigla",
        dataIndex: "sigla",
        key: "sigla",
        sorter: true,
        width: "10%",
        filteredValue: this.ds.filterValue("sigla"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por sigla" {...props} />,
        filterParser: filter => ({ ...filter, field: "sigla", value: `%${filter.value}%`, op: ":LIKE" }),
        render: (_, item) => {
          return (
            <div>{item.sigla}</div>
          )
        }
      },
      {
        title: "Interno?",
        key: "interno",
        dataIndex: "interno",
        width: '10%',
        render: (interno) => {
          return (
            <div>{interno === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Vigência?",
        key: "vigencia",
        dataIndex: "vigencia",
        width: '10%',
        render: (vigencia) => {
          return (
            <div>{vigencia === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Necessita anexo?",
        dataIndex: "necessita_anexo",
        key: "necessita_anexo",
        width: "12%",
        render: (necessita_anexo) => {
          return (
            <div>{necessita_anexo === 'S' ? 'Sim' : 'Não'}</div>
          )
        }
      },
      {
        title: "Observação",
        key: "observacao",
        dataIndex: "obs",
        render: (obs) => {
          return (
            <WrappedValue value={obs} />
          )
        }
      },
      {
        title: "",
        key: "actionbar",
        width: "12%",
        export: false,
        render: (_, item) => {
          const menu =
            <Menu onClick={() => this.handleDelete(item.codigo)}>
              <Menu.Item key="menu-delete">
                <Icon type="delete" />
                Deletar treinamento
              </Menu.Item>
            </Menu>
          return (
            <Dropdown.Button
              onClick={() => history.push(`/treinamento/${item.codigo}`)}
              title="Alterar este Treinamento"
              size="small"
              type="primary"
              overlay={menu}>
              <Icon type="edit" />
              Alterar
            </Dropdown.Button>
          )
        }
      }
    ];

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
          <Button onClick={() => history.push(`/treinamento/add`)} type="primary" icon="plus">Novo Treinamento</Button>
        </PageTitle>
        <FullTable
          rowKey={item => (`${item.codigo}:${item.nome}`)}
          loading={this.props.store.isLoading}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter os treinamentos no momento"
        />
      </div>
    );
  }
}

export default withStore(TreinamentoScreen);
