import React, { PureComponent } from "react";
import { Spin, Popover, Form, DatePicker } from "antd";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";

import { fetchSemaforos } from "actions/dashboard";
import { PageTitle } from "./Styled";
import ModalCliente from "screens/common/Components/ModalCliente";
import ModalMix from "screens/common/Components/ModalMix";

const SemaforoRow = styled.tr`
  th,
  td {
    border: 1px solid #e8e8e8;
    text-align: center;
    align: center;
    font-size: 15px;
    font-weight: bold;
    background-color: ${props => (!!props.isOdd ? "#fafafa" : "#fff")};
    font-weight: ${props => (!!props.isStrong ? "bold" : "normal")};

    &.danger {
      background-color: #f00;
      color: #fff;
    }
    &.success {
      background-color: #096;
      color: #fff;
    }
    &.alert {
      background-color: #faf900;
      color: #000;
    }
    &.neutro {
      background-color: #fff;
      color: #fff;
    }

  }
  th {
    font-size: 12px;
    font-weight: bold;
    background-color: #f2f2f2;
  }
  div {
    width: 20px;
    display: inline-block;
    padding: 13px;
    margin-top: 8px;
    border-radius:100%;
    &.danger {
      background-color: #f00;
      color: #fff;
    }
    &.success {
      background-color: #096;
      color: #fff;
    }
    &.alert {
      background-color: #faf900;
      color: #000;
    }
    &.neutro {
      background-color: #fff;
      color: #fff;
    }

  }
`;

const MONTHSOBJ = {
  Janeiro: "jan",
  Fevereiro: "fev",
  "Março": "mar",
  Abril: "abr",
  Maio: "mai",
  Junho: "jun",
  Julho: "jul",
  Agosto: "ago",
  Setembro: "set",
  Outubro: "out",
  Novembro: "nov",
  Dezembro: "dez",
}

const MONTHS = {
  janeiro: "Jan.",
  fevereiro: "Fev.",
  marco: "Mar.",
  abril: "Abr.",
  maio: "Mai.",
  junho: "Jun.",
  julho: "Jul.",
  agosto: "Ago.",
  setembro: "Set.",
  outubro: "Out.",
  novembro: "Nov.",
  dezembro: "Dez.",
};

// const text = <span>Alexandro Ramos</span>;
// const color = '#2db7f5';
export default class SemaforoPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      ano: null,
      opened: false,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  onOpen(title, indicador) {
    if (indicador === '03') {
      this._cli.open(title);
    } else {
      this._mix.open(title);
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      let ano = null;

      if (!!this.state.ano) {
        ano = this.state.ano.year();
      }

      const response = await fetchSemaforos(!!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null, !!ano ? ano : null);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data, ano, opened } = this.state;

    return (
      <Spin spinning={isLoading}>
        <div style={{ minHeight: 160 }}>
          <PageTitle title="Painel indicadores" meta={false} />
          <Form.Item>
            <DatePicker mode='year'
              value={ano}
              open={opened}
              placeholder="Selecione o ano"
              format="YYYY"
              onOpenChange={status => this.setState({ opened: !!status })}
              onPanelChange={ano => this.setState({ opened: false, ano }, this.fetchData)} />
          </Form.Item>
          {isLoading ? (
            <p>
              <strong>Carregando...</strong>
            </p>
          ) : !!data && !!data.semaforos ? (
            <table cellSpacing={0} cellPadding={0} border={0} style={{ width: "100%" }}>
              <tbody>
                <SemaforoRow>
                  <td></td>
                  {Object.keys(MONTHS).map(m => (
                    <td>{MONTHS[m]}</td>
                  ))}
                </SemaforoRow>

                {Object.keys(data.semaforos).map(m => (
                  <SemaforoRow key={data.semaforos[m].descricao}>
                    <td>{data.semaforos[m].descricao}</td>
                    {Object.keys(MONTHSOBJ).map((mes, i) => {
                      const values = Object.values(MONTHSOBJ);
                      return (
                        <td>
                          <Popover
                            title={data.semaforos[m].descricao + " - " + mes}
                            content={<span dangerouslySetInnerHTML={{ __html: data.semaforos[m][`html_${values[i]}`] }}
                            />}>
                            <div
                              onClick={() => this.onOpen(i, data.semaforos[m].indicador)}
                              style={{ cursor: (data.semaforos[m].indicador === '03' || data.semaforos[m].indicador === '09') && 'pointer' }}
                              className={data.semaforos[m][values[i]] === "3" ? "danger" :
                                data.semaforos[m][values[i]] === "1" ? "success" :
                                  data.semaforos[m][values[i]] === "2" ? "alert" : "neutro"}>
                            </div>
                          </Popover>
                        </td>
                      )
                    })}
                  </SemaforoRow>
                ))}
              </tbody>
            </table>

          ) : (
            <p>
              <strong>Dados insuficientes para mostrar todas as suas informações.</strong>
            </p>
          )}
        </div>
        <ModalCliente
          ref={_ref => (this._cli = _ref)}
          title={"Teste"} />
        <ModalMix
          ref={_ref => (this._mix = _ref)}
          codigo={this.props.codigo}
          isOperador={this.props.isOperador} />
      </Spin>
    );
  }
  _cli;
  _mix;
}
