import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import EmpresasPendentesScreen from "./EmpresasPendentesScreen";
import VisitantesScreen from "./VisitantesScreen";

@observer
class HomeScreen extends Component {
  render() {
    return (
      <div className="home-screen home-screen__cliente">
        <Fragment>
          <EmpresasPendentesScreen />
          <VisitantesScreen />
        </Fragment>
      </div>
    );
  }
}

export default HomeScreen;
