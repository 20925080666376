import React, { Component } from "react";
import { Row, Col, Icon, message, Spin } from "antd";
import { observer } from "mobx-react";

import { fetchApi } from "actions";
import PageTitle from "components/PageTitle";
import Banner from "components/treinamento/Banner";

@observer
class EmpresasPendentesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null
    };
  }

  componentDidMount() {
    this.fetchPendencias();
  }

  fetchPendencias = async () => {
    this.setState({ isLoading: true });
    try {
      const { data: response } = await fetchApi("/treinamento/dashboard/");

      this.setState({ data: response.data })
    } catch (error) {
      message.error(error?.message || "Erro ao carregar informações");
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, data } = this.state;

    return (
      <Spin spinning={isLoading}>
        <PageTitle title="Empresas" meta={false} />
        <Row gutter={16} style={{ marginBottom: 21 }}>
          <Col span={8}>
            <Banner href="/empresadash/vencido" type="vencido">
              <div>
                <Icon type="close" style={{ marginBottom: 7 }} />
                <br />
                Vencidos
                <br />
                {data?.vencido}
              </div>
            </Banner>
          </Col>
          <Col span={8}>
            <Banner href="/empresadash/vencendo" type="avencer">
              <div>
                <Icon type="warning" style={{ marginBottom: 7 }} />
                <br />
                Vencendo
                <br />
                {data?.vencendo}
              </div>
            </Banner>
          </Col>
          <Col span={8}>
            <Banner href="/empresadash/atualizado" type="atualizado">
              <div>
                <Icon type="check" style={{ marginBottom: 7 }} />
                <br />
                Atualizados
                <br />
                {data?.atualizado}
              </div>
            </Banner>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default EmpresasPendentesScreen;
