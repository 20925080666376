import React, { PureComponent } from "react";
import { Col, Row, Spin } from "antd";
import { PieChart, Pie, Cell } from "recharts";
import styled from "styled-components";
import _isEqual from "lodash/isEqual";

import { n } from "@util";
import { fetchMetaLinha } from "actions/dashboard";
import { ChartGroup, ChartGroupTitle, ChartValue, ChartGroupLinha, ChartGroupLinhaMeta } from "./StyledLinha";

const RADIAN = Math.PI / 180;

const DashboardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 26px 16px;
`;

const MetaChart = ({ prefix, label, meta, carteira, chartValue, chartValue_fat, chartSimbol, width = 240, height = 90, percent, percent_fat, children, ...props }) => {
  const pieRadius = {
    innerRadius: (width / 2) * 0.4,
    outerRadius: (width / 2) * 0.6,
  };

  const pieProps = {
    startAngle: 180,
    endAngle: 0,
    cx: width / 2,
    cy: height * 0.8,
  };

  const arrowData = [{ value: Math.min(chartValue, meta) }, { value: 0 }, { value: Math.max(meta - chartValue, 0) }];
  const arrowData_fat = [{ value: Math.min(chartValue_fat, meta) }, { value: 0 }, { value: Math.max(meta - chartValue_fat, 0) }];

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + width * 0.095) * cos;
    const my = cy + (outerRadius + width * 0.095) * sin;

    return (
      <g>
        <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="5" stroke="#494949" fill="none" strokeLinecap="round" />
        <circle cx={cx} cy={cy} r={width * 0.035} fill="#363636" stroke="none" />
      </g>
    );
  };

  return (
    <ChartGroup>
      <ChartGroupLinha>{label}</ChartGroupLinha>
      <ChartGroupLinhaMeta><small>Objetivo: {prefix} </small> {n(meta)} <small>{chartSimbol}</small></ChartGroupLinhaMeta>
      <ChartGroupTitle>Vendido</ChartGroupTitle>

      <ChartValue>
        {prefix} <strong>{n(chartValue)} </strong> {chartSimbol}
      </ChartValue>

      <PieChart width={width} height={height}>
        <defs>
          <linearGradient id="colorGauge" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#CC0000" stopOpacity={1} />
            <stop offset="45%" stopColor="#ff9933" stopOpacity={1} />
            <stop offset="65%" stopColor="#dab318" stopOpacity={1} />
            <stop offset="100%" stopColor="#33cc33" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Pie dataKey="value" data={[{ value: 100 }]} fill="none" {...pieRadius} {...pieProps}>
          <Cell fill="url(#colorGauge)" />
        </Pie>
        <Pie
          stroke="none"
          activeIndex={1}
          activeShape={Arrow}
          dataKey="value"
          data={arrowData}
          outerRadius={pieRadius.innerRadius}
          fill="none"
          {...pieProps}
        />
      </PieChart>
      <ChartValue pct>{n(percent)}%</ChartValue>

      <ChartGroupTitle>Faturado</ChartGroupTitle>

      <ChartValue>
        {prefix} <strong>{n(chartValue_fat)} </strong> {chartSimbol}
      </ChartValue>

      <PieChart width={width} height={height}>
        <defs>
          <linearGradient id="colorGauge" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#CC0000" stopOpacity={1} />
            <stop offset="45%" stopColor="#ff9933" stopOpacity={1} />
            <stop offset="65%" stopColor="#dab318" stopOpacity={1} />
            <stop offset="100%" stopColor="#33cc33" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Pie dataKey="value" data={[{ value: 100 }]} fill="none" {...pieRadius} {...pieProps}>
          <Cell fill="url(#colorGauge)" />
        </Pie>
        <Pie
          stroke="none"
          activeIndex={1}
          activeShape={Arrow}
          dataKey="value"
          data={arrowData_fat}
          outerRadius={pieRadius.innerRadius}
          fill="none"
          {...pieProps}
        />
      </PieChart>
      <ChartValue pct>{n(percent_fat)}%</ChartValue>
      <ChartGroupLinhaMeta><small>Carteira: {prefix}</small> {n(carteira)} <small>{chartSimbol}</small></ChartGroupLinhaMeta>


    </ChartGroup>
  );
};

export default class MetaVolPanelLinha extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.relacionadoData, this.props.relacionadoData)) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const tipo = !!this.props.tipo ? this.props.tipo === "Objetivo Volume" ? "VOL" : "FAT" : null;
      const codigo = !!this.props.isTipoVendaInterna ? this.props.relacionadoData.codigo : null;

      const response = await fetchMetaLinha(tipo, codigo);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
      //
    } finally {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { isLoading, data } = this.state;

    const prefix = !!this.props.tipo && this.props.tipo === "Objetivo Faturamento" ? "R$" : null;
    const sufixRev = !!this.props.tipo && this.props.tipo === "Objetivo Volume" ? "Litros" : null;
    const sufixB2B = !!this.props.tipo && this.props.tipo === "Objetivo Volume" ? "Kg" : null;

    console.log('data -> ', data);

    return (
      <Spin spinning={isLoading}>
        <div>
          {!!data && !!data.metavolume && data.metavolume.length > 0 && (
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {data.metavolume.map((item, index) => (
                <Col span={24 / data.metavolume.length}>
                  <DashboardBody key={index}>
                    {!!item && +item.meta > 0 ? (
                      <MetaChart
                        label={item.tipo}
                        meta={item.meta}
                        chartValue={item.vendido}
                        chartSimbol={
                          data.unidade_negocio === "REVENDA"
                            ? sufixRev
                            : sufixB2B
                        }
                        percent={item.vendido_pct}
                        chartValue_fat={item.faturado}
                        prefix={prefix}
                        percent_fat={item.faturado_pct}
                        carteira={item.carteira}
                        width={960 / data.metavolume.length}
                        height={360 / data.metavolume.length}
                      />
                    ) : (
                      <p>
                        <strong>
                          Dados insuficientes para montar o seu gráfico de Metas.
                        </strong>
                      </p>
                    )}
                  </DashboardBody>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Spin>
    );
  }
}
