import React, { Component } from "react";
import { Alert, Row, Col, Tag, Form, Input, Slider, DatePicker } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";

import { isDev } from "@env";
import { getData, dateFn, n } from "@util";
import Select, { Option } from "components/Select";
import InputSearch from "components/InputSearch";
import { isFilialFracionado, isFobMatriz } from "stores/PedidoStore/Pedido";
import { Fragment } from "react/cjs/react.production.min";
import { fetchApi } from "actions";
import CadastroEditModalScreen from "../clientes/CadastroEditModalScreen";

const FormItem = Form.Item;

const formItemLayout = {};

function diasFormatter(value) {
  value = Math.floor(+value);
  return `${value} dia${value > 1 ? "s" : ""}`;
}

const DiasTag = ({ dias, color = "red" }) => <Tag color={color}>{diasFormatter(dias)}</Tag>;

@observer
export default class StepCliente extends Component {
  constructor(props) {
    super(props);

    this.today = moment(props.pedido.today, "YYYY-MM-DD").startOf("day");

    this.state = {
      current: {},
      focused: null,
      opcaoKit: "N",
      isClienteTintometrico: false,
      clienteSelected: null,
    };
  }

  async componentDidMount() {
    const { history, pedido } = this.props;
    const isAddSimula = history.location.pathname === "/simulacoes/add";
    if (isAddSimula && !!pedido) {
      pedido.data.status_pedido = "S";
      pedido.data.isAddSimula = true;
    }
  }

  componentWillUnmount() {
    if (!!this._requestFocus) clearTimeout(this._requestFocus);
  }
  saveInput = field => _ref => {
    this._inputs[field] = _ref;
  };
  requestFocus = field => {
    if (!!this._inputs[field]) {
      this._requestFocus = setTimeout(() => {
        try {
          this._inputs[field].focus();
        } catch (ex) { }
      }, 1000 / 60);
    }
  };
  saveCurrentValue = field => event => {
    const { current } = this.state;
    const value = typeof event === "string" ? event : event.target.value;
    this.setState({ focused: field, current: { ...current, [field]: value } });
  };
  updateItemValue = (field, onChange = null, tr = v => v) => event => {
    const { data } = this.props.pedido;
    data[field] = tr(typeof event === "string" ? event : event.target.value);

    if (!!onChange && typeof onChange === "function") {
      onChange(field, toJS(data[field]));
    }
  };
  hasChanged = field => {
    const { current } = this.state;
    const { data } = this.props.pedido;

    return !current[field] || `${current[field]}` !== `${toJS(data[field])}`;
  };
  afterChanged = async (field, onChanged, nextFocus = null) => {
    if (this.hasChanged(field) && !!onChanged && typeof onChanged === "function") {
      await onChanged(field);
    }
    if (!!nextFocus) {
      this.requestFocus(nextFocus);
    }
  };
  getInputProps = (field, customProps = {}) => {
    const { data } = this.props.pedido;
    const { onChange = null, ...extraProps } = customProps;

    return {
      ref: this.saveInput(field),
      name: field,
      value: data[field],
      onFocus: this.saveCurrentValue(field),
      onChange: this.updateItemValue(field, onChange),
      ...extraProps,
    };
  };

  disabledDate = current => {
    if (!!current) {
      // can select programado base day

      if (this.props.programadoBase.indexOf(current.format("YYYY-MM-DD")) !== -1) {
        return false;
      }

      // Can not select days before today and today

      if (current < this.today) {
        return true;
      }

      // Can not select weekend days
      const weekDay = current.day();
      if (weekDay === 0 || weekDay === 6) {
        return true;
      }

      if (!this.props.pedido.isB2B && this.props.pedido.data.programacao_forames_revenda === "NAO") {
        // Can not select next month (or year) days
        if (current.month() !== this.today.month() || current.year() !== this.today.year()) {
          return true;
        }
      }
    }
    return false;
  };

  renewFocus = () => {
    const { pedido } = this.props;

    pedido.resetCliente();
  }

  loadCliente = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.loadCliente();

        if (isDev && !pedido.data.obs_carregto) {
          pedido.data.obs_carregto = "TESTE DEV FAVOR IGNORAR";
        }

        if (!pedido.isEdit) {
          if (!isDev && ((pedido.statusCad === 'P') && pedido.isAtualizaOn === 'SIM' && pedido.data.codigo_cliente && pedido.data.tipo_cliente !== 'X')) {
            this._atualizaModal.open(pedido.data.codigo_cliente + "-" + pedido.data.loja_cliente);
          }
        }

        this.setState({ current: { ...this.state.current, codigo_cliente: pedido.data.codigo_cliente } });
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
      const errorMessages = {
        1: `Acesso não autorizado`,
        10: `Cliente inválido ou não pertencente a sua região!`,
        15: `Cliente sem política de desconto!`,
        20: `Os campos de condição de pagamento para este cliente não estão configurados, favor entrar em contato com o setor comercial!`,
        25: `Favor preencher os dados de potencial de compra mensal de solvente e tinta/complemento no cadastro do cliente!`,
        30: `Cliente com dedução de ST Ativa porém com a data de vigência vencida!`,
      };
      onPedidoError(errorMessages[+errorCode] || err.message);
    }
  };
  loadClienteEntrega = async () => {
    const { pedido, onPedidoError } = this.props;
    try {
      if (!pedido.isLoading) {
        await pedido.loadClienteEntrega();

        if (isDev && !pedido.data.obs_carregto) {
          pedido.data.obs_carregto = "TESTE DEV FAVOR IGNORAR";
        }
        if (pedido.data.cliente_entrega === "") {
          pedido.data.cliente_entrega = null;
          pedido.data.loja_entrega = null;
          pedido.data.razao_social_entrega = null;
        }

        this.setState({ current: { ...this.state.current, cliente_entrega: pedido.data.cliente_entrega } });
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;
      const errorMessages = {
        1: `Acesso não autorizado`,
        10: `Cliente inválido ou não pertencente a sua região!`,
        15: `Cliente sem política de desconto!`,
        20: `Os campos de condição de pagamento para este cliente não estão configurados, favor entrar em contato com o setor comercial!`,
      };
      pedido.data.cliente_entrega = null;
      pedido.data.loja_entrega = null;
      pedido.data.razao_social_entrega = null;
      onPedidoError(errorMessages[+errorCode] || err.message);
    }
  };
  loadAlvara = async () => {
    try {
      await this.props.pedido.loadAlvara();
    } catch (err) {
      this.props.onPedidoError(`Alvará inválido ou não pertencente a este cliente!`);
    }
  };
  loadLoja = async () => {
    await this.props.pedido.loadLoja();
  };
  loadTransp = async () => {
    try {
      if (!this.props.pedido.isLoading) {
        await this.props.pedido.loadTransp();
      }
    } catch (err) {
      this.props.onPedidoError(`Não existe transportadora de despacho para esta operação!`);
    }
  };
  loadRedesp = async () => {
    try {
      if (!this.props.pedido.isLoading) {
        await this.props.pedido.loadRedesp();
      }
    } catch (err) {
      this.props.onPedidoError(`Não existe transportadora de redespacho para esta operação!`);
    }
  };
  changeStatus = async status => {
    const { pedido, onPedidoError } = this.props;
    pedido.data.status_pedido = status;

    const validCodes = ["000013", "000014"];

    const isValidCode = (code) => validCodes.includes(code);


    if (pedido.data.status_pedido === "T") {
      if (isValidCode(pedido.policy.data.cod_perfil_auto) || isValidCode(pedido.policy.data.cod_perfil_imob)) {
        pedido.forcePolicyMaster();
      }
    } else {
      await this.loadCliente();
      pedido.data.orcamentoTintometrico = false;
    }

    pedido.data.opcaoKit = "N";
    pedido.data.num_kit = null;
    pedido.data.isValidKit = false;

    try {
      await pedido.checkStatus();

      pedido.checkFrete();

      await pedido.loadCondicao();

      this.requestFocus(pedido.isSafra ? "tipo_transp" : "tipo_frete");
    } catch (err) {
      if (err.name === "PedidoError") {
        onPedidoError(err.message);
      } else {
        onPedidoError(`Status inválido para este pedido!`);
      }
    }
  };

  validarTicket = async () => {
    const { pedido } = this.props;

    if (!pedido.data.num_kit) {
      return this.props.onPedidoError(`O código do ticket é obrigatório`);
    }

    try {
      this.props.pedido.isLoading = true;
      const { data } = await fetchApi(`/tickets/validar-ticket/${pedido.data.num_kit}`);

      if (!data.data) {
        pedido.data.isValidKit = false;
        pedido.data.num_kit = null;
        return this.props.onPedidoError(`Ticket não encontrado`);
      }

      pedido.data.isValidKit = true;
    } catch (err) {
      pedido.data.isValidKit = false;
      pedido.data.num_kit = null;
      this.props.onPedidoError(err.response.data.message);
    } finally {
      this.props.pedido.isLoading = false;
      if (pedido.hasItems) {
        pedido.items.forEach(item => {
          pedido.removeItem(item);
        })
      }
    }
  }

  render() {
    const { chooser, options, pedido, history } = this.props;
    const { hasFilial, hasCliente, data } = pedido;
    const isLoading = pedido.isLoading || this.props.store.isLoading;
    const isAddSimula = history.location.pathname === "/simulacoes/add";
    const mod_logistica = pedido.data.mod_logistica || options.logistica;

    const inputProps = {
      readOnly: isLoading,
      disabled: !hasCliente,
    };

    const linguagens = {
      PT: "Português",
      EN: "Inglês",
      ES: "Espanhol"
    }

    return (
      <div className="pedido-step">
        {!!data.obs_cliente && (
          <Alert
            message={<span dangerouslySetInnerHTML={{ __html: data.obs_cliente }} />}
            type="warning"
            showIcon={false}
            style={{ margin: "0 0 17px" }}
          />
        )}
        {!!data.obs_simulacao && data.obs_simulacao.length > 1 && (
          <Alert
            message={<span dangerouslySetInnerHTML={{ __html: data.obs_simulacao.split("\n").join("<br />") }} />}
            type="warning"
            showIcon={false}
            style={{ margin: "0 0 17px" }}
          />
        )}
        <Form noValidate layout="vertical">
          <Row gutter={12}>
            <Col span={16}>
              <Row gutter={12}>
                <Col span={7}>
                  <FormItem {...formItemLayout} required={true} label="Filial Faturamento">
                    <Select
                      {...inputProps}
                      disabled={pedido.hasItems || (pedido.isEdit && pedido.isSimulacao)}
                      value={data.filial_faturamento}
                      onChange={value => {
                        data.filial_faturamento = value;
                        pedido.resetCliente();
                        if (isFobMatriz(value, pedido.permite_fob)) {
                          data.op_logistica = "L";
                        } else if (isFilialFracionado(value)) {
                          data.op_logistica = "F";
                        } else {
                          data.op_logistica = null;
                        }

                        this.requestFocus("codigo_cliente");
                      }}
                      options={options.filiais}
                    />
                  </FormItem>
                </Col>
                <Col span={5}>
                  <FormItem {...formItemLayout} required={true} label="Cliente">
                    <InputSearch
                      {...this.getInputProps("codigo_cliente", inputProps)}
                      disabled={!hasFilial || pedido.hasItems || (pedido.isEdit && pedido.isSimulacao)}
                      onBlur={() => this.afterChanged("codigo_cliente", this.loadCliente, "loja_cliente")}
                      onSearch={value => chooser("cliente", () => this.requestFocus("loja_cliente"))}
                      onPressEnter={event => {
                        if (event.target.value.trim() === "") {
                          chooser("cliente", () => this.requestFocus("loja_cliente"));
                        } else {
                          this.afterChanged("codigo_cliente", this.loadCliente, "loja_cliente");
                        }
                      }}
                      maxLength="6"
                    />
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem {...formItemLayout} label="Nome Cliente">
                    <Input
                      {...inputProps}
                      value={data.razao_social}
                      readOnly={true}
                      disabled={!hasCliente || pedido.hasItems || (pedido.isEdit && pedido.isSimulacao)}
                    />
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem {...formItemLayout} required={true} label="Loja">
                    <Input
                      {...this.getInputProps("loja_cliente", inputProps)}
                      disabled={!hasCliente || pedido.hasItems || (pedido.isEdit && pedido.isSimulacao)}
                      onBlur={() => this.afterChanged("loja_cliente", this.loadLoja)}
                      onPressEnter={event => {
                        if (event.target.value.trim() !== "") {
                          this.afterChanged("loja_cliente", this.loadLoja, "codigo_alvara");
                        }
                      }}
                      maxLength="2"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Prazo Médio Solvente">
                    <Row gutter={12} style={{ marginTop: -4 }}>
                      <Col span={20} style={{ paddingLeft: 10 }}>
                        <Slider
                          {...inputProps}
                          ref={this.saveInput("prazo_medio_solvente")}
                          value={data.prazo_medio_solvente}
                          onChange={value => {
                            data.prazo_medio_solvente = value;
                            pedido.resetCondicao();
                          }}
                          tipFormatter={diasFormatter}
                          max={pedido.max_prazo_medio_solvente}
                        />
                      </Col>
                      <Col span={4} style={{ height: 36, alignItems: "center", display: "flex" }}>
                        <DiasTag
                          dias={data.prazo_medio_solvente}
                          color={+data.prazo_medio_solvente > 0 ? "#0f73b9" : "#ACACAC"}
                        />
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Prazo Médio Tinta e Comp.">
                    <Row gutter={12} style={{ marginTop: -4 }}>
                      <Col span={20} style={{ paddingLeft: 10 }}>
                        <Slider
                          {...inputProps}
                          ref={this.saveInput("prazo_medio_tintacomp")}
                          value={data.prazo_medio_tintacomp}
                          onChange={value => {
                            data.prazo_medio_tintacomp = value;
                            pedido.resetCondicao();
                          }}
                          tipFormatter={diasFormatter}
                          max={pedido.max_prazo_medio_tintacomp}
                        />
                      </Col>
                      <Col span={4} style={{ height: 36, alignItems: "center", display: "flex" }}>
                        <DiasTag
                          dias={data.prazo_medio_tintacomp}
                          color={+data.prazo_medio_tintacomp > 0 ? "#0f73b9" : "#ACACAC"}
                        />
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={10}>
                  <FormItem {...formItemLayout} required={true} label="Programado">
                    <div
                      onKeyDown={event => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (event.key === "Enter") this.requestFocus("status_pedido");
                      }}
                      tabIndex="0">
                      <DatePicker
                        {...inputProps}
                        ref={this.saveInput("programado")}
                        value={dateFn(data.programado)}
                        onChange={value => (data.programado = value.format("YYYY-MM-DD"))}
                        format="DD/MM/YYYY"
                        allowClear={false}
                        disabledDate={this.disabledDate}
                      />
                    </div>
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem {...formItemLayout} required={true} label="Status Pedido">
                    <Select
                      {...inputProps}
                      disabled={
                        !hasCliente ||
                        (pedido.isCampKit && pedido.hasCampKit) ||
                        (pedido.isSafra && pedido.hasCampSafra) ||
                        (pedido.hasItems && pedido.isBonificacao) ||
                        (pedido.hasItems && pedido.isAmostra) ||
                        (pedido.hasItems && pedido.isSimulacao) ||
                        (pedido.isEdit && pedido.isSimulacao) ||
                        pedido.data.orcamentoTintometrico ||
                        (pedido.isEdit && pedido.isOrcamentoTintometrico) ||
                        //pedido.hasItems ||
                        isAddSimula ||
                        pedido.isResiduo
                      }
                      ref={this.saveInput("status_pedido")}
                      value={pedido.data.status_pedido}
                      onSelect={this.changeStatus}
                      options={options.tipoStatus}
                    />
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayout} required={pedido.isSafra} label="Tipo Transporte">
                    <Select
                      {...inputProps}
                      disabled={!pedido.isSafra || (pedido.isSafra && pedido.hasCampSafra)}
                      ref={this.saveInput("tipo_transp")}
                      value={data.tipo_transp}
                      onChange={value => (data.tipo_transp = value)}
                      allowClear={true}>
                      {Object.keys(options.tipoTransporte).map(k => (
                        <Option key={k} value={k} disabled={pedido.tipo_transp_disp.indexOf(k) === -1}>
                          {options.tipoTransporte[k]}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ backgroundColor: 'rgb(15, 115, 185, 0.1)', borderRadius: 10, paddingTop: 6 }}>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="Total em Aberto">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.saldo_total, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        background: "#0f73b9",
                        borderColor: "white",
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="Saldo vencido">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.saldo_venc, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        //background: "#0f73b9",
                        background: data.saldo_venc > 0 ? "#e05353" : "#0f73b9",
                        borderColor: "white",
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="Limite de crédito">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.limite_credito, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        background: "#0f73b9",
                        borderColor: "white",
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="Lim. Coligação">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.limite_credito_colig, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        background: "#0f73b9",
                        borderColor: "white",
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="($) - Lim. Crédito">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.limite_credito - data.saldo_total, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        background: (data.limite_credito - data.saldo_total) > 0 ? "#6ad182" : "#e05353",
                        borderColor: "white",
                        boxShadow: "inset 0px 0px 8px rgba(0,0,0,0.25)"
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={false} label="($) - Lim. Créd. Colig.">
                    <Input
                      {...inputProps}
                      value={'R$ ' + n(data.limite_credito_colig - data.saldo_total, 2)}
                      readOnly={true}
                      disabled={false}
                      style={{
                        textAlign: "right",
                        fontSize: 16,
                        height: 30,
                        letterSpacing: -0.5,
                        color: "#fff",
                        background: (data.limite_credito_colig - data.saldo_total) > 0 ? "#6ad182" : "#e05353",
                        borderColor: "white",
                        boxShadow: "inset 0px 0px 8px rgba(0,0,0,0.25)"
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={4}>
              <FormItem {...formItemLayout} label="Tipo Frete">
                <Select
                  {...inputProps}
                  disabled={
                    !hasCliente ||
                    pedido.hasItems ||
                    !pedido.tipo_frete_enabled /*||
                    pedido.isCampKitOrSafra*/
                  }
                  ref={this.saveInput("tipo_frete")}
                  value={data.tipo_frete}
                  onSelect={value => {
                    pedido.changeTipoFrete(value);
                    pedido.checkFrete();

                    this.requestFocus(value === "F" ? "codigo_transp" : "redesp_tipo");
                  }}
                  options={options.tipoFrete}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem {...formItemLayout} required={data.tipo_frete === "F"} label="Transportadora">
                <InputSearch
                  {...this.getInputProps("codigo_transp", inputProps)}
                  disabled={!hasCliente || data.tipo_frete !== "F"}
                  onBlur={this.loadTransp}
                  onSearch={value => chooser("transp", () => this.requestFocus("redesp_tipo"))}
                />
              </FormItem>
            </Col>
            <Col span={15}>
              <FormItem {...formItemLayout} required={data.tipo_frete === "F"} label="Nome Transp.">
                <Input {...inputProps} value={data.nome_transp} readOnly={true} disabled={!data.codigo_transp} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={4}>
              <FormItem {...formItemLayout} label="Tipo Redesp.">
                <Select
                  {...inputProps}
                  disabled={
                    !hasCliente ||
                    pedido.hasItems ||
                    !pedido.redesp_tipo_enabled /*||
                    pedido.isCampKitOrSafra*/
                  }
                  ref={this.saveInput("redesp_tipo")}
                  value={data.redesp_tipo}
                  onSelect={value => {
                    pedido.changeRedespTipo(value);
                    pedido.checkFrete();

                    this.requestFocus(
                      value === "F"
                        ? "redesp_transp"
                        : !!pedido.bonif_amostra_brinde
                          ? "bonif_amostra_brinde"
                          : "obs_carregto"
                    );
                  }}
                  options={options.tipoRedesp}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                {...formItemLayout}
                required={data.tipo_frete !== "F" && data.redesp_tipo === "F"}
                label="Transp. Redespacho">
                <InputSearch
                  {...this.getInputProps("redesp_transp", inputProps)}
                  disabled={!hasCliente || data.redesp_tipo !== "F"}
                  onBlur={this.loadRedesp}
                  onSearch={value =>
                    chooser(
                      "redesp",
                      this.requestFocus(!!pedido.bonif_amostra_brinde ? "bonif_amostra_brinde" : "obs_carregto")
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col span={15}>
              <FormItem {...formItemLayout} required={data.redesp_tipo === "F"} label="Nome Redesp.">
                <Input {...inputProps} value={data.redesp_nome_transp} readOnly={true} disabled={!data.redesp_transp} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <FormItem
                {...formItemLayout}
                required={!!pedido.bonif_amostra_brinde}
                label="Mot. Bonif / Amostra / Brinde">
                <Select
                  {...inputProps}
                  disabled={!pedido.bonif_amostra_brinde}
                  ref={this.saveInput("bonif_amostra_brinde")}
                  value={data.bonif_amostra_brinde}
                  onChange={value => {
                    data.bonif_amostra_brinde = value;
                    this.requestFocus("obs_carregto");
                  }}
                  allowClear={true}>
                  {Object.keys(options.bonifAmostraBrinde).map(k => (
                    <Option key={k} value={k}>
                      {options.bonifAmostraBrinde[k]}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem {...formItemLayout} required={!!pedido.bonif_amostra_brinde} label="Obs. Carregamento">
                <Input
                  {...this.getInputProps("obs_carregto", inputProps)}
                  onPressEnter={event => this.requestFocus("op_logistica")}
                  maxLength="255"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <FormItem {...formItemLayout} required={true} label="Operação Logística">
                <Select
                  {...inputProps}
                  disabled={
                    inputProps.disabled ||
                    (pedido.isSafra && !pedido.permiteCabotagem) ||
                    isFilialFracionado(data.filial_faturamento) ||
                    (isFobMatriz(data.filial_faturamento, data.permite_fob) && !data.hasTintaEspecial)
                  }
                  ref={this.saveInput("op_logistica")}
                  value={data.op_logistica}
                  onSelect={value => {
                    data.op_logistica = value;
                    this.requestFocus("obs_nota");
                  }}>
                  {Object.keys(mod_logistica).map(k => (
                    <Option key={k} value={k}>
                      {mod_logistica[k]}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label="Obs. para NF">
                <Input
                  {...this.getInputProps("obs_nota", inputProps)}
                  onPressEnter={event => this.props.stepForward()}
                  maxLength="255"
                />
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem {...formItemLayout} label="Ordem de compra">
                <Input
                  {...this.getInputProps("ordem_compra", inputProps)}
                  onPressEnter={event => this.props.stepForward()}
                  maxLength="13"
                />
              </FormItem>
            </Col>
          </Row>
          {pedido.isSimulacao && (
            <Row gutter={12}>
              <Col span={8}>
                <FormItem {...formItemLayout} required={pedido.isSimulacao} label="Coligação?">
                  <Select
                    {...inputProps}
                    ref={this.saveInput("coligacao_simulacao")}
                    value={data.coligacao_simulacao}
                    onSelect={value => {
                      data.coligacao_simulacao = value;
                      this.requestFocus("motivo_simulacao");
                    }}
                    allowClear={true}>
                    {Object.keys(options.coligacao).map(k => (
                      <Option key={k} value={k}>
                        {options.coligacao[k]}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem {...formItemLayout} required={pedido.isSimulacao} label="Motivo da Simulação">
                  <Select
                    {...inputProps}
                    ref={this.saveInput("motivo_simulacao")}
                    value={data.motivo_simulacao}
                    onSelect={value => (data.motivo_simulacao = value)}
                    allowClear={true}>
                    {Object.keys(options.motivoSimulacao).map(k => (
                      <Option key={k} value={k}>
                        {options.motivoSimulacao[k]}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          )}
          {!!pedido.isB2B && (
            <Fragment>
              <Row gutter={12}>
                <Col span={8}>
                  <FormItem {...formItemLayout} label="Necessita de Avaliação Comercial?">
                    <Select
                      {...inputProps}
                      ref={this.saveInput("necessita_avaliacao")}
                      value={data.necessita_avaliacao}
                      onSelect={value => {
                        data.necessita_avaliacao = value;
                        if (value === "S") {
                          this.requestFocus("motivo_avaliacao");
                        } else {
                          data.motivo_avaliacao = null;
                        }
                      }}>
                      {["N", "S"].map(k => (
                        <Option key={k} value={k}>
                          {k === "N" ? "Não" : "Sim"}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} required={data.necessita_avaliacao === "S"} label="Motivo da Avaliação">
                    <Select
                      {...inputProps}
                      disabled={data.necessita_avaliacao !== "S"}
                      ref={this.saveInput("motivo_avaliacao")}
                      value={data.motivo_avaliacao}
                      onSelect={value => (data.motivo_avaliacao = value)}
                      allowClear={true}>
                      {Object.keys(options.motivoAvaliacao).map(k => (
                        <Option key={k} value={k}>
                          {options.motivoAvaliacao[k]}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayout} label="Alvará">
                    <InputSearch
                      {...this.getInputProps("codigo_alvara", inputProps)}
                      onBlur={() => this.afterChanged("codigo_alvara", this.loadAlvara)}
                      onSearch={value => chooser("alvara", () => this.requestFocus("programado"))}
                      onPressEnter={event => this.afterChanged("codigo_alvara", this.loadAlvara, "programado")}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={4}>
                  <FormItem {...formItemLayout} label="Cliente Entrega">
                    <InputSearch
                      {...this.getInputProps("cliente_entrega", inputProps)}
                      disabled={true /*(!hasFilial || pedido.hasItems) || (pedido.isEdit && pedido.isSimulacao)*/}
                      onBlur={() => this.afterChanged("cliente_entrega", this.loadClienteEntrega)}
                      onSearch={value => chooser("cliente_entrega", () => this.requestFocus("loja_entrega"))}
                      maxLength="6"
                    />
                  </FormItem>
                </Col>
                <Col span={pedido.isExportacao ? 12 : 16}>
                  <FormItem {...formItemLayout} label="Nome Cliente Entrega">
                    <Input
                      {...inputProps}
                      value={data.razao_social_entrega}
                      readOnly={true}
                      disabled={true /*(!hasCliente || pedido.hasItems) || (pedido.isEdit && pedido.isSimulacao)*/}
                    />
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem {...formItemLayout} required={true} label="Loja Entrega">
                    <Input
                      {...this.getInputProps("loja_entrega", inputProps)}
                      disabled={true /*(!hasCliente || pedido.hasItems) || (pedido.isEdit && pedido.isSimulacao)*/}
                      onBlur={() => this.afterChanged("loja_entrega", this.loadLoja)}
                      maxLength="2"
                      readOnly={true}
                    />
                  </FormItem>
                </Col>
                {pedido.isExportacao &&
                  <Col span={4}>
                    <FormItem {...formItemLayout} label="Doc. Exportação em:">
                      <Select
                        {...inputProps}
                        disabled={false}
                        allowClear
                        ref={this.saveInput("idioma")}
                        value={pedido.data.idioma_cliente}
                        onChange={ling => pedido.selectIdiomaCliente(ling)}
                        options={linguagens}
                      />
                    </FormItem>
                  </Col>
                }
              </Row>
            </Fragment>
          )}
          <Row gutter={12}>
            <Col span={4}>
              <FormItem {...formItemLayout} label="Utilizar KIT inicial tintométrico">
                <Select
                  {...inputProps}
                  type="primary"
                  ref={this.saveInput("opcaoKit")}
                  disabled={pedido.isEdit || inputProps.disabled || !(pedido.data.status_pedido === "2" || pedido.data.status_pedido === "1" || pedido.data.status_pedido === "A")}
                  value={data.opcaoKit}
                  onChange={(value) => {
                    if (pedido.hasItems) {
                      pedido.items.forEach(item => {
                        pedido.removeItem(item);
                      })
                    }

                    if (value === "S") {
                      pedido.opcaoKit(value);
                      pedido.data.obs_carregto = "Venda de kit inicial tintométrico";
                      pedido.data.obs_nota = "Venda de kit inicial tintométrico";
                      return;
                    }

                    pedido.data.isValidKit = false;
                    pedido.opcaoKit(value);
                  }}
                >
                  <Option value='S'>Sim</Option>
                  <Option value='N'>Não</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem {...formItemLayout} label="Código do TICKET" required={data.opcaoKit === 'S'}>
                <Input
                  {...this.getInputProps("num_kit", inputProps)}
                  disabled={pedido.isEdit || this.props.pedido.data.opcaoKit !== "S"}
                  maxLength="6"
                  onBlur={this.validarTicket}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      this.validarTicket()
                    }
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <CadastroEditModalScreen
          ref={_ref => (this._atualizaModal = _ref)}
          loading={isLoading}
          pedido={pedido}
          renewFocus={this.renewFocus}
        />
      </div>
    );
  }
  _atualizaModal = {};
  _inputs = {};
}
