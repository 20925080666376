import React from "react";
import { Tag } from "antd";
import styled from "styled-components";

const statusColor = {
  "Liberado": { color: "#33af1a" },
  "Rejeitado": { color: "#e82a2c" },
  "Cancelado": { color: "#ffaf03" },
  "Pendente": { color: "#215e9c" },
  "ANJO": { color: "#215e9c" },
  "DARKBULL": { color: "#215e9c" },
};

const StyledTag = styled(({ fontColor, ...props }) => <Tag {...props} />)`
  &.ant-tag {
    font-size: 14px;
    color: ${props => props.fontColor || "#666"};
    letter-spacing: -0.5px;
    padding: 0 5px;
    margin: 0;
  }
`;

const MemorandoStatusTag = ({ status, ...props }) => {
  const { color } = statusColor[status];

  return (
    <StyledTag title={status} color={color} fontColor="#fff" {...props}>
      {status}
    </StyledTag>
  );
};

export default MemorandoStatusTag;
