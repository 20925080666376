/* eslint-disable */
import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, Toolbar } from "components/FullTable";
import { Button, Dropdown, Icon, Menu, Modal, Tag, message, Popover } from "antd";
import { fetchApi, fetchPostApi } from "actions";
import styled from "styled-components";

const StatusCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => (!!props.gerente ? "#fa8c16" : "#33af1a")};
  overflow: hidden;
  cursor: default;
  margin: 0 auto;
  padding: 0;
`;

const ContainerPopover = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
`

@observer
class PromotoriaIndex extends Component {
  constructor(props) {
    super(props);
    this.ds = new TableDataSource({
      name: "Lista de solicitações promotoria",
      uri: "/promotoria/index",
    });
  }

  acessarSolicitacao(record) {
    this.props.history.push(`/promotoria/index/${record.r_e_c_n_o_}`);
  }

  clickActions = async (event, record) => {
    const { key } = event;
    try {
      this.props.store.isLoading = true;
      await fetchApi(`/promotoria/validar-promotoria/${record.zy7_codsol}`);
      switch (key) {
        case "enviar-supervisor":
          Modal.confirm({
            title: "Você confirma esta operação?",
            content: "Clicando no botão abaixo você irá enviar esta solicitação para o supervisor.",
            cancelText: "Cancelar",
            okText: "Confirmar",
            onOk: () => this.enviarSolicitacaoSupervisor(record),
          });
          break;
        case "enviar-promotor":
          Modal.confirm({
            title: "Você confirma esta operação?",
            content: "Clicando no botão abaixo você irá enviar esta solicitação para o promotor.",
            cancelText: "Cancelar",
            okText: "Confirmar",
            onOk: () => this.enviarSolicitacaoPromotor(record),
          });
          break;
        case "enviar-validacao":
          Modal.confirm({
            title: "Você confirma esta operação?",
            content: "Clicando no botão abaixo você irá enviar esta solicitação para validação para os seguintes usuários (gerente/diretor/supervisor)",
            cancelText: "Cancelar",
            okText: "Confirmar",
            onOk: () => this.enviarSolicitacaoValidacao(record),
          });
          break;
        case "enviar-supervisor-lancamento":
          Modal.confirm({
            title: "Você confirma esta operação?",
            content: "Clicando no botão abaixo você irá enviar esta solicitação para validação ao supervisor",
            cancelText: "Cancelar",
            okText: "Confirmar",
            onOk: () => this.enviarSolicitacaoValidacaoSupervisorPosLancamento(record),
          });
          break;
        case "finalizar-promotoria":
          Modal.confirm({
            title: "Você confirma esta operação?",
            content: "Clicando no botão abaixo você irá finalizar a promotoria",
            cancelText: "Cancelar",
            okText: "Confirmar",
            onOk: () => this.finalizarPromotoria(record),
          });
          break;
        default:
          message.warn('Opção não válida!');
          break;
      }
    } catch (error) {
      if (!!error.response) {
        return Modal.warning({
          title: "Ocorreu um problema",
          content: error?.response?.data?.message
        });
      }
      Modal.error({
        title: "Ocorreu um problema",
        content: "Erro ao tentar enviar a solicitação"
      })
    } finally {
      this.props.store.isLoading = false;
    }
  }

  enviarSolicitacaoPromotor = async (value) => {
    try {
      await fetchPostApi(`/promotoria/enviar-promotor/${value.r_e_c_n_o_}`);
    } catch (error) {
      message.error('Erro ao enviar para o promotor');
    } finally {
      this.ds.fetch();
    }
  }

  enviarSolicitacaoSupervisor = async (value) => {
    try {
      await fetchPostApi(`/promotoria/enviar-supervisor/${value.r_e_c_n_o_}`);
    } catch (error) {
      message.error('Erro ao enviar para o su');
    } finally {
      this.ds.fetch();
    }
  }

  enviarSolicitacaoValidacao = async (value) => {
    try {
      await fetchPostApi(`/promotoria/enviar-para-validacao/${value.r_e_c_n_o_}`);
    } catch (error) {
      message.error('Erro ao enviar para o su');
    } finally {
      this.ds.fetch();
    }
  }

  enviarSolicitacaoValidacaoSupervisorPosLancamento = async (value) => {
    try {
      await fetchPostApi(`/promotoria/enviar-supervisor-pos-lancamento/${value.r_e_c_n_o_}`);
    } catch (error) {
      message.error('Erro ao enviar para o su');
    } finally {
      this.ds.fetch();
    }
  }

  finalizarPromotoria = async (value) => {
    try {
      await fetchPostApi(`/promotoria/finalizar-promotoria/${value.r_e_c_n_o_}`);
    } catch (error) {
      message.error('Erro ao enviar para o su');
    } finally {
      this.ds.fetch();
    }
  }

  render() {
    const { isDiretorTipo, isPromotor } = this.props.store;

    const BadgeRender = (record) => {
      let cor;
      let text;

      switch (record.status) {
        case 'FINALIZADO':
          cor = "#0f73b9";
          text = 'FINALIZADO';
          break;
        case 'REPROVADO':
          cor = '#f5222d';
          text = 'REPROVADO';
          break;
        case 'CANCELADO':
          cor = '#f5222d';
          text = 'CANCELADO';
          break;
        case 'APROVADO':
          cor = '#33af1a';
          text = 'APROVADO';
          break;
        case 'CONFIRM DATA':
          cor = "#fa8c16";
          text = 'AGUARD.. ENVIO';
          break;
        default:
          cor = '#fa8c16';
          text = 'PENDENTE';
          break;
      }

      if (record.status === "PENDENTE" && record.etapas === "ATIVIDADES") {
        cor = '#fa8c16';
        text = 'AGUARDANDO ENVIO';
      }

      return <Tag style={{
        letterSpacing: 1,
        fontSize: 8,
        width: 100,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }} color={cor}>{text}</Tag>;
    }

    const columns = [
      {
        title: <Popover content={
          <ContainerPopover>
            <p style={{ fontSize: 10, marginRight: 5 }}>Solicitação gerada pelo gerente na cor</p>
            <p style={{ fontSize: 10, fontWeight: 'bold', color: '#fa8c16' }}>LARANJA</p>
          </ContainerPopover>
        }><Icon type="question-circle" /></Popover>,
        width: '3%',
        render: (text, item) => (
          <Fragment>
            {item.zy7_criado === "S" ? (
              <StatusCircle gerente />
            ) : (
              <StatusCircle />
            )}
          </Fragment>
        ),
      },
      {
        title: "Código",
        dataIndex: "zy7_codsol",
        key: "zy7_codsol",
        sorter: true,
        width: "8%",
        filteredValue: this.ds.filterValue("zy7_codsol"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Código" {...props} />,
        filterParser: filter => ({ ...filter, field: "zy7_codsol", value: `%${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Cliente",
        dataIndex: "zy7_client",
        key: "zy7_client",
        width: "17%",
        filteredValue: this.ds.filterValue("zy7_client"),
        filterDropdown: props => <TextFilter placeholder="Filtrar pela nome" {...props} />,
        filterParser: filter => ({ ...filter, value: `${filter.value}%`, op: "LIKE" })
      },
      {
        title: "Data inicial",
        dataIndex: "data_inicial",
        key: "data_inicial",
        width: "7%",
        sorter: true,
        // filteredValue: this.ds.filterValue("data_inicial"),
        // filterDropdown: props => <TextFilter placeholder="Filtrar por data incial (dd/mm/aaaa)" {...props} />,
        // filterParser: filter => ({ ...filter, type: "date", value: filterDate(filter.value) }),
      },
      {
        title: "Data final",
        dataIndex: "data_final",
        key: "data_final",
        width: "7%",
      },
      {
        title: "Nm. Genent",
        dataIndex: "zy7.zy7_nomger",
        key: "zy7_nomger",
        width: "10%",
        filteredValue: this.ds.filterValue("zy7_nomger"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por gerente" {...props} />,
        filterParser: filter => ({ ...filter, field: "zy7_nomger", value: `${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Nm. Promotor",
        dataIndex: "zyd.zyd_nome",
        key: "zyd.zyd_nome",
        width: "10%",
        filteredValue: this.ds.filterValue("zyd_nome"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por promotor" {...props} />,
        filterParser: filter => ({ ...filter, field: "zyd_nome", value: `${filter.value}%`, op: "LIKE" }),
      },
      {
        title: "Etapa",
        dataIndex: "etapas",
        key: "etapas",
        width: "12%",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "11%",
        render: (text, record) => <BadgeRender {...record} />
      },
      {
        title: "Ações",
        key: 'options_column',
        className: "text-center",
        width: '15%',
        render: (text, record) =>
          <Dropdown.Button size="small" type="primary" className="ant-dropdown-link" onClick={() => this.acessarSolicitacao(record)} overlay={renderMenuAction(record)} trigger={['click']}>
            Acessar <Icon type="right-circle" />
          </Dropdown.Button>
      }
    ];

    const renderMenuAction = (record) => {
      return (
        <Menu onClick={event => this.clickActions(event, record)}>
          {!(this.props.store.isGerente || this.props.store.isSupervisor || this.props.store.isPromotor) &&
            <Menu.Item key="enviar-validacao" disabled={this.props.store.isDiretorTipo || record.status === "REPROVADO" || Number(record.etapa_solicitacao) > 2 || record.status === "CANCELADO"}>
              <Icon type="right-circle" /> Enviar ao Gerente
            </Menu.Item>
          }
          {!(this.props.store.isSupervisor || this.props.store.isPromotor) &&
            <Menu.Item key="enviar-supervisor" disabled={!(this.props.store.isDiretorTipo || this.props.store.isSupervisor || this.props.store.isGerente) || record.status === "REPROVADO" || !(this.props.store.isDiretorTipo || this.props.store.isSupervisor || this.props.store.isGerente) && record.etapas === "VALIDACAO GERENTE" || record.zy7_criado === "S" && !this.props.store.isGerente || Number(record.etapa_solicitacao) > 3}>
              <Icon type="right-circle" /> Enviar ao Supervisor
            </Menu.Item>
          }
          {this.props.store.isSupervisor &&
            <Menu.Item key="enviar-promotor" disabled={!this.props.store.isSupervisor && record.etapas === "Adc. PROMOTOR" || record.etapas !== "Adc. PROMOTOR" && !this.props.store.isSupervisor || !this.props.store.isSupervisor || record.status === "REPROVADO" || Number(record.etapa_solicitacao) > 4}>
              <Icon type="right-circle" /> Enviar ao Promotor
            </Menu.Item>
          }
          {this.props.store.isPromotor &&
            <Menu.Item key="enviar-supervisor-lancamento" disabled={!this.props.store.isPromotor || record.etapas !== "LANCAMENTO DE CUSTOS" || record.status === "REPROVADO" || Number(record.etapa_solicitacao) > 5}>
              <Icon type="right-circle" /> Enviar ao Supervisor
            </Menu.Item>
          }
          {this.props.store.isSupervisor &&
            <Menu.Item key="finalizar-promotoria" disabled={!this.props.store.isSupervisor || record.etapas !== "VALIDACAO SUPERVISOR" || record.status === "REPROVADO" || Number(record.etapa_solicitacao) > 6}>
              <Icon type="right-circle" /> Finalizar Promotoria
            </Menu.Item>
          }
        </Menu>
      );
    }

    return (
      <div className="report-screen">
        <PageTitle title={this.ds.name}>
          <Button
            title="Cadastrar ] promotoria"
            type="primary"
            icon="plus"
            disabled={isDiretorTipo | isPromotor}
            onClick={() => this.props.history.push(`/promotoria/cadastro`)}>
            Nova Solicitação
          </Button>
        </PageTitle>
        <FullTable
          className="full-table__compact"
          actionBar={<Toolbar exportable={false} printable={false} />}
          loading={this.props.store.isLoading}
          columns={columns}
          rowKey={item => `${item.r_e_c_n_o_}:${item.zy7_codsol}`}
          dataSource={this.ds}
          errorMessage="Não foi possível buscar as informações"
        // scroll={{ x: 'max-content' }}
        />
      </div>
    );
  }
}

export default withStore(PromotoriaIndex);
