import React, { Component, Fragment } from "react";
import { Modal, Form, Input, Spin, Row, Col, Button, Icon, Upload, DatePicker, message } from "antd";
import { observer } from "mobx-react";
import styled from "styled-components";

import { filterDate, getData } from "@util";
import { fetchApi, fetchPostApi } from "actions";
import { v } from "components/FormBuilder";
import FullTable, { TableDataSource, Toolbar, TextFilter, WrappedValue } from "components/FullTable";
import moment from "moment";

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const StyledForm = styled(Form)`
  &.ant-form {
    margin-bottom: 26px;
  }
  .ant-form-item {
    margin-bottom: 3px;
    padding-bottom: 6px;
  }
`;

@observer
class AnexosModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.defaultFilter = {}

    this.defaultFilter = {};

    const { funcionario, empresa, treinamento } = props;

    if (funcionario !== undefined) {
      this.defaultFilter.funcionario = funcionario;
    }

    if (empresa !== undefined && !funcionario) {
      this.defaultFilter.empresa = empresa;
    }

    if (treinamento !== undefined) {
      this.defaultFilter.treinamento = treinamento;
    }

    this.today = moment().startOf("day");

    this.ds = new TableDataSource({
      title: "Histórico de anexos",
      uri: "/treinamento/funcionario/anexos",
      defaultFilter: this.defaultFilter,
      fetched: true
    });
  }
  saveAnexos = async (data) => {
    const errorMessages = {
      10: "Não foi possível registrar seus anexos neste momento. Tente novamente mais tarde",
    };

    if (!!data.anexos) {
      for (let i = 0; i < data.anexos.length; i++) {
        const el = data.anexos[i];
        if (!!el.error) {
          return;
        }
      }
    }

    try {
      this.setState({ isLoading: true });

      if (!Array.isArray(data.anexos)) {
        delete data.anexos;
      }

      if (this.props.funcionario) {
        data = {
          ...data,
          funcionario: this.props.funcionario
        }
      }

      if (this.props.treinamento) {
        data = {
          ...data,
          treinamento: this.props.treinamento
        }
      }

      if (this.props.empresa) {
        data = {
          ...data,
          empresa: this.props.empresa
        }
      }

      const inputFiles = ["anexos"];
      const formData = new FormData();
      Object.keys(data).forEach(field => {
        const isFile = inputFiles.indexOf(field) !== -1;
        if (!isFile && !Array.isArray(data[field])) {
          formData.append(field, data[field]);
        } else {
          data[field].forEach((file, k) => {
            if (!!file.originFileObj) formData.append(`${field}[${k}]`, file.originFileObj);
          });
        }
      });

      const { data: response } = await fetchPostApi("/treinamento/funcionario/saveanexos", formData, true);
      if (!!response.success) {
        Modal.success({
          title: "Registrados com sucesso",
          content: "Seus anexos estão registrados no histórico",
          onOk: () => {
            this.ds.fetch();
          },
          keyboard: false,
        });

        this.props.onClose && this.props.onClose();
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveAnexos(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  handleChange = ({ fileList }) => {
    let files = [];
    for (let i = 0; i < fileList.length; i++) {
      const item = fileList[i];
      item.response = "Erro no Upload";
      files.push(item);
    }
    this.setState({ fileList: files, download_uri: null });
  };
  normalizeFiles = event => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };
  renderUploadItem = (anexos) => {
    const fileList = [];

    anexos && anexos.forEach((item, index) => {
      fileList.push({
        uid: index * -1,
        name: item.filename,
        status: 'done',
        url: item.download_uri,
      })
    });

    return (
      <Upload fileList={fileList} />
    )
  }
  tryDelete = async (id) => {
    try {
      this.setState({ isLoading: true });

      const { data: response } = await fetchApi("/treinamento/funcionario/anexos/deletar/" + id);

      if (!!response.success) {
        Modal.success({
          title: "Sucesso!",
          content: `Anexos #${id} excluidos com sucesso`
        });
        this.ds.fetch();
      }
    } catch (err) {
      const errorMessages = {
        10: "Não foi possível registrar seus anexos neste momento. Tente novamente mais tarde",
      };
      const errorCode = !!err.response ? getData(err, "response.data.code", 10) : 10;

      Modal.error({
        title: "Atenção",
        content: errorMessages[+errorCode] || (typeof err === "string" ? err : errorMessages[10]),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }
  handleDelete = (codigo) => {
    Modal.confirm({
      title: "Atenção",
      content: `Você realmente deseja excluir os anexos #${codigo}?`,
      okType: 'danger',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.tryDelete(codigo)
      }
    })
  }
  disabledDate = (current) => {
    if (current > moment(this.today).add(2, 'years')) {
      return true;
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const isLoading = this.props.isLoading || this.state.isLoading;
    const types = ["application/pdf"];

    const columns = [
      {
        title: "Código",
        dataIndex: "fn.codigo",
        key: "codigo",
        width: "8%",
        sorter: true,
      },
      {
        title: "Data",
        dataIndex: "fn.cadastro",
        dataType: "datetime",
        key: "cadastro",
        width: "13.5%",
        sorter: true,
        filteredValue: this.ds.filterValue("cadastro"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Data (DD/MM/AAAA)" {...props} />,
        filterParser: filter => ({ ...filter, value: filterDate(filter.value), type: "str_date" }),
      },
      {
        title: "Anexados",
        dataIndex: "fn.anexos",
        key: "anexos",
        className: "text-left",
        sorter: false,
        render: (item) => this.renderUploadItem(item)
      },
      {
        title: "Observação",
        dataIndex: "fn.obs",
        key: "obs",
        width: "24%",
        render: (obs) => (
          <WrappedValue
            value={obs}
            type="text"
          />
        ),
      },
      {
        title: "Vigência",
        dataIndex: "fn.vigencia",
        key: "vigencia",
        width: "12%",
        render: (value) => {
          return (
            <WrappedValue
              value={value}
              type="date"
            />
          )
        }
      },
      {
        title: "Status",
        dataIndex: "fn.status",
        key: "status",
        width: "8%",
        render: (status) => (
          <WrappedValue
            value={status === 'A' ? 'Ativo' : 'Histórico'}
            type="text"
          />
        ),
      },
      {
        title: "",
        key: "actionbar",
        width: "5%",
        export: false,
        render: (_, item) => {
          return (
            <Button
              onClick={() => this.handleDelete(item.fn.codigo)}
              title="Deletar anexos"
              size="small"
              type="primary">
              <Icon type="delete" />
            </Button>
          )
        }
      }
    ];

    return (
      <Fragment>
        <Spin spinning={!!isLoading}>
          <StyledForm
            autoComplete="off"
            layout="vertical"
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
            className="cadastro--form">
            <Fragment>
              <Row gutter={12}>
                <Col span={(this.props.vigencia) ? 19 : 24}>
                  <FormItem label="Observação">
                    {getFieldDecorator("obs", { rules: [v.required] })(<TextArea rows={4} />)}
                  </FormItem>
                </Col>
                {(this.props.vigencia) &&
                  <Col span={5}>
                    <FormItem label="Vigência">
                      {getFieldDecorator("vigencia", { rules: [v.required] })(
                        <DatePicker
                          format="DD/MM/YYYY"
                          disabledDate={this.disabledDate} />
                      )}
                    </FormItem>
                  </Col>
                }
                {(this.props.necessita_anexo) &&
                  <Col span={24}>
                    <FormItem label="Anexos">
                      {getFieldDecorator("anexos", {
                        valuePropName: "fileList",
                        getValueFromEvent: this.normalizeFiles,
                        rules: [v.required]
                      })(
                        <Upload.Dragger
                          disabled={this.state.fileList && this.state.fileList.length >= 5}
                          accept=".pdf"
                          name="avatar"
                          listType="picture"
                          className="avatar-uploader-large"
                          beforeUpload={(file) => {
                            if (!types.includes(file.type)) {
                              message.error(`${file.name} não é um PDF, é aceito apenas PDF`);
                              return true;
                            } else {
                              return false
                            }
                          }}
                          action={null}
                          onChange={this.handleChange}
                          showUploadList={{ showPreviewIcon: false }}
                        >
                          {
                            this.state.fileList && this.state.fileList.length >= 5 ?
                              null
                              :
                              <div>
                                <p className="ant-upload-drag-icon">
                                  <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Aperte ou arraste o arquivo para essa área!</p>
                              </div>
                          }
                        </Upload.Dragger>
                      )}
                    </FormItem>
                  </Col>
                }
              </Row>
              <Row type="flex" justify="end">
                <Button loading={isLoading} type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Row>
            </Fragment>
          </StyledForm>
        </Spin>
        {this.props.mostraTable &&
          <FullTable
            actionBar={<Toolbar exportable={true} printable={false} />}
            rowKey={item => `${item.fn.codigo}`}
            loading={isLoading}
            columns={columns}
            dataSource={this.ds}
            errorMessage="Não foi possível obter as interações relacionadas no momento"
          />
        }
      </Fragment>
    );
  }
}

export default Form.create()(AnexosModal);