import React, { Component } from "react";
import { Button, Spin, Icon, Tag, Calendar, message, Row, Col, Input } from "antd";
import { observer } from "mobx-react";
import withStore from "withStore";
import PageTitle from "components/PageTitle";
import { fetchApi } from "actions";
import moment from "moment";
import { stringToColor } from "./createHexaDecimalColor";

@observer
class AgendaPromotoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      events: [],
      filter: null
    }

    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    const filter = this.state.filter;
    try {
      this.setState({ isLoading: true });
      const { data } = this.state.filter ? await fetchApi(`/promotoria/agenda-promotores/${filter}`) : await fetchApi('/promotoria/agenda-promotores');

      const eventsWithMomentDates = data.agenda.map(event => ({
        data: moment(event.data_inicial, 'DD-MM-YYYY').toDate(),
        payload: {
          zy7_codsol: event.zy7.zy7_codsol,
          recno: event.zy7.r_e_c_n_o_,
          zy7_client: event.zy7.zy7_client,
          zy7_promot: event.zyd.zyd_nome,
          data_inicio: moment(event.data_inicial, 'DD-MM-YYYY').toDate(),
          data_final: moment(event.data_final, 'DD-MM-YYYY').toDate(),
          colorHash: stringToColor(String(event.zyd.zyd_nome).substring(0, 5)),
          zy7_status: event.zy7.zy7_status
        }
      }));

      this.setState({ events: eventsWithMomentDates });
      this.forceUpdate();
    } catch (error) {
      message.error('Erro ao buscar as datas!')
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getListData(value) {
    const eventsInRange = this.state.events.filter(item => {
      const dataInicio = moment(item.payload.data_inicio);
      const dataFinal = moment(item.payload.data_final);
      const dateValue = moment(value);

      return dateValue.isSameOrAfter(dataInicio, 'day') &&
        dateValue.isSameOrBefore(dataFinal, 'day');
    });

    if (eventsInRange.length > 0) {
      return eventsInRange.map(event => ({
        recno: event.payload.recno,
        cliente: `Promotoria de ${event.payload.zy7_client}`,
        promotor: `Promotor: ${event.payload.zy7_promot}`,
        colorHash: event.payload.colorHash,
        status: event.payload.zy7_status
      }));
    } else {
      return [];
    }
  }


  dateCellRender = value => {
    const listData = this.getListData(value);

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>
            <Tag onClick={() => this.eventSelected(item)} color={item.colorHash} style={{ marginTop: 5, marginBottom: 5 }}>
              {item.recno + " - "}
              {item.status === "3" ? <strong>FINALIZADO - </strong> : <i>ANDAMENTO - </i>}
              {!this.props.store.isPromotor && <strong style={{ fontSize: 10 }} color="white">{item.promotor} - {item.cliente}</strong>}
              {this.props.store.isPromotor && <strong style={{ fontSize: 10 }} color="white">{item.cliente}</strong>}
            </Tag>
          </li>
        ))}
      </ul>
    );
  }

  eventSelected = (value) => {
    this.props.history.push(`/promotoria/index/${value.recno}`);
  }

  renderInputFilter = () => {
    return (
      <Row gutter={8}>
        <Col span={4}>
          <Input placeholder="Filtrar por Código/Nome promotor" value={this.state.filter} onChange={e => this.setState({ filter: e.target.value })} onPressEnter={this.fetchData} />
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={this.fetchData}>Filtrar<Icon type="search" /></Button>
        </Col>
      </Row>
    )
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <PageTitle title="Agenda Promotoria" />
        {this.renderInputFilter()}
        <Spin spinning={isLoading}>
          <Calendar dateCellRender={this.dateCellRender} locale="pt_BR" />
        </Spin>

        {/* <Calendar mode="month" fullscreen /> */}
      </div>
    );
  }
}

export default withStore(AgendaPromotoria);
